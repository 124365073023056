import './footer.scss';

import { JSXFactory, CustomElementHandler, ContactType, linkClick, render, patch } from '@appzuka/rxnano';
import { FooterContactArray } from 'site/siteContent';

  // Guard against a ContactType not in contactDict
  const showLine = (c) => {
    // NICE: Add typing to ensure keys are members of contact enum
    const contactDict = {
      [ContactType.Mail]: (icon, label, target) => <ContactMailLink icon={icon} href={'mailto:' + target}>{label}</ContactMailLink>,
      [ContactType.Line]: (icon, label) => <ContactLine icon={icon}>{label}</ContactLine>,
      [ContactType.Link]: (icon, label, target) => <ContactLineLink icon={icon} href={target}>{label}</ContactLineLink>,
      [ContactType.External]: (icon, label, target) => <ContactLineLink icon={icon} href={target} target="_blank" rel="nofollow noopener noreferrer">{label}</ContactLineLink>,
    }
    if (contactDict[c.type] === undefined) {
      console.log(`Warning: Undefined contactType on ${c.label}`);
      return;
    }
    render(contactDict[c.type](c.icon, c.label, c.target));
  }

interface CustomFooterElement {
    (attributes: {data: FooterContactArray}): JSX.Element;
}

const Address: CustomFooterElement = (attributes) => {
    return <div class='footer-address-wrapper'>
      <div class='footer-address'>
        { () => { attributes.data.map(showLine) } }
      </div>
    </div>
}

const Contacts: CustomFooterElement = (attributes) => {
    return <div class='footer-contacts-wrapper'>
      <div class='footer-contacts'>
        { () => { attributes.data.map(showLine) } }
      </div>
    </div>
}

//NICE: check for missing attributes
const ContactLine: CustomElementHandler = (attributes, contents) => {
  return (
    <div class='footer-contact-line'>
      { attributes!.icon}
      <p>{contents}</p>
    </div>
  )
}

const ContactMailLink: CustomElementHandler = (attributes, contents) => {
  const target = (attributes && attributes.href) ? attributes.href : '/';
  return <a href={target as string}><ContactLine icon={attributes!.icon}>{contents}</ContactLine></a>
}

const ContactLineLink: CustomElementHandler = (attributes, contents) => {
  const target = (attributes && attributes.href) ? attributes.href : '/';
  return <a href={target as string} target={attributes!.target as string} rel={attributes!.rel as string}><ContactLine icon={attributes!.icon}>{contents}</ContactLine></a>
}

const InternalLink: CustomElementHandler = (attributes, contents) => {
  const target = (attributes && attributes.href) ? attributes.href : '/';
  return <a href={target as string} onclick={(event) => linkClick(event, target)}>{contents}</a>
}

let showVersionTime = 0;

const clickCommit = (e) => {
  e.preventDefault();

  let clickInterval = Date.now() - showVersionTime;
  if ((clickInterval > 500) && (clickInterval < 3000)) {
    const el = document.getElementById('footer-versions') as HTMLDivElement;
    el.style.display = 'block';
    window.scrollTo(0, document.body.scrollHeight);
    setTimeout(() => { el.style.display = 'none'; }, 15000);  // No need to cancel as the footer is always in the DOM
  }
  showVersionTime = Date.now();

}

interface CustomCopyrightElement {
  (attributes: {commit: string}): JSX.Element;
}

const Copyright: CustomCopyrightElement = (attributes) => {
  return <p>
    <span id='commit-id' onclick={clickCommit}>
      {attributes.commit}
    </span>
    <span> © Copyright 2024 by </span>
    <a href='https://www.appzuka.io' target='_blank' rel='nofollow noopener noreferrer'>appZuka</a>
    <span> All Rights Reserved.</span>
  </p>
}

const insertFooter = (FooterContacts:FooterContactArray, FooterAddress:FooterContactArray, FooterAddress2:FooterContactArray, FooterSiteLogo, commitVersion, buildDate, versionList) => {

  const footerWrapper = document.getElementById('footerwrapper') as HTMLElement;
  // TODO: Delay until after page rendered
  patch(footerWrapper,
    <>
      <div class='footer-content'>
        <div class='footer-logos-wrapper'>
        <a class='footer-logo-link' href='/' onclick={() => {}}>
          { FooterSiteLogo }
        </a>
        <img src="/images/rics_white.png" class="rics-logo"/>
        </div>
        <Address data={FooterAddress} />
        <Address data={FooterAddress2} />
        <Contacts data={FooterContacts} />
      </div>
      <div class='footer-copyright-wrapper'>
        <div class='footer-copyright'>
          <div class='terms-links'>
            <ul>
              <li><InternalLink href='/policies/terms'>Website Terms</InternalLink></li>
              <li><InternalLink href='/policies/privacy'>Privacy Policy</InternalLink></li>
            </ul>
          </div>
          <div class='terms-copyright'>
            <Copyright commit={`${commitVersion} ${buildDate}`} />
          </div>
        </div>
        <div id='footer-versions'>
          <ul>
            { () => versionList.map( v => {
              render(<li><p>{v}</p></li>);
            })}
          </ul>
        </div>
      </div>
    </>
  );

  // window.innerHeight will change if the page scrolls on mobile and the tab bar disaappears, but this just sets the min
  // height for short pages so there will be always be a scroll bar (to prevent width jumps)
  const mainContainer2 = document.getElementById('container') as HTMLElement;
  mainContainer2.style.minHeight = `calc(${window.innerHeight + 2}px - ${footerWrapper.offsetHeight}px)`;

}

export {
  insertFooter
};

