// Headmenu is shown at the top of each page.  It is the first div in the container
// div, both of which are inserted in the original html file

import {
    patch,
    elementOpen,
    elementClose,
    elementVoid,
    text
} from 'incremental-dom';

import { exportedSiteData, PageListItem, isNil, linkClick, MenuType } from '@appzuka/rxnano';
import { FooterContactArray } from 'site/siteContent';

//   import { isNil, patchElement } from '../../utils';
//   import { exportedSiteData, PageListItem } from '../../index';

import './headmenu.scss';
import './menucontent.scss';
// import './topmenu.scss';

// import { linkClick } from '../../rxjs';
import { timer } from 'rxjs';
// import { takeWhile, map, filter, first } from 'rxjs/operators';

// import { MenuType } from '../../enums';

// import { HamburgerIcon } from '../icons/hamburger';

// import {
//     elementOpen,
//     elementVoid,
//     elementClose,
// } from 'incremental-dom';

const HamburgerIcon = (toggleMenu) => {
    elementOpen('svg', null, null,
        'class', 'hamburger-icon',
        'viewBox', '0 0 28.61 22.24',
        'onclick', (e) => { toggleMenu(); }
    );
        elementVoid('path', null, null, 'd', "M2.38,22.24C1.07,22.24,0,22,0,21.81V17.42C0,17.18,1.07,17,2.38,17H26.23c1.31,0,2.38.2,2.38,0.44v4.39c0,0.24-1.07.44-2.38,0.44H2.38Zm0-8.49c-1.32,0-2.38-.2-2.38-0.44V8.93c0-.24,1.07-0.44,2.38-0.44H26.23c1.31,0,2.38.2,2.38,0.44v4.39c0,0.24-1.07.44-2.38,0.44H2.38Zm0-8.49C1.07,5.26,0,5.07,0,4.83V0.44C0,0.2,1.07,0,2.38,0H26.23c1.31,0,2.38.2,2.38,0.44V4.83c0,0.24-1.07.44-2.38,0.44H2.38Z");
    elementClose('svg');
};

interface HMStore {
    shorten: number,
    actual: number
};

// TODO: memoize menu length

let headMenuStore =  {
        shorten: 0,
        actual: 0
} as HMStore;

const headMenuShorten = (by: number):void => {
    headMenuStore.shorten = by<0
      ? 0
      : headMenuStore.shorten>10
        ? headMenuStore.shorten
        : headMenuStore.shorten + by;
}

const HeadMenu = (pageChangeObservable, headMenuContent, headerLogo, dropmenu, options? : {afterHeadMenu?}) => {
    const hm = Object.create({});

    // If the prerendered headmenu is already there don't add it again.
    if (!document.getElementById('headmenuwrapper')) {
        const bwel = document.createElement('div');
        bwel.setAttribute('id', 'headmenuwrapper');
        const pw = document.getElementById('container');
        pw!.insertBefore(bwel, pw!.firstChild);
        // FIXME: What if there is no firstChild in container?
    };

    pageChangeObservable.subscribe({
        next: (ps:PageListItem) => {
            const menuContainer = document.getElementById('headmenuwrapper');
            if (menuContainer !== null) {
                patch(menuContainer as Element, () => headMenu(headMenuContent, headerLogo, dropmenu, ps.subMenu,
                  {subMenuClass: ps.subMenuClass, afterHeadMenu: options?.afterHeadMenu }));
                dropmenu.reset();
            }
        }
    });

    return hm;
}

const headMenuFormattedContent = ( headMenuContent, currentLocation, shorten, menuType:MenuType = MenuType.Head) => {
    elementOpen('div', null, null, 'id', 'menucontent');

            let displayList = headMenuContent
            .filter((m) => ((shorten === 0) || (currentLocation!==m.link))) // shorten:1 cuts just the current link
            .filter((m) => (m.priority > shorten))
            .map(m => ({menuText: m.menuText, link: m.link}));

            if (displayList.length < 2) {
                // NICE: Could also make this open the menu

                elementOpen('p');
                if (exportedSiteData.pageLabels[currentLocation] !== undefined) {
                    text(exportedSiteData.pageLabels[currentLocation]); 
                } else {
                    text(currentLocation.split('/').slice(-1)[0]); 
                }

                elementClose('p');
            } else {
                elementOpen('ul', null, ['id', menuType===MenuType.Top?'mclist-top':'mclist-head']);
                displayList.map(({menuText, link}, i) => {

                    elementOpen('li');
                    elementOpen('a', `mc-${i}`, null, 'href', link,
                        'class', currentLocation===link?'active-link':'other-link',
                        'onclick', (event) => {
                            linkClick(event, link);
                        });
                        text(menuText);
                    elementClose('a');
                    elementClose('li');
            });
            elementClose('ul');
            }
    elementClose('div');
};

const afterRepaint = (f:any):void => {
    window.requestAnimationFrame(() => window.requestAnimationFrame(f));
}

let windowWidth : number = window.innerWidth;

// NICE: Move submenu into own component
const subMenuContent = (subMenu, subMenuClass?) => {
    if (subMenu.length === 0) return;
    elementOpen('div', `sub-menu-${subMenuClass}`, ['id', `sub-head-menu`, 'class', [`submenu`, subMenuClass].filter(c => c !== undefined).join(' ')]);
    elementOpen('ul', `sub-menu-ul`, null, 'id', `sub-head-menu-ul`);
    subMenu!.map(({label, link, shortLabel}) => {

        if (link !== undefined) {
            elementOpen('a', null, null, 'href', link,
            'class', window.location.pathname === link?'active-link':'other-link',
            'onclick', (event) => {
                linkClick(event, link);
            });
            elementOpen('li', `sub-menu-li`, null);
                text(window.innerWidth > 450?label: shortLabel || label);
                elementClose('li');
            elementClose('a');
        } else {
            elementOpen('li', `sub-menu-li`, null);
                text(label);
            elementClose('li');
        }
    });
    elementClose('ul');
elementClose('div');
}

// TODO: The menucontent changes when the page changes
const headMenu = (headMenuContent, headerLogo, dropmenu, subMenu,
  options?: {subMenuClass? : string | undefined, afterHeadMenu? })  => {

    elementOpen('div', null, null, 'class', 'topmenuouter' );
        elementVoid('div', null, ['id', 'headsidemenu']);

        elementOpen('div', null, null, 'class', 'topmenubar');

            elementOpen('div', null, null, 'class', 'menubar-logos');

            elementOpen('img', null, null, 'class', 'rics-header-logo', 'src', '/images/rics.png' );
            elementClose('img');

            elementOpen('a', `head-logo`, null, 'href', '/', 'class', 'menubarlogolink', 'alt', 'Home Page',
            'onclick', (event) => {
                linkClick(event, '/');
            });
                headerLogo();
            elementClose('a');

            elementClose('div');

            elementOpen('div', null, null, 'class', 'content', 'id', 'hmc' );
                headMenuFormattedContent(headMenuContent, window.location.pathname, headMenuStore.shorten);
            elementClose('div');

            if (dropmenu) {
                HamburgerIcon(() => {
                    dropmenu.toggle();   
                });
            }
        elementClose('div');

        if (typeof subMenu === 'function') {
            subMenu();
        } else {
            subMenuContent(subMenu, options?.subMenuClass);
        }
        elementVoid('div', null, ['id', 'headsubmenu']);
        if (options?.afterHeadMenu) {
          elementOpen('div', null, ['class', 'after-head-menu']);
            options.afterHeadMenu();
          elementClose('div');
        }

    elementClose('div');

    // headMenuStore = {actual: 0, shorten: 0};
    // TODO: Start on first idle.

    timer(200)
    .subscribe(() => {
        patch(<HTMLElement>document.getElementById('hmc'),
          () => {
              headMenuFormattedContent(headMenuContent, window.location.pathname, headMenuStore.shorten);
              afterRepaint(() => setHeadMenuWidth(headMenuContent, 0));
          });
    });

    // patchElement('headsubmenu').style.height = '0px';
    // document.getElementById('headsidemenu').style.right = '-2000px';
    // headMenuStore = { // TODO: Check if this is needed still
    //     show: false,
    //     actual: 0
    // };
    // console.log('killing top menu')
        // headMenuStore = merge(headMenuStore, {show: false, actual: 0});



        // afterRepaint(() => setHeadMenuWidth(headMenuContent, 0));
        // window.requestAnimationFrame(() => setHeadMenuWidth(0));
        // addToPageQueue({
        //     run: function loadPix() {
        //         console.log('headMenuStore.shorten')
        //         afterRepaint(() => setHeadMenuWidth(headMenuContent, 0));
        //     }, name: 'hmcShorten', holdFor: [{
        //         event: KernelEvent.itemPatched,
        //         item: 'hmcContent'
        //     }]});

    let resizeThrottle = undefined as undefined | any; //TODO: fix type
    window.addEventListener('resize', (e) => {
//   console.log('resize event')
      // NICE: remove flash after resize
      if (!isNil(resizeThrottle)) {
        clearTimeout(resizeThrottle);
      }
      resizeThrottle = setTimeout(() => {
  
        if (window.innerWidth !== windowWidth)  { // Don't reflow if only height changes (iPad menubar reveal)
        windowWidth = window.innerWidth;
        headMenuShorten(-1);

          const hmc = document.getElementById('hmc') as Element;
          patch(hmc, () => headMenuFormattedContent(headMenuContent, window.location.pathname, headMenuStore.shorten));

          afterRepaint(() => setHeadMenuWidth(headMenuContent, 0));
        //   window.requestAnimationFrame(() => setHeadMenuWidth(0));
      }
  
        resizeThrottle = undefined;
        // topMenuShow(false);
      }, 100);
    });
};
    
const setHeadMenuWidth = (headMenuContent, loopctr:number = 0) => {

    // console.log(`Setting menu width hms: ${headMenuStore.shorten}`)
    let el = document.getElementById('mclist-head') as HTMLElement;
    if (el !== null) {
        let el2 = el.firstChild as HTMLElement;
        if (el2 !== null) {
            // console.log(`menu: ${el.offsetLeft} ${el2.offsetLeft}`);
            // console.log(`menu: ${el.offsetWidth} ${el2.offsetWidth}`);
            if (windowWidth < 1180) {
                // console.log('No room for menu');
                const hmc = document.getElementById('hmc') as Element;
                patch(hmc, () => {});

            } else if (el2.offsetWidth > (el.offsetWidth - 40)) {
                // console.log('No room for menu');
                const hmc = document.getElementById('hmc') as Element;
                patch(hmc, () => {});
            } else if (el2.offsetLeft < el.offsetLeft) {
                // console.log(`xMenu Overflowing by: ${(el.offsetLeft-el2.offsetLeft)} loop: ${loopctr} hms: ${headMenuStore.shorten}`);
                headMenuShorten(1);
                const hmc = document.getElementById('hmc') as Element;
                patch(hmc, () => headMenuFormattedContent(headMenuContent, window.location.pathname, headMenuStore.shorten));
                afterRepaint(() => setHeadMenuWidth(headMenuContent, loopctr+1));
                // window.requestAnimationFrame(() => setHeadMenuWidth(loopctr+1));
            }
        } 
    }

};

export {
    HeadMenu,
    headMenuFormattedContent, // Used in scrollmenu
};

