import { first, map } from 'rxjs/operators';

import { JSXFactory, sitePix, exportedSiteData, pixLoadedObservable, patch, render } from '@appzuka/rxnano';

import './accreditations.scss';

let accredEl;

const RICSLogo = () => {
  render(
    <div class="sitepix-wrapper sitepix-wrapper-accreditations-ice-logo">
      <picture class="sitepix-accreditations-ice-logo">
        <img src="/images/rics.png"/>
        </picture>
      </div>
  )
}


const accreditations = () => {
  render(<div ref={(e) => { accredEl = e; return e }} id="accreditations-wrapper" />);
  // When the pixList is loaded, fill the div.
  pixLoadedObservable
    .pipe(
      first(pl => pl.length > 0), // Wait until pixlist is loaded. Cancel after first valid pixlist found
      map(pl => pl.filter(p => p.file.match(/^accreditations/))), // Filter for the chosen image
    )
    .subscribe(pix => {
      patch(accredEl,
        <div>
          <div class="first-row">
            { () =>  {
              RICSLogo();
              pix.map((p,i) => { if (i<4) sitePix(exportedSiteData.assetBase, p.file) });
              }
            }
          </div>
          <div class="second-row">
          { () =>  {
            pix.map((p,i) => { if (i>3 && i<8) sitePix(exportedSiteData.assetBase, p.file) });
            }
          }
          </div>
          <div class="third-row">
          { () =>  {
            pix.map((p,i) => { if (i>7) sitePix(exportedSiteData.assetBase, p.file) });
            }
          }
          </div>
        </div>)})
}

export {
  accreditations
};

