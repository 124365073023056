import { JSXFactory, render } from '@appzuka/rxnano';

import { linkClick } from '@appzuka/rxnano';

import { sitePix } from '@appzuka/rxnano';
import { SiteData } from 'site/siteContent';

import './imagePanel.scss';



// const imagePanelOld = (image, title, link, content) => {
//     let elementList:HTMLElement[] = [];
//     const addELement = (el) => {
//       el.addEventListener("transitionend", () => {
//         var rect = el.firstChild.getBoundingClientRect();
//         var rect2 = el.parentNode.getBoundingClientRect();
//         if (rect.bottom > rect2.bottom) {
//           el.parentNode.classList.add("overflow");
//         }
//       });
//       elementList.push(el);
//       return el;
//     }

//     render(
//       <div class={`project-wrapper`} onclick={title.length>0?(e) => toggleActive(e):() => {}}>
//         <div class='project-thumbnail'>
//             { () => sitePix(SiteData.assetBase, image)}
//             <div class="project-title">
//               <h2>{title}</h2>
//             </div>
//         </div>
//         <div ref={addELement} class='project-content'>
//             <p>{content}</p>
//         </div>
//           <div class='more-link' onclick={e => linkClick(e, link)}>More &gt;</div>
//       </div>
//     );

// }

const imagePanel = (image, title, link, content, hidden) => {




  let isTouch = false;
  let isOverflowing = false;

const toggleActive = (event, link) => {

  // If isOverflowing is true then the panel has already been touched or clicked
  // and its content overflow.  If it is clicked again go to the cnotent.
  if (isOverflowing) {
    linkClick(event, link)
  }
  const isActive = event.currentTarget.className.split(' ').includes('active');
  const otherClasses = event.currentTarget.className.split(' ').filter(a => a !== 'active'?a:'').join(' ');

  if (isActive) {
    // Remove active from the current node
    event.currentTarget.className = otherClasses;
  } else {
    // Remove active from all wrappers and add it to the clicked node
    const els = document.getElementsByClassName('image-panel-wrapper');
    Array.from(els).map(el => el.className = el.className.split(' ').filter(a => a !== 'active' ? a : '').join(' '));
    event.currentTarget.className = otherClasses + ' active';
  }
}

  // NICE: This runs once for every panel. Would be better to have once per app
  if (!isTouch) {
    window.addEventListener('touchstart', function onFirstTouch() {
      document.body.classList.add('is-touch');
      isTouch = true;
      window.removeEventListener('touchstart', onFirstTouch, false);
    }, false);
  }

  const addElement = (el) => {
    // Remove the overflow class as soon as the height begins to collapse
    el.addEventListener("transitionstart", (transitionEvent) => {
      if (transitionEvent.propertyName !== 'height') return;
      // el is the image-panel-wrapper__content
      // el.parentNode.firstChild is the thumnail spacer.  The height of
      // this will be close to zero if the panel is currently expanded

      if ((el.parentNode.firstChild.offsetHeight) < 10) { // Only if the element is currently expanded
        el.parentNode.parentNode.classList.remove("overflow"); // transitionstart means element is collapsing
        isOverflowing = false;
      } else { // Expand has just begun
        // el.firstChild is the contents of __contents
        // el.parentNode.firstChild is the image spacer, which has the same height
        // __contents will have when fully expanded
        if (el.firstChild.offsetHeight > el.parentNode.firstChild.offsetHeight || image.length > 1) {
          el.parentNode.parentNode.classList.add("overflow");
          isOverflowing = true;
        }
         // Lock in the transition for 2 seconds to avoid flicker and bouncing
         // The transition itself has a delay in css which debounces short hovers
        el.parentNode.parentNode.classList.add("hoverstart");
        setTimeout(() => {
          // NICE: Listen for pagechange and cancel timeout
          el.parentNode.parentNode.classList.remove("hoverstart");
        }, 2000)
      }
    });
    return el;
  }

  if (!hidden) {
    render(
      <div class={`image-panel-wrapper`} onclick={title.length>0?(e) => toggleActive(e, link):() => {}}>
        <div class='image-panel-wrapper__thumbnail'>
          { () => {
                let firstImage = Array.isArray(image) ? image[0] : image+'1';
                let  ni = `projects/${firstImage.replace('.jpg', '').replace('.png', '').toLowerCase()}`;
                sitePix(SiteData.assetBase, ni)
            }
          }
        </div>
        <div class='image-panel-wrapper__filter' />
        <div class='image-panel-wrapper__title-wrapper'>
          <div class='image-panel-wrapper__thumbnail-space'/>
          <div class='image-panel-wrapper__title'>
            <h2>{title}</h2>
          </div>
          <div ref={ addElement } class='image-panel-wrapper__content'>
            <p>{content}</p>
            <div class='image-panel-wrapper__more-mask'></div>
          </div>
        </div>
        <div class='image-panel-wrapper__more'>More &gt;</div>
      </div>
    );
  } else {
    render(
      <div class={`image-panel-wrapper`}>
        <div class='image-panel-wrapper__thumbnail'>
          { () => {
                let firstImage = Array.isArray(image) ? image[0] : image+'1';
                let  ni = `projects/${firstImage.replace('.jpg', '').replace('.png', '').toLowerCase()}`;
                sitePix(SiteData.assetBase, ni)
            }
          }
        </div>
        <div class='image-panel-wrapper__filter' />
        <div class='image-panel-wrapper__title-wrapper'>
          <div class='image-panel-wrapper__thumbnail-space'/>
          <div class='image-panel-wrapper__title'>
            <h2>{title}</h2>
          </div>
        </div>
      </div>
    );
  }

}

export {
  imagePanel
}

