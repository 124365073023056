// var React = require('react');
// import { ImageContext } from '../app';

import { JSXFactory, CustomElementHandler } from '@appzuka/rxnano';
import { sitePix } from '@appzuka/rxnano';
import { SiteData } from 'site/siteContent';


// const procomOrg = () => {
//   render(
//     <div class='reorg'>
//       <h2>Procom Organisation</h2>
//       <p>Procom has been restructured in to 6 workstreams, each with a operational and commercial lead.  This allows the business to provide a much more focused service for our growing client base and puts quality of delivery at the top of our agenda.</p>
//       <p>The workstreams compliment each other and each team can collaborate to offer joined up service offering to clients.</p>
//       <img id='procomorgimg' src='/images/2024procomorg.png' />
//     </div>
//   )
// }

const reorgNews = () =>  {

  <render>
      <div class='reorg'>
        <p>Procom has been restructured in to 6 workstreams, each with an operational and commercial lead.  This allows the business to provide a much more focused service for our growing client base and puts quality of delivery at the top of our agenda.</p>
        <p>The workstreams compliment each other and each team can collaborate to offer joined up service offering to clients.</p>
        <img id='procomorgimg' src='/images/2024procomorg.png' />
     </div>
  </render>  

}

const news1812 = () =>  {

  const staff = [
    {file: 'stevenewcombe', content: 'Steve Newcombe brings 35 years experience, including working with global consultancies, and will be leading the new Birmingham office.'},
    {file: 'mark-sanchez', content: 'Mark Sanchez brings over 20 years experience and joins Procom from Arcadis (ex EC Harris).  Mark has worked on a number of prestigious projects including Heathrow Terminal 2, Lower Thames Crossing, Thames Tideway Tunnel and Thames Water AMP6.  He joins Procom to support our next phase of growth.'},
    {file: 'peter-corfe', content: 'Peter Corfe brings 26 years experience  and joins Procom to bolster the management team to support our next phase of growth.'}
  ];

  <render>
      <div class="news1812">
      <p>Procom are pleased to announce the appointment of new associate directors.</p>
      <div class="news1812-block">
      {() => staff.map((s,i) => {
        <render><div class="news1812-row" >
        { () => sitePix(SiteData.assetBase, `people/${s.file}`) }
        <p>{s.content}</p></div></render>
      })}
      </div>
    </div>
  </render>  

}





var newsData = [
  // {
  //   "title": "Procom Appointed as Programme Director for Royal Piers",
  //   "date": "4-Dec-2018",
  //   "image": "https://appzuka-web.s3.eu-west-2.amazonaws.com/procom/projects/royalpier.jpg",
  //   "size": "large",
  //   "body": "<p>Procom are pleased to announce their appointment as Programme Director to deliver the entire Royal Pier project in Southampton.</p><p>Procom have also secured commisions to act as Project Manager / Quanitity Surveyor & CDM Advisor on Sitewide Sustainable Infrastructure Services and Hampshire Gardens – Vertical Farming Facility.</p>"
  // },
  {
    "title": "Procom is proud to be an RICS Regulated Firm",
    "date": "21-Feb-2025",
    "image": "/images/rics.png",
    "body": `<p>Professionalism, integrity, and technical excellence have always been at the heart of Procom Group. That's why we're proud to be an RICS Regulated Firm. But what does this mean for our clients and partners?</p>
    <p>Read our <a href="https://www.linkedin.com/pulse/rics-regulated-firm-why-matters-our-clients-procom-im-ltd-4vthc/" target='_blank' rel='noopener noreferrer'>article</a> to learn more.</p>`
  },
  {
    "title": "Procom Announces Restructuring",
    "date": "9-Oct-2024",
    "image": null,
    "body": reorgNews
  },
  {
    "title": "Procom appoint Jon Nixon into the role of Finance Director",
    "date": "2-Aug-2024",
    "image": "site/jonnixon",
    "homepage" : {
      "summary" : `<p>Procom is delighted to announce the appointment of Jon Nixon into the role of Finance Director.</p>
      <p>Jon joins the senior team alongside Colin Hardiman, Mark Sanchez and Peter Corfe helping spearhead the business into its next chapter. He brings with him over 25 years of experience working in the Financial Services sector, holding senior positions working for the likes of HSBC, Citibank, Dynamo and OMA.</p>`,
      "image": "site/jonnixon",
      "disable" : false

    },
    "body": `<p>Procom is delighted to announce the appointment of Jon Nixon into the role of Finance Director.</p>
    
    <p>Jon joins the senior team alongside Colin Hardiman, Mark Sanchez and Peter Corfe helping spearhead the business into its next chapter. He brings with him over 25 years of experience working in the Financial Services sector, holding senior positions working for the likes of HSBC, Citibank, Dynamo and OMA.</p>

    <p>With a track record of developing multiple revenue lines and growing businesses we feel this appointment is a perfect fit at a crucial time in Procom's new phase of growth.</p>`
  },
  {
    "title": "Procom has joined hands with EXCOM",
    "date": "22-Nov-2023",
    "image": "site/procom-excom-news",
    "body": `<p><p>We are delighted to announce that EXCOM has joined hands with Procom as a part of the collaborative strategy to optimise the presence in KSA construction market.</p>
    <p>Procom is a UK based cost consultancy firm established in 2009 and has provided a solid foundation of industry-leading expertise and knowledge for the clients, earning recognition for its professional approach. EXCOM has been working with Procom since 2019 by providing pre-contract cost consultancy services.</p>
    <p>Colin Hardiman (Managing Director - Procom), Mark Sanchez (Director - Procom), Uthpala Jayaweera (CEO - EXCOM), Duminda Dissanayaka (Managing Director - EXCOM) and Janaka Nalin (Country Manager KSA - EXCOM) met in Riyadh in last week to inaugurate the joint operations in KSA.</p>
    <p>With this milestone, both Procom and EXCOM aim to broaden the horizons of commercial management in the ever-evolving construction arena to provide a superior service to potential clients.</p>`
  },
  {
    "title": "Procom partner with InventoryBase",
    "date": "2-Nov-2023",
    "image": "site/inventorybase",
    "body": `<p>Procom have partnered with <a href="https://inventorybase.co.uk" target='_blank' rel='noopener noreferrer'>InventoryBase</a>, the UK's leading platform for property reporting, to develop a bespoke App.</p>
    <p>Our app enables clients to streamline property operations for inventory, inspections, fire and legionella risk assessments with video and photographic evidence.</p>
    <p>Please <a href="/contact" target='_blank' rel='noopener noreferrer'>contact us</a> for further information and a demonstration.</p>`
  },
  {
    "title": "Procom signed up to the Conflict Avoidance Pledge",
    "date": "4-May-2023",
    "image": "org-logos/conflictavoidance",
    "homepage" : {
      "summary" : `<p>Procom are proud to announce that we have signed up to the Conflict Avoidance Pledge, joining a growing number of individuals and organisations showing our commitment to support the Conflict Avoidance Coalition.</p>`,
      "image": "org-logos/conflictavoidance",
      "disable" : false

    },
    "body": `<p>Procom are proud to announce that we have signed up to the Conflict Avoidance Pledge, joining a growing number of individuals and organisations showing our commitment to support the Conflict Avoidance Coalition. The coalition comprises of industry and professional bodies, with a purpose to reduce disputes in the construction industry by promoting best dispute avoidance and mitigation practices, and encouraging collaborative approaches to resolving differences.</p>

    <p>In response to Procom being successful in our application to sign up to the Pledge, the Conflict Avoidance Coalition responded positively. Becoming a signatory signalling to clients and suppliers that:</p>
    
    <ul>
    <li>Procom are a good business to work with</li>
    <li>Procom are committed to maintaining good business relationships and dealing with problems early and amicably. These are keystones of our day to day commercial operations</li>
    
    <li>Procom's business is fully committed to delivering value for money and working collaboratively to ensure projects are delivered on time, on budget and on par.</li>
    </ul>`
  },
  {
    "title": "Colin Hardiman completes Health and Safety Training for Managers course.",
    "date": "4-May-2023",
    "image": "site/colincert",
    "body": `<p>Procom MD, Colin Hardiman, has been awarded a certificate of achievement from High Speed Training to certify that he has completed the 'Health and Safety Training for Managers' course.</p><p>This is a high quality, interactive training course that provides senior staff with an in-depth level of knowledge on various health and safety topics, which helps them understand how to manage health and safety in their organisation.</p>`
  },
  {
    "title": "Procom are proud to have been appointed to be QS and EA for the Decoupling and Decarbonisation Works for Lambeth Council at Brixton Recreation Centre.",
    "date": "22-Mar-2023",
    "image": "client-logos/lambeth",
    "body": `<p>London Borough of Lambeth have committed to achieve Net Zero carbon target by 2030 across its estate with Brixton Recreation Centre being the single largest carbon emitter.</p>
    <p>Consequently, decarbonisation options have been considered by the Development and Sustainability teams to achieve net zero, value for money, project life span, NPV and operational and maintenance costs.</p>
    <p>Decarbonisation works to existing building being:</p>
    <ul>
<li>Upgrade Building Management System (BMS).</li>
<li>Upgrade existing lighting to LED lighting.</li>
<li>Install VSD motor controls.</li>
<li>Pool AHU replacement</li>
<li>Solar PV</li>
<li>Install VSDs</li>
<li>Window upgrades - replace existing glazing over pool area with double glazing with metal or plastic frames.</li>
</ul>`
  },
  // {
  //   "title": "Procom Welcome Hooshiar Ahmadpanah to the Team",
  //   "date": "7-Mar-2023",
  //   "image": "site/hooshiar",
  //   "body": `<p>Procom are pleased to announce that Hooshiar Ahmadpanah has joined us in the position of Senior Quantity Surveyor. </p>
  //       <p>Hooshiar has 13 years of construction experience in different fields ranging from building, road, railway to bridge construction.</p>

  //       <p>Bringing strong project management and collaboration skills, Hooshiar has an MSc degree in “BIM and digital transformation” from the University of Liverpool. Hooshiar is familiar with the state-of-the-art digital facilities and methods in construction.</p>

  //       <p>We wish Hooshiar a warm welcome to the Procom team.</p>
  //   `
  // },
  {
    "title": "Procom in Building Magazine's Top 150 Consultants in 2022",
    "date": "20-Oct-2022",
    "image": "site/2022top150",
    "body": `<p>Procom Construction Consultants are really proud to have moved up to 98th position in the 2022 Building Magazine Top 150 Consultants, demonstrating great success by being in the top 100 in a field of strong competition! In addition, we are also delighted to have achieved 41st position in the Top 50 Quantity Surveyors list!</p>

    <p>With our great team, we strive to deliver the best possible service to our valued clients and the results achieved also demonstrate our strong position across a number of these including chartered staff, salary patterns, work areas and expectations, all supported by our focus on team development and culture in the workplace.</p>
    
    <p>If you are interested in finding out more about Procom, whether as a potential client looking for professional support, or perhaps looking for a change in career to join a great company, please get in touch with us.</p>`
  },
  {
    "title": "Procom support Barnet Community Project",
    "date": "15-Oct-2022",
    "image": "site/barnetcommunity",
    "body": `<p>In September, we helped to brighten up Barnet community space! Colin, Peter, Janine and Timothy took some their time away from the office to work alongside Masher Brothers Limited decorators to tidy up the Merlin Ball Park by giving the walls a fresh coat of paint to a blank canvas in readiness for Global Street Art to apply their graffiti style artwork to the walls!</p>
    
    <p>Tim Blanc, Head of Community Engagement for Barnet Homes expressed gratitude on behalf of the Barnet community and thanked us for supporting this rewarding project.</p>`
  },
  {
    "title": "Procom proud to support Barnet Homes Urban Gamez",
    "date": "7-Sep-2022",
    "image": "site/urbangamezposter",
    "body": `<p>It was great to support Barnet Homes Ltd Urban Gamez event this year.</p>
    <p>Tim Blanc, Head of Community Engagement for Barnet Homes said: "THANK YOU! For being part of this year's Urban Gamez.  It was great to see you and we hope you got as much out of the day as we at Barnet Homes did. The Mayor of Barnet asked me to personally thank you for attending and supporting the local community!"</p>`
  },
  {
    "title": "Janine Moore Promoted to Quantity Surveyor",
    "date": "22-Aug-2022",
    "image": "site/janinepromotion",
    "body": `We're pleased to announce that Janine Moore BSc has been promoted from Intermediate Quantity Surveyor to Quantity Surveyor.

    In addition she's also proud to have recently successfully completed her Biker Down motorcyclists' First Aid Course.
    
    Well done, and congratulations on both of your achievements Janine!`
  },
  {
    "title": "Procom Welcomes New Team members",
    "date": "15-Aug-2022",
    "image": "site/timandlounews",
    "body": `We’re pleased to announce that Timothy Adafia MSc BSc (Hons) PGDipSurv and Lou Bhandari BSc (Hons) have recently joined us and will bring further strength to the team in delivering commissions to our valued Clients.

    Timothy joins us in the role of Quantity Surveyor. He is professionally qualified in Quantity Surveying and Commercial Management, in addition to having successfully completed a Masters Degree in Construction Law and Practice. His wide range of experience covers both main contractor and client side covering a range of sectors, including residential new build and maintenance , education new build and refurbishment, and civils projects. Most recently, he has also performed a lecturing role at Uxbridge College, preparing and delivering Construction and Built Environment lectures to BTEC students.
    
    Lou joins us in the role of Senior Commercial Manager. He is professionally qualified and brings with him over thirty years’ experience working across a range of contracts in heavy civil engineering, major road and land reclamation plus major Capital Investment Programme in aviation and broadband infrastructure development. He has extensive experience in performing leading roles in tendering and procurement, pre and post contract cost management, cost auditing and verification, data analytics and insights, commercial strategy and cost efficiency targeting.
    
    We wish Timothy and Lou a warm welcome to the team!”`
  },
  {
    "title": "Procom Welcomes New Team Member",
    "date": "10-Feb-2022",
    "image": "people/hannahmyers",
    "body": `Procom are pleased to announce that Hannah Myers has joined the Procom team as Quantity Surveyor.

    Hannah joins us in the role of Quantity Surveyor following a number of years establishing her professional career. She has been predominately working on a range of traditional and JCT D&B forms of contract across a variety of different sectors and has gained significant experience in doing so. She is currently progressing her RICS accredited degree at the University of Portsmouth, which she is on target to complete and become fully chartered later this year.`
  },
  {
    "title": "Procom Team Help Community Garden Project",
    "date": "3-Dec-2021",
    "image": "site/grahamepark2",
    "body": `Social Value is so important to local communities and today the Procom team were pleased to help Groundwork with a community garden project at Barnet Homes Ltd, Grahame Park estate. Peter Corfe MCIOB AssocRICS CIWFM, Janine Moore, Sam Busbridge and Rob Matthews found it a very chilly, but rewarding experience and enjoyed contributing to bringing a better life to the community of Barnet. #project #help #community #socialvalue`
  },
  {
    "title": "Procom Welcomes Students from Queensmead House School",
    "date": "12-Nov-2021",
    "image": "site/queensmeadhouse",
    "body": `<p>Year 10 and 11 pupils from Queensmead House School recently visited the offices of Procom Construction Consultants in Wokingham. The company welcomed pupils with enthusiasm with a meeting room set up with hard hats, high-visibility vests and plenty of refreshments.</p>
    <p>This visit enabled pupils to get a real insight into construction and the working life of an office. Mark Sanchez, Associate Director, and Sam Busbridge, Quantity Surveyor, delivered an exciting and engaging presentation that included their journey to success, highlighting the advantages of apprenticeships as well as pursuing a university degree.</p>
    
    <p>The duo described what makes a work environment great and highlighted teamwork and excellent communication skills as some of the most important aspects. Following this, an illustration of the services construction management companies offer was shown, and during this segment, pupils tapped into their mathematical abilities to explore the latest quantity surveying software and calculate the number of bricks required to construct a building.</p>

    <p>The presentation ended with what companies like Procom look for in candidates. Pupils took away that they would like young people as part of their team who hold on to their dreams, lifelong learners, are kind to themselves and others, and always remember that success is a process and not an event.</p>`
  },
  {
    "title": "Procom Release New Infrastructure Brochure",
    "date": "9-Jul-2021",
    "image": "thumbnails/2021infrastructure",
    "body": `<p>Procom have published a new 16-page brochure detailing our services, team and experience across a range of infrastructure sectors including road, rail, renewable energy, aviation, health, utilities and marine.<p><p><a target='_blank' rel='nofollow noopener noreferrer' href='https://appzuka-web.s3.eu-west-2.amazonaws.com/procom/documents/Procom+Infrastructure+Brochure.pdf'>Click here to view the new brochure</a></p>`
  },
  {
    "title": "Procom awarded place on the NHS Dynamic Purchasing System",
    "date": "24-May-2021",
    "image": "site/nhslpp",
    "body": `Procom are proud to announce that we have successfully been awarded a place on the NHS Estates & Facilities Consultancy Services Dynamic Purchasing System to provide high quality services to health and public sector organisations.
    The LPP DPS Framework allows NHS organisations and public sector bodies to appoint Procom as a provider, helping them to secure the highest quality professional services at the most cost-effective prices to ensure that their commercial and clinical needs are met.
    The LPP has saved the NHS more than £1.2 billion since its launch in 2006.  Procom will help drive essential cost savings that will help the NHS retain, redesign and improve healthcare while driving towards the plan to become the world’s first net zero carbon health system by 2045.`
  },
  {
    "title": "Colin Hardiman and Dave Buxton admitted to membership of the CIArb",
    "date": "16-Apr-2021",
    "image": "accreditations/ciarb",
    "body": `Huge congratulations to our Managing Director, Colin Hardiman and Commercial Team Lead, Dave Buxton on being admitted as members of The Chartered Institute of Arbitrators! Membership demonstrates a commitment to achieving the highest levels of knowledge, skills, and behavior in dispute avoidance and dispute management.`
  },
  {
    "title": "Procom proud to be on Prosper Procurement's Dynamic Purchasing System",
    "date": "11-Mar-2021",
    "image": "site/prosper-logo",
    "body": `Procom are proud to announce they have secured a place on Prosper Procurement's Dynamic Purchasing System (DPS) for Design & Consultancy Services under LOT3 Surveying (All Forms) for the North East, North West, Yorkshire and The Humber.`
  },
  {
    "title": "Procom Welcomes New Team Member",
    "date": "26-Feb-2021",
    "image": "people/agnieszkadivall",
    "body": `Procom are pleased to announce that Agnieszka Divall has joined the Procom team as Quantity Surveyor.

    Agnieszka is currently progressing with her RICS APC and has strong industry experience, having previously worked across a number of sectors including earthworks, MEP, interiors fit-out and commercial developments including aviation related.
    
    Procom continue to remain resilient as a business through these challenging times and Agnieszka is joining us in our commitment to ensure that our clients continue to receive the highest standard of service that they expect of us.`
  },
  {
    "title": "Procom sponsor The Barnet Group Community Awards 2020",
    "date": "25-Feb-2021",
    "image": "site/barnetaward",
    "body": `<p>We're proud sponsors of the The Barnet Group Community Awards 2020 and we wish to congratulate all the nominees for the fantastic contribution they've made to the community of Barnet!</p>
        <p>The Barnet Group hold these awards once a year and whilst the impact of the Covid-19 pandemic means the event can’t be held in person this time around, it does not take anything away from the incredible achievements of those nominated for an award. During what has been a difficult year, the nominees have stepped up in so many ways to support their neighbours and be a part of their communities. The awards are usually for customers and service users of The Barnet Group, but in recognition of those who have given so much over the last year, the awards have extended to the wider Barnet community. The virtual awards ceremony on our website and YouTube channel on Thursday 28th January.</p>
    
        <div class='barnetawardlinks'>
    <p><a href='https://lnkd.in/ehqnvF8'>https://lnkd.in/ehqnvF8</a></p>
    <p><a href='https://www.linkedin.com/feed/hashtag/?keywords=socialvaluematters&highlightedUpdateUrns=urn%3Ali%3Aactivity%3A6759409200469741568&lipi=urn%3Ali%3Apage%3Ad_flagship3_detail_base%3BtDQ%2BMWRBRGy6uAiwPrtr1w%3D%3D'>#socialvaluematters</a></p>
    <p><a href='https://www.linkedin.com/feed/hashtag/?keywords=communityspirit&highlightedUpdateUrns=urn%3Ali%3Aactivity%3A6759409200469741568&lipi=urn%3Ali%3Apage%3Ad_flagship3_detail_base%3BtDQ%2BMWRBRGy6uAiwPrtr1w%3D%3D'>#communityspirit</a></p>
    <p><a href='https://www.linkedin.com/feed/hashtag/?keywords=recognition&highlightedUpdateUrns=urn%3Ali%3Aactivity%3A6759409200469741568&lipi=urn%3Ali%3Apage%3Ad_flagship3_detail_base%3BtDQ%2BMWRBRGy6uAiwPrtr1w%3D%3D'>#recognition</a></p>
    </div>`
  },
  {
    "title": "Procom Shortlisted in the Construction News Awards 2020",
    "date": "12-Mar-2020",
    "image": "site/cnawards2020",
    "body": "Procom is pleased to announce that we have been shortlisted in the Construction News Awards 2020 in the category of Construction Consultancy of the Year."
  },
  {
    "title": "Procom appointed to Golding Homes' DPS",
    "date": "19-Feb-2020",
    "image": "client-logos/goldinghomes",
    "body": `Procom is pleased to announce that we've been appointed to Golding Homes DPS Lot 4 Employer's Agent framework. This has been achieved through a rigorous and competitive tender process by us demonstrating our experience and capability to provide Employer's Agent services, including quantity surveying and cost consultant services.


    This is a great achievement and we're looking forward to supporting Golding Homes on its strategic priorities to grow its portfolio of new homes across all tenures over the next few years!`
  },
  {
    "title": "Procom appointed to Swan Housing's DPS",
    "date": "12-Feb-2020",
    "image": "client-logos/swan",
    "body": "<p>Procom is pleased to announce that we've been appointed to Swan Housing's DPS Lot 4 Employer's Agent framework. This has been achieved through a rigorous and competitive tender process by us demonstrating our experience and capability to provide Employer’s Agent services.</p><p>This is a great achievement and we're looking forward to supporting Swan Housing to deliver on its commitment to build a further 10,000 new homes across East London and Essex by 2027!</p>"
  },
  {
    "title": "Procom's 10th Birthday",
    "date": "19-Nov-2019",
    "image": "site/1911-procom10",
    "body": "<p>Last week saw Procom’s 10th Birthday!</p>We're extremely proud to have achieved this milestone as an established business that has developed and grown from being founded in 2009 by our Managing Director and Founder, Colin Hardiman.  We're grateful to our valued clients for giving us the opportunities to support them on a variety of commissions over the past 10 years and we're looking forward to continuing to help them and new clients with their needs in future!</p>"
  },
  {
    "title": "Procom in Building Magazine's Top 150 Consultants in 2019",
    "date": "02-Oct-2019",
    "image": "site/top150-2019",
    "body": "<p>Procom are proud to announce that we have successfully achieved a place in the Buildings Magazine’s Top 150 Consultants in 2019.</p><p>This is a great achievement that marks our continued growth and success for the fourth time with our ranking increased from 129th to 93rd place. Building Magazine is one of the construction industry’s most respected and widely read publications and so to receive this recognition is something we are very proud of.</p><p>Close to 250 firms were contacted for this year’s Building Top Consultants’ Survey. They were surveyed on their number of chartered staff, non-chartered, technical staff and domestic and worldwide fee incomes. Practices were also asked questions relating to staff recruitment, wage patterns, margins, areas of work, and work expectations.</p>"
  },
  {
    "title": "Procom Announce Constructionline Gold Membership Certification",
    "date": "18-Apr-2019",
    "image": "accreditations/constructionine-gold-logo",
    "size": "large",
    "body": "<p>Procom are proud to announce our certification as Constructionline Gold Members.</p><p>Gold Membership requires an extended verified PQQ (covering Environmental Management, Equalities and Diversity, and Quality Management, and ensuring legislative compliance). All Gold Members must also possess a valid SSIP certificate.</p><p>Constructionline is the UK’s largest register of contractors, consultants and material suppliers for the construction industry, with over 10,000 key decision makers from over 3,800 buying organisations using Constructionline to select quality suppliers.</p>"
  },
  {
    "title": "Procom Welcomes New Team Members",
    "date": "22-Nov-2018",
    "image": null,
    "body": news1812
  },
  // {
  //   "title": "Procom Opens office in Birmingham",
  //   "date": "26-Oct-2018",
  //   "image": "site/edmundhouse",
  //   "body": () => <render>
  //     <p>Procom are pleased to announce the opening of our new office in Birmingham.  The office is located in Edmund House, neighbouring Temple Street, Church Street and Waterloo Street.</p>
  //     <p>The office is 5 Minutes to New Street &amp; Snow Hill with regular trains to London Euston and 10 Minutes to Birmingham International Airport by train from Birmingham New Street Station.</p>
  //     <p class='addressline'><a href='https://edmund-house.co.uk/' target='_blank'>Edmund House</a></p>
  //     <p class='addressline'>12-22 Newhall Street</p>
  //     <p class='addressline'>Birmingham</p><p class='addressline'>B3 3EW</p>
  //   </render>
    

  // },
  {
    "title": "Procom Secures New Contract with Barnet Homes",
    "date": "21-Feb-2018",
    "image": "client-logos/barnethomes",
    "body": () => <render>
      <p>Procom are proud to announce the award  of a further contract from long standing client Barnet Homes.</p>
      <p><b>Contract:</b> Cost Management</p>
      <p><b>Scope of Works:</b></p>
      <ul>
        <li>Responsive repairs</li>
        <li>Gas servicing</li>
        <li>Aids and adaptations</li>
        <li>Major works involving building work</li>
        <li>Major works involving electrical lateral mains.</li>
      </ul>
      <p><b>Period of Contract:</b> April 2018 to March 2023</p>
    </render>
  },
  {
    "title": "Procom has secured a further commission with Wycombe District Council",
    "date": "17-Aug-2017",
    "image": "client-logos/wycombe",
    "body": "<p>Procom are pleased to announce that it has secured a further commission with Wycombe District Council to act as Employers Agent on the Totteridge Recreation Open Space Improvements Scheme.</p><p>The upgraded space will encourage new sports activities for the local community, provide outdoor fitness equipment to promote group training and new seating areas for use by the public.</p>"

  },
  {
    "title": "Procom approved by Open Door Development Consultants",
    "date": "15-May-2017",
    "image": ["client-logos/opendoorhomes", "client-logos/barnethomes"],
    "body": "<p>We have achieved &lsquo;approved&rsquo; status of the Open Door Development Consultants DPS. Opendoor Homes is a new affordable housing provider which has just achieved registration by the Homes and Communities Agency.</p><p>They are a wholly-owned subsidiary of Barnet Homes, the arm&apos;s length management company for the London Borough of Barnet&apos;s housing stock.  Opendoor Homes has an initial development programme of 320 homes underway on land we are acquiring from the Council.  Our current sites are small scale, complicated and present major challenges for economic development.</p><p>The next phase in our growth will be development on larger, but no less challenging, brownfield, urban sites for a range of affordable housing, including general needs, extra care and temporary accommodation. We will be working with Opendoor Homes to expand their programme.</p>"
  }
];

// var oldNewsData = [


//   {
//     "title": "Procom getting young people into careers in the construction industry",
//     "date": "15-May-2017",
//     "image": "/images/site/iwilllogo.png",
//     "body": "<p>We are pleased to welcome Hamza Hashmi and Sam Busridge who have started work experience with us this week as part of our #iwill pledge to get more young people into careers in the construction industry.</p><p>If you would like to check out some of the work that they do or get involved in your own pledge go to <a href='http://www.iwill.org.uk'>http://www.iwill.org.uk</a></p>"
//   },
//   {
//     "title": "Procom Appointed to Brighton &amp; Hove County Council Dynamic Purchasing System",
//     "date": "16-Mar-2017",
//     "image": "/images/client-logos/bhcc-logo.jpg",
//     "body": "<p>We are pleased to announce that Procom have been appointed to Brighton &amp; Hove County Council’s Dynamic Purchasing System. The construction projects that the Procom will advise on/provide services for under this DPS could be potential, programmed and reactive works for new builds and existing asset refurbishment/regeneration. All Council owned assets and land, including Housing Revenue Account assets apply.</p><p>Procom will be providing services for:</p><ul><li>Quantity Surveyor</li><li>Cost Consultant</li><li>Employer's Agent</li></ul>"
//   },
//   {
//     "title": "Procom Welcomes New Team Members",
//     "date": "21-Feb-2017",
//     "image": "/images/org-logos/procom.jpg",
//     "body": "<p>We are pleased to announce the following additions to the Procom team:</p><ul><li>Owen Ndagurwa : Quantity Surveyor</li><li>Andy Chimbadzwa: Quantity Surveyor</li></ul><p>We also pleased to announce that Jack Palmer has become a full time employee at Procom having previously worked as a contractor for us.</p><p>Congratulations also go to Amy Bonczyk who has been appointed to Team Leader and will be taking on new responsibilities and a continued key member of staff!</p>"
//   },
//   {
//     "title": "Procom Wishing everyone a very Merry Christmas",
//     "date": "20-Dec-2016",
//     "size": "large",
//     "image": "/images/site/1612christmas.jpg",
//     "body": "<p>And a best wishes for a happy and prosperous New Year</p>"
//   },
//   {
//     "title": "Procom Volunteering for First Days in Woodley",
//     "date": "20-Dec-2016",
//     "image": "/images/site/1612volunteering.jpg",
//     "size": "large",
//     "body": "<p>Mel, Jamie and Ismail from our office had a great time helping out First Days Charity on Monday. Preparing gift bags for families who struggle to provide gifts for their Children at this time of year. We managed to prepare over 100 of the gift bags they send out. First Days is a great local charity based in Woodley who help families who were struggling to provide all the practical things they needed for their young families, if you want to take a look at all the awesome work that they do, take a look at their website <a href='http://www.firstdays.net'>http://www.firstdays.net</a></p>"
//   },
//   {
//     "title": "Procom pledges to #iwill campaign",
//     "date": "17-Nov-2016",
//     "image": "/images/site/iwilllogo.png",
//     "body": "<a>Procom have pledged up to support the #iwill campaign promotes social action for 10-20 year olds, we hope to support young people and give advice on how they could get involved in a career in the construction industry, see the full extent of our pledges here: <a href='http://www.iwill.org.uk/pledge/procom' target='_blank'>http://www.iwill.org.uk/pledge/procom</a></p><p>Look out for updates on this soon! </p>"
//   },
//   {
//     "title": "Happy Birthday Procom!",
//     "date": "17-Nov-2016",
//     "size": "large",
//     "image": "/images/site/1611anniversary.jpg",
//     "body": "<p>We have been celebrating in the office this week as Procom turned 7 years old this November. Here’s to many more exciting years to come! </p>"
//   },
//   {
//     "title": "Procom secures new commissions with Swindon Borough Council",
//     "date": "19-Oct-2016",
//     "image": "/images/client-logos/swindonbc.jpg",
//     "body": "<p>Procom are happy to announce that we have secured new commissions with Swindon Borough Council. Procom will be acting as Employers agent for their External Wall Insulation (EWI) Phase II project.</p>"
//   },
//   {
//     "title": "Procom have been added to The Oil & Pipelines Agency's Cost Management Framework",
//     "date": "19-Oct-2016",
//     "image": "/images/client-logos/opa.jpg",
//     "body": "<p>This includes providing design and support services to the various activities associated with Operations and Maintenance. </p>"
//   },
//   {
//     "title": "Procom have been appointed by Midas to carry out Bill Preparation tendering services",
//     "date": "19-Oct-2016",
//     "image": "/images/client-logos/midas.jpg",
//     "body": "<p>Procom have been appointed by Midas to carry out Bill Preparation tendering services on various projects including the construction of Adults with Disability Extra Care Homes in Aldershot, Binfields, Fernmount and Newman Bassett. The relocation Riverside Day Nursery from New Town Primary School to Palmer Park and the design and construction of Alexandra House a two storey, 58 bed care home. </p>"
//   },
//   {
//     "title": "Procom to provide Pre tender Cost Advice to Wycombe District Council",
//     "date": "19-Oct-2016",
//     "image": "/images/client-logos/wycombe.jpg",
//     "body": "<p>Procom are pleased to be continuing working with Wycombe District Council to provide Pre tender Cost Advice for their new project of Totteridge Recreation Ground Community Facility, Landscaping and Sports facilities.</p>"
//   },
//   {
//     "title": "Procom are now CHAS Accredited!",
//     "date": "22-Jun-2016",
//     "image": "/images/accreditations/chas-logo.jpg",
//     "body": "<p>We are excited to announce that Procom are now an accredited CHAS contractor (The Contractors Health and Safety Assessment Scheme). Health and Safety is very important to us at Procom and we are always striving to improve for our Clients, Employees and Members of the public.</p>"
//   },

//   {
//     "title": "New Additions to the Procom Team",
//     "date": "22-Jun-2016",
//     "image": "/images/org-logos/procom.jpg",
//     "body": "<p>We are pleased to announce that there are new additions to the Procom team:</p>\n<ul>\n\t<li>Ismail Saeed: Quantity Surveyor</li>\n\t<li>Jack Palmer: Quantity Surveyor</li>\n</ul>\n<p>We also welcome Paul Abbot to our team for a Summer Placement where we hope to guide him through the ropes in the next steps before returning to university.</p>"
//   },
//   {
//     "title": "Procom appointed by Bouygues on 570-room student village for the University of Gloucestershire",
//     "date": "4-Apr-2016",
//     "image": "/images/site/pittville.jpg",
//     "body": "<p>We are pleased to announce that Procom have been appointed by Bouygues to assist with the procurement of work packages for a 570-room student village for the University of Gloucestershire. Bouygues has got the green light to start building the student village, thanks to a £40m government guarantee. It’s the first time that a guarantee has been given to a privately-financed university accommodation project.</p>\n<p>The Pittville campus scheme in Cheltenham is being developed by Uliving, the company formed by Bouygues Development and Derwent Living. The development, which involves the delivery of 577 new bedrooms and the refurbishment of the 214 existing bedrooms, as well as the media centre, will be built by Bouygues UK while Derwent Living is responsible for operational and facilities management elements.</p>"
//   },
//   {
//     "title": "Procom secures a new commission with Wycombe District Council",
//     "date": "29-Mar-2016",
//     "image": "/images/client-logos/wycombe.jpg",
//     "body": "<p>Procom are pleased to announced a new commission with a new client Wycombe District Council for Quantity Surveying Services helping with the new community facility at Queen Elizabeth II playing fields.</p>\n<p>The brief includes location of a site for a multi-purpose community hall within the queen Elizabeth II park. This is to be used by the local community for meetings, children&#8217;s parties, brownies / beavers and social events. It will also enhance surrounding environment with trees, benches and landscaping.</p>"
//   },
//   {
//     "title": "Procom Appointed to Gloucestershire County Council Dynamic Purchasing System",
//     "date": "2-Feb-2016",
//     "image": "/images/client-logos/gcc_logo.jpg",
//     "body": "<p>We are pleased to announce that Procom have been appointed to Gloucestershire County Council&#8217;s Dynamic Purchasing System to deliver Quantity Surveying and other Professional Services  associated with the undertaking of Maintenance and other Built Environment work Programmes  for a period of ten (10) years until July 2025.  This is a significant achievement for Procom and this commission is a major step in our growth plans.</p>\n<p><b>Background of <span class=\"caps\">DPS</span></b></p>\n<p>Gloucestershire County Councils Asset Management and Property Services Department is responsible for the delivery of Capital Maintenance Works projects with a typical annual spend of £5,000,000 to £7,000,000 in recent years.</p>\n<p><b>Objectives</b></p>\n<p>The <span class=\"caps\">DPS</span> will provide certified Building, Engineering (mechanical/electrical) and Specialist consultants for engagement by the following users:</p>\n<ul>\n\t<li>Gloucestershire County Council</li>\n\t<li>District Councils</li>\n\t<li>Parish &amp; Town Councils</li>\n\t<li>Schools/Academies</li>\n\t<li>offices</li>\n\t<li>libraries</li>\n\t<li>day centres</li>\n\t<li>care homes</li>\n\t<li>fire stations</li>\n\t<li>other public buildings within Gloucestershire</li>\n</ul>\n<p>The intention of the <span class=\"caps\">DPS</span> is to pursue a partnering relationship for the period of the contract, which it is envisaged will include the following outcomes: continuous performance improvement in terms  of product, process and satisfaction rating from the sites, with an overarching aim of improving cost and time performance.  These outcomes will be identified, qualified and agreed jointly, with feedback loops and meetings to pass on learning, process improvements and judge the success of the <span class=\"caps\">DPS</span>.</p>"
//   },
//   {
//     "title": "New Additions to the Procom Team",
//     "date": "6-Jan-2016",
//     "image": "/images/org-logos/procom.jpg",
//     "body": "<p>Whilst <b>Roy Amos</b> has been working with Procom as a consultant for the past nearly two years, we can announce that he will join the Procom team as a member of staff on a part-time basis (3 days per week &#8211; Monday, Tuesday, Wednesday) and Board Director with effect from the 4th January.</p>\n<p>We welcome <b>Melissa Robinson</b> as the newest addition to the Procom team. Melissa will provide much needed support to the administrative function of the business on a full-time basis, acting as Offce Administrator and assisting with the growth and development of our internal marketing function.</p>"
//   },
//   {
//     "title": "Staff Appointments within the Existing Procom Team",
//     "date": "6-Jan-2016",
//     "image": "/images/org-logos/procom.jpg",
//     "body": "<p><b>Sue Gaff</b> has been appointed Finance Manager and will lead all finance related matters for the business going forward into 2016 and beyond.</p>\n<p>We are pleased to see <b>Kristie Keenan</b> return to work after a period of Maternity Leave. Kristie has now accepted a new position with Procom as Office Manager and HR Assistant on a part-time basis. Kristie will attend the office on Mondays and Tuesdays of each week.</p>\n<p>As you are all aware, <b>Tracey Scotten</b> has been providing maternity cover for Kristie Keenan. We are pleased to announce that we have managed to retain Tracey on a part-time basis to act in the capacity as Quality Manager and Finance Assistance. Tracey will be responsible for the smooth operation of the Procom Quality Management System and managing the timesheet and customer invoicing systems. Tracey will attend the Wokingham office on Wednesdays and Thursdays of each week.</p>"
//   },
//   {
//     "title": "Procom in Building Magazine's Top 150 Consultants",
//     "date": "24-Nov-2015",
//     "image": "/images/site/-2019.jpg",
//     "body": "<p>Procom continues to succeed in the rising construction market and have been named within Building Magazine’s Top 150 Consultants in 2015.</p>\n<p>This is a major milestone which marks our continued growth and success.  Building Magazine is one of the construction industry’s most respected and widely read publications and so to receive this recognition is something we are very proud of.</p>\n<p>In addition, Procom are in the Top 50 QS’s, ranking at number 36.</p>\n<p>More than 500 firms were contacted for this year’s Building Top Consultants’ Survey. They were surveyed on their number of chartered staff, non-chartered, technical staff and domestic and worldwide fee incomes. Practices were also asked questions relating to: staff recruitment, wage patterns, margins, areas of work, and work expectations.</p>\n<p>Link for subscribers of Building Magazine:</p>\n<ul>\n\t<li><a href=\"http://www.building.co.uk/top-50-qss/5077610.article\">http://www.building.co.uk/top-50-qss/5077610.article</a></li>\n\t<li><a href=\"http://www.building.co.uk/data/top-150-consultants/5077609.article\">http://www.building.co.uk/data/top-150-consultants/5077609.article</a></li>\n</ul>"
//   },
//   {
//     "title": "Procom appointed Cost Consultant for Refurbishment of Smithfields",
//     "date": "29-Oct-2015",
//     "image": "/images/client-logos/city_of_london.jpg",
//     "body": "<p>Procom appointed Cost Consultant for Refurbishment of Smithfields Poultry Market on behalf of the City of London Corporation</p>"
//   },
//   {
//     "title": "Procom enters into a Service Level Agreement with Wates Group",
//     "date": "28-Oct-2015",
//     "image": "/images/client-logos/wates.jpg",
//     "body": "<p>Procom have entered into a Service Level Agreement to provide Taking-Off and Bill Preparation services to Wates Group.  The service will service Wates requirements for Bill preparation across the UK.</p>"
//   },
//   {
//     "title": "Procom engages Chameleon Construction Marketing",
//     "date": "26-Oct-2015",
//     "image": "/images/client-logos/chameleon.jpg",
//     "body": "<p>Procom have engaged the services of <a href=\"http://chameleon-marketing.co.uk\">Chameleon Construction Marketing</a> to assist with <span class=\"caps\">PQQ</span> and Tender Submissions.</p>"
//   },
//   {
//     "title": "Procom expands offices in Wokingham, Berkshire.",
//     "date": "20-Oct-2015",
//     "image": "/images/org-logos/procom.jpg",
//     "body": "<p>Procom expands offices in Wokingham, Berkshire.  Procom signed a lease for the entire 2nd floor of their offices in Wokingham, providing capacity for future planned growth.</p>"
//   },
//   {
//     "title": "New commission from Barnet Homes",
//     "date": "20-Oct-2015",
//     "image": "/images/client-logos/barnet.jpg",
//     "body": "<p>New commission from Barnet Homes to undertake audit of costs for their Responsive Repairs and Maintenance programme.</p>"
//   },
//   {
//     "title": "Provision of cost managementservices for the City of London Corporation",
//     "date": "20-Sep-2015",
//     "image": "/images/client-logos/city_of_london.jpg",
//     "body": "<p>New commission from the City of London Corporation.  Provision of cost management services for the asbestos removal works to Smithfields Poultry Market.</p>"
//   },
//   {
//     "title": "New commission from Barnet Homes",
//     "date": "15-Sep-2015",
//     "image": "/images/client-logos/barnet.jpg",
//     "body": "<p>New commission from Barnet Homes to undertake a re-procurement of the OT Adaptations contract.  Procom will also be providing the post contract cost management service on behalf of Barnet Homes.</p>"
//   },
//   {
//     "title": "New commission from Ductclean Limited.",
//     "date": "10-Sep-2015",
//     "image": "/images/client-logos/ductclean.jpg",
//     "body": "<p>New commission from Ductclean Limited.  Procom were commissioned to assist with the tender for the Refurbishment of Guildford Cathedral, involving Asbestos Removal works, Upgrade of Electrical / lighting systems, Removal and upgrade of Acoustic Plastering and Decorations.  Also installation of new external hard landscaping and Illuminated Balustrading.</p>"
//   },
//   {
//     "title": "Procom Expands with New Staff",
//     "date": "5-Sep-2015",
//     "image": "/images/org-logos/procom.jpg",
//     "body": "<p>Procom welcomes new colleagues</p>\n<ul>\n\t<li>Robert Matthews; Trainee Quantity Surveyor</li>\n\t<li>Tracey Scotten; Office Manager</li>\n\t<li>Sue Gaff; Finance Assistant</li>\n</ul>"
//   },
//   {
//     "title": "Procom to undertake audit for Barnet Homes",
//     "date": "15-Jul-2015",
//     "image": "/images/client-logos/barnet.jpg",
//     "body": "<p>New commission from Barnet Homes to undertake audit of costs for their planned electrical works programme.</p>"
//   },
//   {
//     "title": "Changes to the CDM Regulations",
//     "date": "26-Mar-2015",
//     "image": "/images/org-logos/hse.jpg",
//     "body": "<p>Procom provides a range of services to support clients in their compliance with the Construction Design and Management (<span class=\"caps\">CDM</span>) Regulations.</p>\n<p>Changes to the Construction (Design &amp; Management) regulations are set to come into effect on 6th April 2015.</p>\n<p>The new regulations will introduce significant changes including:</p>\n<ul>\n\t<li>Extending the scope of the regulations to include domestic clients.</li>\n\t<li>Strengthening of client duties.</li>\n\t<li>Replacement of the <span class=\"caps\">CDM</span> Coordinator with a Principal Designer.</li>\n\t<li>Replacement of the explicit requirement for duty holder competence with the need for appropriate skills, knowledge training and experience.</li>\n\t<li>Change to the HSE’s notification level – An F10 notice will only be required for projects lasting more than 500 person days, or lasting more than 30 days with more than 20 workers simultaneously.</li>\n</ul>\n<p>When <span class=\"caps\">CDM</span> 2015 comes into force on the 6th April 2015, there will be a transitional period that will run for six months from 6th April 2015 to 6th October 2015.</p>\n<p>For projects starting before 6th April 2015, where the construction phase has not yet started and the client has not yet appointed a <span class=\"caps\">CDM</span> Co-ordinator, the client must appoint a principal designer as soon as it is practicable.</p>\n<p>If the <span class=\"caps\">CDM</span> co-ordinator has already been appointed, a principal designer must be appointed to replace the <span class=\"caps\">CDM</span> co-ordinator by 6th October 2015, unless the project comes to an end before this date.</p>"
//   },
//   {
//     "title": "Procom Certified to ISO9001",
//     "date": "04-Mar-2015",
//     "image": "/images/accreditations/iso9001-2.jpg",
//     "body": "<p>Procom are pleased to announce that we have recently achieved the internationally recognised <span class=\"caps\">ISO</span> 9001 certification underlining our commitment to our customers and our focus on quality.</p>\n<p><span class=\"caps\">ISO</span> 9001 was first introduced in 1987 and requires organisations to demonstrate that they do what they say they do and that they have a quality management system in place to ensure consistency and improvement; leading to high levels of performance and customer satisfaction. Certified organisations are committed to continuous improvement and are assessed annually to ensure progress is being maintained.</p>"
//   },
//   {
//     "title": "New Project – Crossrail",
//     "date": "19-Feb-2015",
//     "image": "/images/client-logos/crossrail_logo.jpg",
//     "body": "<p>Procom have secured a new commission with Costain / Skanska joint venture to provide professional services on the £150m C405 Paddington Crossrail project.  The scope of service involves dealing with change management.</p>\n<p>C405 Paddington Crossrail is part of Europe&#8217;s largest construction project, with the project employing over 10,000 people across more than 40 worksites throughout London and the southeast. Crossrail began construction at the North Dock in Canary Wharf on 15 May 2009. Five years on, over 57 million hours have been worked on Europe&#8217;s largest construction project.  There has been significant progress made during the first half of the construction programme constructing the tunnels and underground station spaces.</p>\n<p>The new Paddington station will be 260 metres long, 30 metres wide and 30 metres deep and will provide step free access to platform level along with an interchange with national rail and London Underground. Eastbourne Terrace will be reinstated above the station once main works have finished.</p>"
//   },
//   {
//     "title": "New Client - Triplepoint LLP",
//     "date": "29-Sep-2014",
//     "image": "/images/client-logos/triplepoint.jpg",
//     "body": "<p>Procom has been appointed as monitoring surveyor on 3nr Anaerobic Digestion Plants for Triplepoint <span class=\"caps\">LLP</span>.  The commission includes monitoring the plants in operation and monitoring feedstock and operations to ensure the efficiency of the plants are maximised.</p>"
//   },
//   {
//     "title": "New Project – ELWA Frog Island",
//     "date": "29-Sep-2014",
//     "image": "/images/client-logos/shanks.jpg",
//     "body": "<p>Procom have secured a new commission with Shanks Waste Management Ltd to provide Quantity Surveying services for the remedial works project at its flagship Frog Island Mechanical Biological Treatment (&#8216;<span class=\"caps\">MBT</span>&#8217;) facility in East London.</p>\n<p>The plant was damaged by fire in August 2014 and extensive remedial works are to be completed to ensure the plant is back up and running in 2015.  The plant is one of the two <span class=\"caps\">MBT</span> facilities forming part of the East London Waste Authority (&#8216;<span class=\"caps\">ELWA</span>&#8217;) <span class=\"caps\">PFI</span> project. The project is jointly owned and funded by Shanks Group plc and by John Laing Environmental Assets Group Ltd and the facilities are operated by Shanks.</p>\n<p>Procom will be working with the project team to schedule the works, prepare cost estimates, undertake procurement of the works and full contract administration to completion and final account.  The commission will involve working alongside Shanks insurers and their advisors.</p>"
//   },
//   {
//     "title": "Procom team completed the Challenge Cancer Night Ride",
//     "date": "20-Sep-2014",
//     "image": "/images/site/bikeride.png",
//     "zoomimage": "/images/site/bikeride.png",
//     "largeimage": true,
//     "body": "<p>Procom team completed the Challenge Cancer Night Ride from London to Brighton on 20/21st September 2014.</p>\n<p>Click on the image to zoom.</p>\n<p>Donations can still be made on our JustGiving page:<br />\n<a href=\"https://www.justgiving.com/teams/Procom\">https://www.justgiving.com/teams/Procom</a></p>"
//   },
//   {
//     "title": "New commission for Barnet Homes",
//     "date": "12-Aug-2014",
//     "image": "/images/client-logos/barnet.jpg",
//     "body": "<p>Project Management of Planned Works programme; circa £8m p.a.</p>"
//   },
//   {
//     "title": "Corporate Hospitality Event: The Guildford Cricket Week 2014",
//     "date": "24-June-2014",
//     "exclude_front": "22-July-2014",
//     "disable": false,
//     "image": "/images/site/guildfordcc.jpg",
//     "body": "<p><span class=\"caps\">MATCHDAY</span> <span class=\"caps\">CORPORATE</span> <span class=\"caps\">HOSPITALITY</span> &#8211; Tuesday, 22nd July 2014 – Surrey v Kent (<span class=\"caps\">DAY</span> <span class=\"caps\">THREE</span>)</p>\n<p>The Festival has long been a popular way for the major local businesses to entertain clients and contacts in a stylish and relaxed environment. Procom have a table for ten in a high quality Corporate Hospitality Marquee on the boundary edge.  This is a superb opportunity to enjoy County cricket at its very best.</p>\n<p><a href=\"http://guildfordcc.com/cricket_festival.html\">http://guildfordcc.com</a></p>"
//   },
//   {
//     "title": "Further commission from City of London Corporation",
//     "date": "24-June-2014",
//     "image": "/images/client-logos/city_of_london.jpg",
//     "body": "<p>Project: Smithfield&#8217;s Poultry Market<br />\nScope of the services: Early stage / feasibility cost planning / cost management: major refurbishment of Smithfield Poultry market (Grade II listed building).</p>\n<p><a href=\"http://www.cityoflondon.gov.uk\">http://www.cityoflondon.gov.uk</a></p>"
//   },
//   {
//     "title": "New Client: M+W Group",
//     "date": "24-June-2014",
//     "image": "/images/client-logos/mwgroup.jpg",
//     "body": "<p>M+W Group is a leading global engineering and construction partner for technology-based clients in the segments Electronics, Photovoltaics, Battery Cells, Life Sciences, Chemicals, Science &amp; Research, Energy &amp; Environment Technologies, Automotive, IT &amp; Telecoms, Space &amp; Security.</p>\n<p>Scope of services: To co-ordinate and deliver the post contract commercial management function of a new £18m Cancer Research Centre in Manchester.</p>\n<p><a href=\"http://www.mwgroup.net\">http://www.mwgroup.net</a></p>"
//   },
//   {
//     "title": "Procom Construction Line Certification",
//     "date": "18-May-2014",
//     "image": "/images/accreditations/constructionline-logo.jpg",
//     "body": "<p>Procom is pleased to announce certification by Construction Line, the UK&#8217;s largest register for pre-qualified contractors and consultants.<br />\n<a href=\"http://www.constructionline.co.uk\">http://www.constructionline.co.uk</a></p>"
//   },
//   {
//     "title": "Procom providing support to KeepMoat",
//     "date": "8-May-2014",
//     "image": "/images/client-logos/keepmoat.jpg",
//     "body": "<p>Procom please to annouce they are assiting KeepMoat in responsive repairs projects for Hyde and Metropolitan Housing.<br />\n<a href=\"http://www.keepmoat.com\">http://www.keepmoat.com</a></p>"
//   },
//   {
//     "title": "New Client: City of London Corporation",
//     "date": "25-Apr-2014",
//     "image": "/images/client-logos/city_of_london.jpg",
//     "body": "<p>Procom is pleased to announce City of London Corporation as a new client.</p>\n<p>The City Corporation has a special role and wide remit that goes beyond that of an ordinary local authority. They look after the City of London on behalf of all who live, work and visit.</p>\n<p><a href=\"http://www.cityoflondon.gov.uk\">http://www.cityoflondon.gov.uk</a></p>"
//   },
//   {
//     "title": "New Client: Downing LLP",
//     "date": "25-Apr-2014",
//     "image": "/images/client-logos/downing.jpg",
//     "body": "<p>Procom is pleased to announce Downing <span class=\"caps\">LLP</span> as a new client.</p>\n<p>Downing are currently responsible for the management of 16 VCTs, 15 EISs, 6 <span class=\"caps\">IHT</span> mitigation funds, 11 <span class=\"caps\">BPRA</span> schemes, and 1 <span class=\"caps\">OEIC</span>, amounting to over £400 million.</p>\n<p><a href=\"http://www.downing.co.uk\">http://www.downing.co.uk</a></p>"
//   }
// ];

export {
  newsData
};
