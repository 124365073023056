import {
  initApp,
  packageVersion as rxnanoBasePackageversion,
  rxnanoComponent,
  triggerPageChange,
  preloadMaps
} from '@appzuka/rxnano';
import { packageVersion as rxnanoContactformPackageversion } from '@appzuka/rxnano-contactform';
import { pageList } from './site/pages/pages';

import { insertFooter } from './site/components/footer';

const CommitInfo = require('virtual/commitinfo.js');

import { HeadMenu } from './site/components/headmenu';

import { HeadMenuContent, DropMenuContent, SideMenuContent, HeaderSiteLogo,
    FooterContacts, FooterAddress, FooterAddress2, FooterSiteLogo, SiteData} from './site/siteContent';

import './site/fonts.scss';


// FIXME: Inlcude findindex polyfill in rxnano
// https://tc39.github.io/ecma262/#sec-array.prototype.findindex
if (!Array.prototype.findIndex) {
  Object.defineProperty(Array.prototype, 'findIndex', {
    value: function(predicate) {
     // 1. Let O be ? ToObject(this value).
      if (this == null) {
        throw new TypeError('"this" is null or not defined');
      }

      var o = Object(this);

      // 2. Let len be ? ToLength(? Get(O, "length")).
      var len = o.length >>> 0;

      // 3. If IsCallable(predicate) is false, throw a TypeError exception.
      if (typeof predicate !== 'function') {
        throw new TypeError('predicate must be a function');
      }

      // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
      var thisArg = arguments[1];

      // 5. Let k be 0.
      var k = 0;

      // 6. Repeat, while k < len
      while (k < len) {
        // a. Let Pk be ! ToString(k).
        // b. Let kValue be ? Get(O, Pk).
        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
        // d. If testResult is true, return k.
        var kValue = o[k];
        if (predicate.call(thisArg, kValue, k, o)) {
          return k;
        }
        // e. Increase k by 1.
        k++;
      }

      // 7. Return -1.
      return -1;
    },
    configurable: true,
    writable: true
  });
}

const prerendering = window.location.search.match(/prerender/)?true:false;

let pageChangeObservable = initApp(pageList, SiteData);

pageChangeObservable.subscribe({
  next: (pageSelected: any) => {

    //TODO: Type pageSelected as PageListItem
    if (window.location.hostname === 'www.procom-uk.com') {

      let pageTitle = pageSelected.pageTitle.length > 0 ? `${SiteData.pageTitleBase} | ${pageSelected.pageTitle}` : SiteData.pageTitleBase;

      gtag('event', 'page_view', {
        'page_location': window.location.protocol + '//' + window.location.hostname + '/' + pageSelected.pageLocation,
        'page_title': pageTitle,
        'debug_mode': false
      });
    }


  }
});

// div already present in html
// only inserted once
// NICE: Could be delayed until after main render
if (!prerendering) {
  insertFooter(FooterContacts, FooterAddress, FooterAddress2, FooterSiteLogo,  [CommitInfo.server, CommitInfo.commit].join(' '), CommitInfo.builddate,
    ["fresh/"+CommitInfo.commit, rxnanoBasePackageversion, rxnanoContactformPackageversion]);
}

const wide = document.body.clientWidth>1023;
const DM = wide ? rxnanoComponent.DropMenu(DropMenuContent) : rxnanoComponent.SideMenu(SideMenuContent); 

// NICE: Could set up drop menu after main render.  Would need to show hamburger icon during first render
HeadMenu(pageChangeObservable, HeadMenuContent, HeaderSiteLogo, DM);
triggerPageChange(null);

if (!prerendering) {
  rxnanoComponent.ScrollMenu(pageChangeObservable, HeadMenuContent, HeaderSiteLogo, DM);  // Once installed, the scroll menu handles itself

  // Preload the maps api if required on the contact page
  preloadMaps(5000);
}

import './site/pages/overrides.scss'; // Overrides last
