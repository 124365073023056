const CommitInfo = require('virtual/commitinfo.js');

import { ContactType, rxnanoIcons, linkClick, JSXFactory, render } from '@appzuka/rxnano';
import { ISiteData } from "rxnanoModels";
import procomLogo from './procomlogo25.svg';

// import { type } from 'os';
const procomIcon = () => procomLogo('procom-logo');

interface FooterContactLine {
    type: ContactType,
    icon: any,
    label: string;
    target: string
}

export type FooterContactArray  = FooterContactLine[];

export const MapsAPIKey = 'AIzaSyAWGm7lJKidOYOZwusjGXLXgJApnXf5XQ8';


//TODO: Move w3w icon into rxnano

var w3wIcon = function (color) {
    var paths = [
        "M256,0C114.6,0,0,114.6,0,256S114.6,512,256,512,512,397.4,512,256,397.4,0,256,0Zm0,496C123.5,496,16,388.5,16,256S123.5,16,256,16,496,123.5,496,256,388.6,496,256,496Z",
        "m220.39,394.22c-3.17,0-6.3-.51-9.31-1.52-7.42-2.46-13.45-7.69-16.97-14.7-3.52-7.03-4.09-15-1.6-22.46l72.21-216.64c4.03-12.03,15.22-20.11,27.85-20.11,3.19,0,6.34.52,9.38,1.55,7.38,2.45,13.42,7.68,16.93,14.69,3.52,7.03,4.09,15,1.6,22.46l-72.21,216.64c-4.01,12.02-15.21,20.09-27.88,20.09Zm17.13-23.68l72.21-216.64c1.53-4.58,1.18-9.48-.98-13.8-2.16-4.31-5.84-7.51-10.43-9.03-1.87-.63-3.8-.95-5.75-.95-7.75,0-14.62,4.97-17.1,12.36l-72.21,216.64c-1.53,4.58-1.18,9.48.98,13.8,2.16,4.31,5.85,7.51,10.43,9.03,1.85.62,3.78.94,5.71.94,7.78,0,14.67-4.96,17.13-12.34Z",
        "m112.08,394.22c-3.17,0-6.3-.51-9.31-1.52-7.42-2.46-13.45-7.69-16.97-14.7-3.52-7.03-4.09-15-1.6-22.46l72.21-216.64c4.01-12.03,15.21-20.11,27.85-20.11,3.19,0,6.34.52,9.38,1.55,7.38,2.45,13.42,7.67,16.93,14.69,3.52,7.03,4.09,15,1.6,22.46l-72.21,216.64c-4.01,12.02-15.21,20.09-27.88,20.09Zm17.13-23.68l72.21-216.64c1.53-4.58,1.18-9.49-.98-13.8-2.16-4.31-5.84-7.51-10.43-9.03-1.87-.63-3.8-.95-5.75-.95-7.76,0-14.63,4.97-17.09,12.36l-72.21,216.64c-1.53,4.58-1.18,9.48.98,13.8,2.16,4.31,5.85,7.51,10.43,9.03,1.85.62,3.78.94,5.71.94,7.78,0,14.67-4.96,17.13-12.34Z",
        "m328.71,394.22c-3.17,0-6.3-.51-9.31-1.52-7.42-2.46-13.46-7.69-16.97-14.71-3.52-7.03-4.09-15-1.6-22.46l72.21-216.64c4.01-12.03,15.21-20.11,27.85-20.11,3.19,0,6.34.52,9.38,1.55,7.38,2.45,13.42,7.68,16.93,14.69,3.52,7.03,4.09,15,1.6,22.46l-72.21,216.64c-4.01,12.02-15.21,20.09-27.88,20.09Zm17.13-23.68l72.21-216.64c1.53-4.58,1.18-9.48-.98-13.8-2.16-4.31-5.84-7.51-10.43-9.03-1.87-.63-3.8-.95-5.75-.95-7.76,0-14.63,4.97-17.09,12.36l-72.21,216.64c-1.53,4.58-1.18,9.49.98,13.8,2.16,4.31,5.85,7.51,10.43,9.03,1.85.62,3.77.93,5.71.93,7.78,0,14.67-4.96,17.13-12.34Z"
    ];
    elementOpen('svg', null, null, 'class', 'w3w-icon contact-icon', 'viewBox', '0 0 512 512');
    paths.map(function (p, i) {
        elementVoid('path', "w3wiconpath-" + i, ['d', p]);
    });
    elementClose('svg');
};
// export { w3wIcon };


export const FooterContacts : FooterContactArray = [
    {type: ContactType.Link, icon: rxnanoIcons.worldIcon, label: 'www.procom-uk.com', target: '/'},
    {type: ContactType.Mail, icon: rxnanoIcons.emailIcon, label: 'info@procom-uk.com', target: 'info@procom-uk.com'},
    {type: ContactType.Line, icon: rxnanoIcons.phoneIcon, label: '+44 118 997 7760', target: ''},
    {type: ContactType.External, icon: rxnanoIcons.linkedinIcon, label: 'linkedin', target: 'https://www.linkedin.com/company/procom-im-ltd/'},
    {type: ContactType.Link, icon: rxnanoIcons.locationIcon, label: 'Contact Us', target: '/contact'},
    {type: ContactType.Link, icon: w3wIcon, label: 'W3W Front Door', target: 'https://w3w.co/raves.cheeks.volume'}, //TODO: Should open in new window
    {type: ContactType.Link, icon: w3wIcon, label: 'W3W Car Park', target: 'https://w3w.co/motor.deep.post'}
];

export const FooterAddress : FooterContactArray = [
    {type: ContactType.Link, icon: rxnanoIcons.locationIcon, label: 'Wokingham', target: '/contact'},
    {type: ContactType.Line, icon: undefined, label: 'Seymour House, The Courtyard', target: '/contact'},
    {type: ContactType.Line, icon: undefined, label: 'Denmark Street', target: '/contact'},
    {type: ContactType.Line, icon: undefined, label: 'Wokingham', target: '/contact'},
    {type: ContactType.Line, icon: undefined, label: 'Berkshire', target: '/contact'},
    {type: ContactType.Line, icon: undefined, label: 'RG40 2AZ', target: '/contact'}
];

export const FooterAddress2 : FooterContactArray = [
    {type: ContactType.Link, icon: rxnanoIcons.locationIcon, label: 'Birmingham', target: '/contact'},
    {type: ContactType.Line, icon: undefined, label: 'Unit 10, Imperial Works', target: '/contact'},
    {type: ContactType.Line, icon: undefined, label: 'Bromley Street', target: '/contact'},
    {type: ContactType.Line, icon: undefined, label: 'Stourbridge West Midlands', target: '/contact'},
    {type: ContactType.Line, icon: undefined, label: 'DY9 8HZ', target: '/contact'}
];

export const HeaderSiteLogo = () => {
    <render>
        <div class='logoouter'>
            { () => procomLogo('procom-logo')}
        </div>
    </render>

}
export const FooterSiteLogo = () => procomLogo('procom-logo');

interface HeadMenuItem {
    menuText: string
    link: string
    priority: number
}

type HeadMenuArray = HeadMenuItem[];
type DropMenuArray = HeadMenuArray[];

const defineMenuItem = (item:string, priority: number, link?:string) : HeadMenuItem  =>
  ({menuText: item, priority: priority, link: link===undefined?'/'+item.toLowerCase():link});

export const PageLabels = {
    'about': 'about us'
};

export const HeadMenuContent : HeadMenuArray = [
    defineMenuItem('About', 3, '/about'),
    defineMenuItem('Services', 6),
    defineMenuItem('Sectors', 5),
    defineMenuItem('News', 4, '/about/news'),
    defineMenuItem('People', 2, '/about/people'),
    defineMenuItem('Contact', 7)
];

export const TopMenuContent = HeadMenuContent;

export const serviceList = [
    'Cost Management',
    'Stock Condition Surveys',
    'Bills of Quantities',
    'Project Management',
    'Monitoring Surveyors',
    'Forensic Cost Auditing',
    'Dispute Resolution',
    'Building Surveying',
    'Cladding Remediation'
];

const sectorList = [
    'Renewable Energy',
    'Aviation & Transport',
    'Education',
    'Office & Commercial',
    'Leisure & Sport',
    'Residential'
];

const DropMenuContentArray: DropMenuArray = [
    [
        defineMenuItem('Procom', 1, '/'),
        defineMenuItem('About Us', 1, '/about'),
        defineMenuItem('People', 1, '/about/people'),
        defineMenuItem('Join Us', 1, '/about/people/join'),
        defineMenuItem('News', 1, '/about/news'),
        defineMenuItem('Clients', 1, '/about/clients'),
        defineMenuItem('Testimonials', 1, '/about/clients/testimonials'),
        defineMenuItem('Recent Projects', 1, '/about/recentprojects'),
        defineMenuItem('Contact Us', 1, '/contact'),
    ],
    [
        defineMenuItem('Services', 1),
        ...serviceList.map(s => defineMenuItem(`*${s}`, 1, `/services/${s.replace(' & ', '-').replace(/ /g, '-').toLowerCase()}`),)
    ],
    [
        defineMenuItem('Sectors', 1),
        ...sectorList.map(s => defineMenuItem(`*${s}`, 1, `/sectors/${s.replace(' & ', '-').replace(/ /g, '-').toLowerCase()}`),)
    ],
    [
        defineMenuItem('Policies', 1, '/policies'),
        defineMenuItem('Privacy', 1, '/policies/privacy'),
        defineMenuItem('Terms', 1, '/policies/terms'),
        defineMenuItem('Code of Conduct', 1, '/about/code'),
        defineMenuItem('Quality', 1, '/about/quality'),
        defineMenuItem('Values', 1, '/about/values'),
    ]
];

type vf = (string?) => void;

export const DropMenuContent: vf = (currentLocation?: string) => {
  DropMenuContentArray.map((c, j) => {
    render(<div class='hovermenucolumn'>
      <ul>
        {() => c.map(({ menuText, link }, i) => {
          let classList, finalMenuText;
          if (menuText[0] === '*') {
            classList = 'other-link';
            finalMenuText = menuText.slice(1);
          } else {
            classList = currentLocation === link ? 'active-link' : 'other-link';
            finalMenuText = menuText;
          }
          render(<li>
            <a href={link} class={classList}
               onclick={(event) => {
                linkClick(event, link);
              }}>
              {(finalMenuText.length === 0) ? ' ' : finalMenuText}
            </a>
          </li>)
        })}
      </ul>
    </div>)
  });
}

type SideMenuArray = Array<Array<Array<string>>>;

const SideMenuContentArray: SideMenuArray = [
    [
        ['About Us', '/about'],
        ['Home', '/'],
        ['Contact', '/contact'],
        ['People', '/about/people'],
        ['Join Us', '/about/people/join'],
        ['News', '/about/news'],
        ['Clients', '/about/clients'],
        ['Testimonials', '/about/clients/testimonials'],
        ['Code of Conduct', '/about/code'],
        ['Values', '/about/values'],
        ['Recent Projects', '/about/recentprojects'],
    ],
    [
        ['Services', '/services'],
        ...serviceList.map(s =>[s, `/services/${s.replace(' & ', '-').replace(/ /g, '-').toLowerCase()}`])
    ],
    [
        ['Sectors', '/sectors'],
        ...sectorList.map(s =>[s, `/sectors/${s.replace(' & ', '-').replace(/ /g, '-').toLowerCase()}`])
    ],
    [
        ['Privacy', '/policies/privacy'],
        ['Terms', '/policies/terms'],
        ['Quality', '/about/quality']
    ],
];

// TODO: If the link us is the same the menu does not close
export const SideMenuContent: SideMenuArray | vf = (currentLocation?: string) => {
  SideMenuContentArray.map((menuColumn, j) => {
    render(
    <div class='sidemenucolumn'>
      <ul>
      { () => menuColumn.map(([menuText, link], i) => {
        render(
        <li>
          <a href={link}
             class={((currentLocation === link) && ((j === 0) || (i === 0))) ? 'active-link' : 'other-link'}
             onclick={(event) => { linkClick(event, link) }}>
            {menuText}
          </a>
        </li>)
      }) }
      </ul>
    </div>)
    });
}

export const MapInfo = {
    latitude: 51.535708,
    longitude: -0.120786,
    zoom: 8,
    title: 'Us'
};

interface ContactPageItem {
    icon: any,
    label: string,
    linkText: string,
    link?: string
}

type ContactPageArray = ContactPageItem[];

const defineContactPageItem = (icon: any, label:string, linkText:string, link?:string) : ContactPageItem  =>
  ({icon: icon, label: label, linkText: linkText, link: link});

export const ContactPageContent : ContactPageArray = [
    defineContactPageItem(rxnanoIcons.locationIcon, 'Address', 'London, UK'),
    defineContactPageItem(rxnanoIcons.phoneIcon, 'Phone', '07777 123456'),
    defineContactPageItem(rxnanoIcons.worldIcon, 'Web', 'www.website.com', '/'),
    defineContactPageItem(rxnanoIcons.emailIcon, 'eMail', 'info@website.com', 'mailto:info@website.com'),
    defineContactPageItem(rxnanoIcons.linkedinIcon, 'linkedIn', 'www.linkedin.com', 'https://www.linkedin.com'),
];

import { elementOpen, elementVoid, elementClose, } from 'incremental-dom';



//
const wokinghamAddress = {
    title: 'Procom Wokingham',
    mapinfo: {
        latitude: 51.40899912404043,
        longitude: -0.8344874170988167,
        zoom: 8,
        title: 'Procom Wokingham'
    },
    details: [
        defineContactPageItem(rxnanoIcons.locationIcon, 'Address', 'Seymour House, The Courtyard, Denmark Street, Wokingham, Berkshire, RG40 2AZ, United Kingdom'),
        defineContactPageItem(rxnanoIcons.phoneIcon, 'Phone', '+44 118 997 7760'),
        defineContactPageItem(rxnanoIcons.worldIcon, 'Web', 'www.procom-uk.com', '/'),
        defineContactPageItem(rxnanoIcons.emailIcon, 'eMail', 'info@procom-uk.com', 'mailto:info@procom-uk.com'),
        // defineContactPageItem(twitterIcon, 'twitter', 'twitter @ProcomLTD', 'https://twitter.com/ProcomLTD'),
        defineContactPageItem(rxnanoIcons.linkedinIcon, 'linkedIn', 'www.linkedin.com', 'https://www.linkedin.com/company/procom-im-ltd'),
        <div class='contact-address contact-row'>
            {() => w3wIcon('#333')}
            <p class='contact-label'>W3W<br />Front Gate</p>
            <p class='contact-row-content'>
                    <a class='contact-row-content-link' href='https://w3w.co/raves.cheeks.volume' target='_blank' rel='noopener noreferrer'>raves.cheeks.volume</a>
            </p>
        </div>,
        <div class='contact-address contact-row'>
            {() => w3wIcon('#333')}
            <p class='contact-label'>W3W<br />Car Park</p>
            <p class='contact-row-content'>
                    <a class='contact-row-content-link' href='https://w3w.co/motor.deep.post' target='_blank' rel='noopener noreferrer'>motor.deep.post</a>
            </p>
        </div>
    ]
}

const birminghamAddress = {
    title: 'Procom Birmingham',
    mapinfo: {
        latitude: 52.45937,
        longitude:  -2.10681,
        zoom: 8,
        title: 'Procom Birmingham'
    },
    details: [
        defineContactPageItem(rxnanoIcons.locationIcon, 'Address', 'Unit 10, Imperial Works Bromley Street, Stourbridge West Midlands. DY9 8HZ'),
        defineContactPageItem(rxnanoIcons.phoneIcon, 'Phone', '+44 121 827 5249'),
        defineContactPageItem(rxnanoIcons.worldIcon, 'Web', 'www.procom-uk.com', '/'),
        defineContactPageItem(rxnanoIcons.emailIcon, 'eMail', 'info@procom-uk.com', 'mailto:info@procom-uk.com'),
        // defineContactPageItem(twitterIcon, 'twitter', 'twitter @ProcomLTD', 'https://twitter.com/ProcomLTD'),
        defineContactPageItem(rxnanoIcons.linkedinIcon, 'linkedIn', 'www.linkedin.com', 'https://www.linkedin.com/company/procom-im-ltd'),
    ]
}

export const ContactAddressList = [
    () => {},
    wokinghamAddress,
    birminghamAddress
]

export const SiteData:ISiteData = {
    ga: window.location.hostname==='www.procom-uk.com'?'UA-47087919-4':null,
    pageTitleBase: 'Procom',
    assetBase: 'https://appzuka-web.s3.eu-west-2.amazonaws.com/procom/imagestream/',
    fullpixlist: 'fullpixlist.json',
    index: CommitInfo.index,
    siteName: 'www.procom-uk.com',
    highlight: '#68b0e0',
    pageLabels: {
        '/about': 'about us'
    },
    cognitoConfig: {
        region: 'eu-west-1',
        clientID: '28toug1d6udh9ipmk1cbejd07q',
        userpool: 'eu-west-1_ktnvCHUat',
        identityPool: 'eu-west-1:72ed9f40-bf99-4692-960d-818c47b16351'
    }
}

export const PrivacyContacts = {
    company: 'Procom',
    policyDate: 'November 2019',
    contact: 'info@procom-uk.com'
}
