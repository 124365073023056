/*


npx tsc -P genProjects.json


sector
residential
renewable
education
commercial
leisure
aviation



'Renewable Energy', 'Civil', 'Education', 'Offices and Commercial'
'Residential', 'Retail', 'Sports and Recreational', 'Science and Pharmaceuticals', 'Transportation', 'Industrial', 'Hospitality', 'Defence', 'Aviation', 'Infrastructure', 'Healthcare', 'Utilities', 'Religious'

*/



interface ProjectData {
    client: string
    contract: string
    date?: string
    description: string
    duration: string
    images: string[] | string
    name: string
    project: string
    sector: string
    sectors: string[]
    services: string
    title: string
    value: string
    hide?: boolean
}

const projectData:ProjectData[] = [
    {
        client: '',
        contract: '',
        date: '2018-02-28',
        description: '<p>New anaerobic digestion plant</p>',
        duration: '',
        images: ['westpole.jpg'],
        name: 'westpole',
        project: 'Anaerobic Digestion Facility, Westpole',
        sector: 'renewable',
        sectors: ['Renewable Energy', 'Civil'],
        services: 'Project Management and Cost Management',
        title: 'Anaerobic Digestion Facility',
        value: '',
    },
    {
        client: '',
        contract: '',
        date: '2018-02-28',
        description: '<p>New plastic reprocessing facility</p>',
        duration: '',
        images: ['viridorplastics.jpg'],
        name: 'viridorplastics',
        project: 'Plastics Reprocessing Facility, Rochester',
        sector: 'renewable',
        sectors: ['Renewable Energy', 'Civil'],
        services: 'Project Management and Cost Management',
        title: 'Plastics Reprocessing Facility',
        value: '',
    },
    {
        client: '',
        contract: '',
        date: '2018-02-28',
        description: '<p>State of the art facility at an existing landfill site.</p>',
        duration: '',
        images: ['viridor-beddington.jpg', 'viridor-beddington-site.jpg'],
        name: 'viridor-beddington',
        project: 'Energy Recovery Facility',
        sector: 'renewable',
        sectors: ['Renewable Energy', 'Civil'],
        services: 'Commercial & Contracts Management',
        title: 'Energy Recovery Facility',
        value: '',
    },
    {
        client: '',
        contract: '',
        date: '2018-02-28',
        description: '<p>New resource recovery centre.</p>',
        duration: '',
        images: ['viridor-avonmouth-site.jpg'],
        name: 'viridor-avonmouth-site',
        project: 'Resource Recovery Centre',
        sector: 'renewable',
        sectors: ['Renewable Energy', 'Civil'],
        services: 'Commercial & Contracts Management',
        title: 'Resource Recovery Centre',
        value: '',
    },
    {
        client: '',
        contract: '',
        date: '2018-02-28',
        description: '<p>Creation of new community leisure facilities.</p>',
        duration: '',
        images: ['totteridge.jpg'],
        name: 'totteridge',
        project: 'Totteridge',
        sector: 'commercial',
        sectors: ['Offices and Commercial'],
        services: 'Employer’s Agent',
        title: 'Totteridge',
        value: '',
    },
    {
        client: '',
        contract: '',
        date: '2018-02-28',
        description: '<p>New build college buildings and facilities.</p>',
        duration: '',
        images: ['stgeorgescollege.jpg'],
        name: 'stgeorgescollege',
        project: 'St. George&#39;s College',
        sector: 'education',
        sectors: ['Education'],
        services: 'Bills of Quantities',
        title: 'St. George’s College',
        value: '',
    },
    {
        client: '',
        contract: '',
        date: '2018-02-28',
        description: '',
        duration: '',
        images: ['skillscentre.jpg'],
        name: 'skillscentre',
        project: 'Centre for Engineering, Manufacturing and Advanced Skills Training',
        sector: 'commercial',
        sectors: ['Offices and Commercial'],
        services: 'Cost Management',
        title: 'Engineering Training Centre',
        value: '',
    },
    {
        client: '',
        contract: '',
        date: '2018-02-28',
        description: '<p>Household waste, recycling centres, transfer loading stations, autoclaves, materials recycling facility, composting facility, education centre, refuse collection & vehicle facility.</p>',
        duration: '',
        images: ['shankswakefield.jpg'],
        name: 'shankswakefield',
        project: 'Shanks - Wakefield',
        sector: 'renewable',
        sectors: ['Renewable Energy', 'Civil'],
        services: 'Cost Management',
        title: 'Shanks - Wakefield',
        value: '',
    },
    {
        client: '',
        contract: '',
        date: '2018-02-28',
        description: '<p>Nuclear decommissioning works.</p>',
        duration: '',
        images: ['sellafield.jpg'],
        name: 'sellafield',
        project: 'Sellafield Nuclear',
        sector: 'renewable',
        sectors: ['Renewable Energy', 'Civil'],
        services: 'Forensic Auditing',
        title: 'Sellafield Nuclear',
        value: '',
    },
    {
        client: '',
        contract: '',
        date: '2018-02-28',
        description: '<p>Refurbishment and new build facilities for returning troops, including accommodation, lecture theatre and nursery.</p>',
        duration: '',
        images: ['rafinnsworth.jpg'],
        name: 'rafinnsworth',
        project: 'RAF Innsworth',
        sector: 'commercial',
        sectors: ['Offices and Commercial'],
        services: 'Quantity Surveying',
        title: 'RAF Innsworth',
        value: '',
    },
    {
        client: '',
        contract: '',
        date: '2018-02-28',
        description: '<p>Refurbishment of existing 5 storey underground car park.</p>',
        duration: '',
        images: ['parliament.jpg'],
        name: 'parliament',
        project: 'Houses of Parliament MSCP',
        sector: 'commercial',
        sectors: ['Offices and Commercial'],
        services: 'Quantity Surveying',
        title: 'Houses of Parliament MSCP',
        value: '',
    },
    {
        client: '',
        contract: '',
        date: '2018-02-28',
        description: '<p>Infrastructure works to new residential development.</p>',
        duration: '',
        images: ['north-east-didcot.jpg'],
        name: 'north-east-didcot',
        project: 'North East Didcot',
        sector: 'residential',
        sectors: ['Residential'],
        services: 'Quantity Surveying',
        title: 'North East Didcot',
        value: '',
    },
    {
        client: '',
        contract: '',
        date: '2018-02-28',
        description: '<p>Responsive repairs contract</p>',
        duration: '',
        images: ['hydelogo.jpg'],
        name: 'hydelogo',
        project: 'Hyde Group &mdash; Responsive Repairs & Maintenance',
        sector: 'residential',
        sectors: ['Residential'],
        services: 'Cost Consultant',
        title: 'Responsive Repairs & Maintenance',
        value: '',
    },
    {
        client: '',
        contract: '',
        date: '2018-02-28',
        description: '<p>Nuclear decommissioning works.</p>',
        duration: '',
        images: ['harwell.jpg'],
        name: 'harwell',
        project: 'Harwell',
        sector: 'education',
        sectors: ['Offices and Commercial'],
        services: 'Quantity Surveying',
        title: 'Harwell',
        value: '',
    },
    {
        client: '',
        contract: '',
        date: '2018-02-28',
        description: '<p>New build school buildings and facilities.</p>',
        duration: '',
        images: ['harrow.jpg'],
        name: 'harrow',
        project: 'Harrow School',
        sector: 'education',
        sectors: ['Education'],
        services: 'Quantity Surveying',
        title: 'Harrow School',
        value: '',
    },
    {
        client: '',
        contract: '',
        date: '2018-02-28',
        description: '<p>Civils works to freightliner facilities.</p>',
        duration: '',
        images: ['freightliner.jpg'],
        name: 'freightliner',
        project: 'Freightliner',
        sector: 'commercial',
        sectors: ['Offices and Commercial'],
        services: 'Cost Planning',
        title: 'Freightliner',
        value: '',
    },
    {
        client: '',
        contract: '',
        date: '2018-02-28',
        description: '<p>New 5 storey office and conference building.</p>',
        duration: '',
        images: ['ecsat1.jpg', 'ecsat2.jpg'],
        name: 'ecsat',
        project: 'European Centre for Space Applications and Telecommunications (ECSAT)',
        sector: 'commercial',
        sectors: ['Offices and Commercial'],
        services: 'Commercial Management',
        title: 'ECSAT European Centre',
        value: '',
    },
    {
        client: '',
        contract: '',
        date: '2018-02-28',
        description: '<p>Feasibility exercises on potential vertical farming facilities.</p>',
        duration: '',
        images: ['downing.jpg'],
        name: 'downing-vertical',
        project: 'Vertical Farming Facilities (Feasibility Only)',
        sector: 'commercial',
        sectors: ['Offices and Commercial'],
        services: 'Initial feasibility commercial services',
        title: 'Vertical Farming Facilities',
        value: '',
    },
    {
        client: '',
        contract: '',
        date: '2018-02-28',
        description: '<p>Internal refurbishment and fit out of existing flats including MEP upgrades and sprinkler installation.</p>',
        duration: '',
        images: ['chaucer.jpg'],
        name: 'chaucer',
        project: 'Chaucer House',
        sector: 'residential',
        sectors: ['Residential'],
        services: 'Cost Management',
        title: 'Chaucer House',
        value: '',
    },
    {
        client: '',
        contract: '',
        date: '2018-02-28',
        description: '<p>Upgrade and improvement works</p>',
        duration: '',
        images: ['california-park.jpg'],
        name: 'california-park.',
        project: 'California Country Park',
        sector: 'commercial',
        sectors: ['Offices and Commercial'],
        services: 'Bills of Quantities',
        title: 'California Country Park',
        value: '',
    },
    {
        client: '',
        contract: '',
        date: '2018-02-28',
        description: '<p>New build residential development on top of existing flats.</p>',
        duration: '',
        images: ['burntoak.jpg'],
        name: 'burntoak',
        project: 'Burnt Oak Broadway',
        sector: 'residential',
        sectors: ['Residential'],
        services: 'Cost Planning',
        title: 'Burnt Oak Broadway',
        value: '',
    },
    {
        client: '',
        contract: '',
        date: '2018-02-28',
        description: '<p>Internal and external works including services and building works to housing stock.</p>',
        duration: '',
        images: ['bracknellforest.jpg'],
        name: 'bracknellforest',
        project: 'Bracknell Forest Homes – Planned Works',
        sector: 'residential',
        sectors: ['Residential'],
        services: 'Cost Management',
        title: 'Bracknell Forest Homes',
        value: '',
    },
    {
        client: 'Wycombe DC',
        contract: '',
        date: '2017-05-12',
        description: '<p>Conversion of existing Grade II Listed building to provide covered traders units and public eating area.</p>',
        duration: '',
        images: ['littlemarket.jpg'],
        name: 'markethouse',
        project: 'Little Market House',
        sector: 'commercial',
        sectors: ['Retail'],
        services: '',
        title: 'Little Market House',
        value: '',
    },
    {
        client: 'Keepmoat',
        contract: '',
        date: '2017-05-12',
        description: '<p>Residential Development; refurbishment</p>',
        duration: '',
        images: ['keepmoat.jpg'],
        name: 'ellisonwilkins',
        project: 'George Ellison & Eric Wilkins',
        sector: 'residential',
        sectors: ['Residential'],
        services: '',
        title: 'Ellison & Wilkins',
        value: '',
    },
    {
        client: 'Westminster City Council',
        contract: '',
        date: '2017-05-12',
        description: '<p>Bouygues UK constructed a replacement for the 1960s Pimlico School, on the existing site, when Westminster City Council realised that the older building would simply not be able to cope with the needs of the school and wider community into the future. The new Pimlico Academy featured a sports hall, science laboratories, technology classrooms, kitchen and dining areas and a Speech Language and Communication Needs (SLCN) facility. The community benefited from a new site for Pimlico Library and Westminster\'s Adult Education Service and with a green roof, the environmental credentials of the Academy saw it used in 2010 as the launch venue for the Zero Carbon Task Force Initiative.</p>',
        duration: '',
        images: ['pimlico-academy.jpg'],
        name: 'pimlicoacademy',
        project: 'Pimlico Academy, Westminster',
        sector: 'education',
        sectors: ['Education'],
        services: '',
        title: 'Pimlico Academy',
        value: '',
    },
    {
        client: 'London Borough of Waltham Forest',
        contract: '',
        date: '2017-05-12',
        description: '<p>The Grade II Listed buildings comprising Walthamstow School for Girls needed to be extended and refurbished elegantly and cautiously to accommodate 900 pupils in a way that suited a modern educational environment. A spectacular glazed foyer set on multiple levels was equipped with folding screens allowing the space to reconfigured for drama, assembly or larger gatherings. The design of this sensitive development, delivering flexibility and excitement, won a BCSE Design Award in 2011 and gained a \'Very Good\' BREEAM assessment for its environmental credentials.</p>',
        duration: '',
        images: ['walthamstow.jpg'],
        name: 'walthamstow',
        project: 'Walthamstow School for Girls',
        sector: 'education',
        sectors: ['Education'],
        services: '',
        title: 'Walthamstow School for Girls, Walthamstow',
        value: '',
    },
    {
        client: 'London Borough of Tower Hamlets/Warings',
        contract: '',
        date: '2017-05-12',
        description: '<p>Residential school Bowden House, is a new build project set in a residential zone near the South Downs National Park, close to Seaford. The school looks after 40 boys, all with behavioural and emotional difficulties, and the project was initiated under the auspices of Tower Hamlets\' BSF programme. Separation of students\' school and homes lives was encouraged by keeping a strong dividing line between the physical buildings providing for those two functions. The buildings aimed for the \'Excellent\' BREEAM grade, meaning natural ventilation, underfloor heating fed by air source heat pumps and a sustainable urban drainage system which involved green roofs and rainwater harvesting.</p>',
        duration: '',
        images: ['bowden.jpg'],
        name: 'bowdenschool',
        project: 'Bowden House School',
        sector: 'education',
        sectors: ['Education'],
        services: '',
        title: 'Bowden House School, East Sussex',
        value: '',
    },
    {
        client: 'London Borough of Waltham Forest',
        contract: '',
        date: '2017-05-12',
        description: '<p>Kelmscott School has undergone a complete refurbishment thanks to the Bouygues Partnership for Education and Community. The school was being remodelled and extended, significantly improving the school\'s functionality. In addition the presence and image of the school has been significantly enhanced, upgrading its image in the community. This was accomplished by creating a very prominent entrance, giving on to a new street which connects to buildings old and new, providing an easily navigable central axis. A new building provides for the performing arts and another a new catering and dining facility. The complete project was awarded the 2008 PfS Best Design for a Remodelled School prize.</p>',
        duration: '',
        images: ['kelmscott.jpg'],
        name: 'kelmscott',
        project: 'Kelmscott School, Waltham Forest',
        sector: 'education',
        sectors: ['Education'],
        services: '',
        title: 'Kelmscott School',
        value: '',
    },
    {
        client: 'Vinci',
        contract: '',
        date: '2017-04-27',
        description: '<p>New build college facility</p>',
        duration: '',
        images: ['eastbourne.jpg'],
        name: 'eastbourne',
        project: 'Eastbourne College',
        sector: 'education',
        sectors: ['Education'],
        services: '',
        title: 'Eastbourne College',
        value: '',
    },
    {
        client: 'Thomas Vale',
        contract: '',
        date: '2017-04-27',
        description: '<p>New build facilities for the fire service</p>',
        duration: '',
        images: ['thomasvale.jpg'],
        name: 'pfi',
        project: 'PFI',
        sector: 'commercial',
        sectors: ['Civil'],
        services: '',
        title: 'PFI',
        value: '',
    },
    {
        client: 'Swindon',
        contract: '',
        date: '2017-04-27',
        description: '<p>Upgrades to existing residential properties</p>',
        duration: '',
        images: ['swindon1.jpg', 'swindon2.jpg'],
        name: 'ewiphaseii',
        project: 'EWI Phase II',
        sector: 'residential',
        sectors: ['Residential'],
        services: '',
        title: 'EWI Phase II',
        value: '',
    },
    {
        client: 'S & C Clatter',
        contract: '',
        date: '2017-04-27',
        description: '<p>New build sports and leisure facility</p>',
        duration: '',
        images: ['waterside.jpg'],
        name: 'waterside',
        project: 'Waterside Drive',
        sector: 'leisure',
        sectors: ['Sports and Recreational'],
        services: '',
        title: 'Waterside Drive',
        value: '',
    },
    {
        client: 'Midas',
        contract: '',
        date: '2017-04-27',
        description: '<p>New build School facility</p>',
        duration: '',
        images: ['romsey.jpg'],
        name: 'romsey',
        project: 'Romsey Primary School ',
        sector: 'education',
        sectors: ['Education'],
        services: '',
        title: 'Romsey Primary School ',
        value: '',
    },
    {
        client: 'Midas',
        contract: '',
        date: '2017-04-27',
        description: '<p>New build School facility</p>',
        duration: '',
        images: ['waltham1.jpg', 'waltham2.jpg'],
        name: 'bishopswaltham',
        project: 'Bishops Waltham Infant and Junior School',
        sector: 'education',
        sectors: ['Education'],
        services: '',
        title: 'Bishops Waltham School',
        value: '',
    },
    {
        client: 'LBS',
        contract: '',
        date: '2017-04-27',
        description: '<p>Residential Development; conversion</p>',
        duration: '',
        images: ['cadogan1.jpg', 'cadogan2.jpg'],
        name: 'cadogan',
        project: 'Cadogan Square',
        sector: 'residential',
        sectors: ['Residential'],
        services: '',
        title: 'Cadogan Square',
        value: '',
    },
    {
        client: 'LBD',
        contract: '',
        date: '2017-04-27',
        description: '<p>Fit out of restaurant</p>',
        duration: '',
        images: ['bunnychow1.jpg', 'bunnychow2.jpg'],
        name: 'bunnychow',
        project: 'Bunnychow',
        sector: 'leisure',
        sectors: ['Retail'],
        services: '',
        title: 'Bunnychow',
        value: '',
    },
    {
        client: 'Keepmoat',
        contract: '',
        date: '2017-04-27',
        description: '<p>Residential Development; conversion</p>',
        duration: '',
        images: ['keepmoat-carolyn.jpg', 'keepmoat-carolyn2.jpg'],
        name: 'carolyn',
        project: 'Carolyn House',
        sector: 'residential',
        sectors: ['Residential'],
        services: '',
        title: 'Carolyn House',
        value: '',
    },
    {
        client: 'Claude Fenton',
        contract: '',
        date: '2017-04-27',
        description: '<p>Residential Development; new build</p>',
        duration: '',
        images: ['fenton-beomonds2.jpg'],
        name: 'beomonds',
        project: 'Beomonds Refurbishment',
        sector: 'residential',
        sectors: ['Residential'],
        services: '',
        title: 'Beomonds Refurbishment',
        value: '',
    },
    {
        client: 'Bouygues',
        contract: '',
        date: '2017-04-27',
        description: '<p>New build student accommodation</p>',
        duration: '',
        images: ['bouygues-pitville.jpg'],
        name: 'pittville',
        project: 'Pittville Cheltenham',
        sector: 'education',
        sectors: ['Education'],
        services: '',
        title: 'Pittville Cheltenham',
        value: '',
    },
    {
        client: 'Bouygues',
        contract: '',
        date: '2017-04-27',
        description: '<p>New build School facility</p>',
        duration: '',
        images: ['bouygues-furze.jpg'],
        name: 'furze',
        project: 'Furze Down School Project',
        sector: 'education',
        sectors: ['Education'],
        services: '',
        title: 'Furze Down School Project',
        value: '',
    },
    {
        client: 'Bouygues',
        contract: '',
        date: '2017-04-27',
        description: '<p>New science research facility</p>',
        duration: '',
        images: ['bouygues-escat.jpg', 'bouygues-escat2.jpg'],
        name: 'escat',
        project: 'Escat',
        sector: 'education',
        sectors: ['Science and Pharmaceuticals'],
        services: '',
        title: 'Escat',
        value: '',
    },
    {
        client: 'Balfour Beatty',
        contract: '',
        date: '2017-04-27',
        description: '<p>Construction of new build car park and bowling alley</p>',
        duration: '',
        images: ['beatty-gerrards.jpg'],
        name: 'gerrardscross',
        project: 'Gerrard\'s Cross Car Park',
        sector: 'leisure',
        sectors: ['Civil'],
        services: '',
        title: 'Gerrard\'s Cross Car Park',
        value: '',
    },
    {
        client: 'Alfred Quincey',
        contract: '',
        date: '2017-04-27',
        description: '<p>Residential Development; conversion</p>',
        duration: '',
        images: ['quincey-leon.jpg'],
        name: 'leon',
        project: 'Leon House',
        sector: 'residential',
        sectors: ['Residential'],
        services: '',
        title: 'Leon House',
        value: '',
    },
    {
        client: 'Alfred Quincey',
        contract: '',
        date: '2017-04-27',
        description: '<p>Residential Development; new build</p>',
        duration: '',
        images: ['quincey-firs.jpg'],
        name: 'firs',
        project: 'Firs Avenue',
        sector: 'residential',
        sectors: ['Residential'],
        services: '',
        title: 'Firs Avenue',
        value: '',
    },
    {
        client: 'Alfred Quincey',
        contract: '',
        date: '2017-04-27',
        description: '<p>Residential Development; conversion</p>',
        duration: '',
        images: ['quincey-rushworth.jpg'],
        name: 'rushwood',
        project: 'Rushworth Street',
        sector: 'residential',
        sectors: ['Residential'],
        services: '',
        title: 'Rushworth Street',
        value: '',
    },
    {
        client: 'Alfred Quincey',
        contract: '',
        date: '2017-04-27',
        description: '<p>Residential Development; conversion</p>',
        duration: '',
        images: ['quincey-gaywood.jpg'],
        name: 'gaywood',
        project: 'Gaywood House',
        sector: 'residential',
        sectors: ['Residential'],
        services: '',
        title: 'Gaywood House',
        value: '',
    },
    {
        client: 'Alfred Quincey',
        contract: '',
        date: '2017-04-27',
        description: '<p>Residential Development; conversion</p>',
        duration: '',
        images: ['quincey-charterhouse.jpg'],
        name: 'charterhouse',
        project: 'Charterhouse',
        sector: 'residential',
        sectors: ['Residential'],
        services: '',
        title: 'Charterhouse',
        value: '',
    },
    {
        client: 'Alfred Quincey',
        contract: '',
        date: '2017-04-27',
        description: '<p>Residential Development; new build</p>',
        duration: '',
        images: ['quincey-clapham.jpg'],
        name: 'clapham',
        project: 'Clapham Road',
        sector: 'residential',
        sectors: ['Residential'],
        services: 'Quantity Surveying / Cost Consultancy',
        title: 'Clapham Road',
        value: '',
    },
    {
        client: '',
        contract: '',
        date: '2017-01-25',
        description: '<p>A new terminal building constructed as part of ‘plan Abu Dhabi 2030’, an Emirates-wide scheme to ensure the growth of the region as a business and tourism centre.</p><p>Procom were appointed to undertake preliminary contractual, commercial and planning/programming investigations to facilitate and allow informed choices by the client on how they might proceed in securing maximum entitlement to cost and time recovery under the terms of the subcontract.</p>',
        duration: '',
        images: ['midfieldterminal.jpg'],
        name: 'midfield',
        project: 'Midfield Terminal Building',
        sector: 'aviation',
        sectors: ['Transportation'],
        services: '',
        title: 'Midfield Terminal Building',
        value: '',
    },
    {
        client: 'CASCADE',
        contract: '',
        date: '2017-01-25',
        description: '<p>Lower Thames Crossing have proposed the new cross twin-bored tunnel which will cross between Tilbury and Gravesham in Thames Estuary, linking Kent and Essex to increase road capacity through the creation of a ‘motorway to motorway’ connection. This is expected to be completed in 2026. Procom have been appointed to provide a project control service.</p>',
        duration: '',
        images: ['thames2.jpg', 'thames1.jpg'],
        name: 'thamescrossing',
        project: 'Lower Thames Crossing',
        sector: 'aviation',
        sectors: ['Civil', 'Transportation'],
        services: 'Project Control',
        title: 'Lower Thames Crossing',
        value: '£4-6bn',
    },
    {
        client: 'Kier',
        contract: '',
        date: '2017-01-25',
        description: '<p>Kier have been appointed as the head contractor for the redevelopment of Broadmoor Hospital containing 162 mental health beds and will transform the 150 year old hospital into the 21st century providing a modern, fit for purpose environment and high security. Procom are providing Quantity Surveying services.</p>',
        duration: '',
        images: ['broadmore1.jpg', 'broadmore2.jpg'],
        name: 'broadmoor',
        project: 'Broadmoor Hospital Redevelopment',
        sector: 'commercial',
        sectors: ['Civil'],
        services: 'Quantity Surveying',
        title: 'Broadmoor Hospital Redevelopment',
        value: '£115m',
    },
    {
        client: 'Ardmore',
        contract: '',
        date: '2017-01-25',
        description: '<p>Ardmore are carrying out the refurbishment and transformation of Ten Trinity Square a 93-year-old Grade II* listed building and the former Port of London Authority headquarters, this includes 41 outstanding residences, a luxury Four Seasons hotel, spa and fine restaurants. Procom have been Instructed to carry out the re-measure part of the £35m service package to define scope and quantum of change; specifically mechanical.</p>',
        duration: '',
        images: ['ardmoretrinity1.jpg', 'ardmoretrinity2.jpg'],
        name: 'trinitysquare',
        project: 'Ten Trinity Square, London',
        sector: 'residential',
        sectors: ['Residential', 'Retail'],
        services: 'Quantity Surveying',
        title: 'Ten Trinity Square',
        value: '£35m',
    },
    {
        client: 'Ardmore',
        contract: '',
        date: '2017-01-25',
        description: '<p>Ardmore are carrying out the development of Chelsea Water front which includes two glass luxury residential towers of 37 and 25 storeys, three riverside buildings arranged around landscaped gardens and the redevelopment of the historic Lots Road Power Station. The development will also include shops, restaurants and bars together with a residents\' health and fitness club.</p><p>Procom have been appointed to provide quantity surveying services in relation to change control.</p>',
        duration: '',
        images: ['ardmore1.jpg', 'ardmore2.jpg'],
        name: 'chelseawharf',
        project: 'Chelsea Waterfront',
        sector: 'residential',
        sectors: ['Residential'],
        services: 'Quantity Surveying / Cost Consultancy',
        title: 'Chelsea Waterfront',
        value: '£160m',
    },
    {
        client: 'Wycombe District Council',
        contract: '',
        date: '2016-10-30',
        description: '<p>Wycombe District Council will be carrying out the design and build of a Community Hall in Queen Elizabeth II playing field.  Procom have been instructed to act as Cost consultant and Employers agent undertaking full Pre and Post contract Services.</p>',
        duration: '',
        images: ['wycombeqe2-1.jpg', 'wycombeqe2-2.jpg'],
        name: 'wycombeqe2',
        project: 'QEII Playing Field',
        sector: 'education',
        sectors: ['Education'],
        services: 'Cost consultant and Employers agent',
        title: 'QEII Playing Field',
        value: '£150k',
    },
    {
        client: 'Quinn',
        contract: 'PPC 2000',
        date: '2016-10-30',
        description: '<p>Quinn are carrying out the design and build of the refurbishment of Willow Place, London. This includes 7no apartments and works to external windows, doors, roof, mechanical and electrical works and internal finishes. Procom have been appointed to provide quantity surveying & commercial management for both pre and post contract services.</p>',
        duration: '14 Months',
        images: ['quinnwillow.jpg'],
        name: 'willow',
        project: 'Willow Place',
        sector: 'residential',
        sectors: ['Residential'],
        services: 'Quantity surveying & commercial management for both pre and post contract services',
        title: 'Willow Place',
        value: '£2.29m',
    },
    {
        client: 'Quinn',
        contract: 'PPC 2000',
        date: '2016-10-30',
        description: '<p>Quinn are carrying out the design, onsite demolition of existing buildings and construction and completion of 24 self-contained flats at Ladbroke Drive. This includes associated access roads, footpaths, parking bays, drainage, external lighting and general external works and landscaping.  Procom have been appointed to provide quantity surveying & commercial management for both pre and post contract services.</p>',
        duration: '15 Months',
        images: ['quinnladbroke.jpg'],
        name: 'ladbroke',
        project: 'Ladbroke Drive',
        sector: 'residential',
        sectors: ['Residential'],
        services: 'Quantity Surveying / Cost Consultancy',
        title: 'Ladbroke Drive',
        value: '£3.7m',
    },
    {
        client: 'Midas',
        contract: '',
        date: '2016-10-30',
        description: '<p>Midas have nominated  Procom to  carry out the Bill Preparation for Tender on the relocation of Riverside Day Nursey from New Town Primary School to new modern facilities in Palmer Park, which has been specially refurbished to accommodate the nursey and allowing it to expand.</p>',
        duration: '',
        images: ['newtown.jpg'],
        name: 'newtown',
        project: 'New Town Primary School',
        sector: 'education',
        sectors: ['Education'],
        services: 'Bills of Quantities preparation',
        title: 'New Town Primary School',
        value: '',
    },
    {
        client: 'Dawnus',
        contract: '',
        date: '2016-10-30',
        description: '<p>The project is an Energy from Waste Plant under an engineer, procure, construct (EPC) contract. The built project will service the requirements of Gloucestershire’s 600,000+ residents. 92% of household waste will be diverted from landfill sites to Project Javelin where it is used as fuel to power a turbine which generates 17.4 MW/hour of energy; 14.5MW/hour of this energy is fed into the National Electricity Network.</p>',
        duration: '',
        images: ['dawnus.jpg'],
        name: 'javelin',
        project: 'Gloucestershire, Javelin Park EfW',
        sector: 'renewable',
        sectors: ['Renewable Energy'],
        services: 'Bills of Quants',
        title: 'Gloucestershire, Javelin Park EfW',
        value: '£3.7m',
    },
    {
        client: 'City West Homes',
        contract: '',
        date: '2016-10-30',
        description: '<p>Design and build of 5no new build apartments and 1no commercial unit, for City West Homes. Located in Westminster, London this included associated access roads, footpaths, parking bays, drainage, external lighting and general external works and landscaping.  Procom were been appointed to provide quantity surveying & commercial management for both pre and post contract services.</p>',
        duration: '',
        images: ['mozartestate.jpg'],
        name: 'mozart',
        project: 'Mozart Estate',
        sector: 'residential',
        sectors: ['Residential'],
        services: 'Quantity surveying & commercial management for both pre and post contract services',
        title: 'Mozart Estate',
        value: '£2m',
    },
    {
        client: 'Shanks Waste Management',
        contract: 'NEC Options A &amp; C',
        description: `<p>The AD Process:<br />
  A good balanced diet suits the AD process best</p>
  <ul>
      <li>Food waste is taken to the reception area where the material goes through a process which separates the waste from any packaging.</li>
      <li>Food waste is taken to the digester whilst packaging is sent off to be treated separately.</li>
      <li>Under the anaerobic conditions the food waste is broken down by micro-organisms which generates biogas and digestate.</li>
      <li>The digestate is then conditioned before being used to fertilise land.</li>
      <li>Biogas is cleaned and burned to produce renewable electricity, reducing the use of fossil fuels and minimise greenhouse gas emissions.</li>
  </ul>`,
        duration: '12 Months',
        images: ['westcott1.jpg', 'westcott2.jpg', 'westcott3.jpg', 'westcott4.jpg'],
        name: 'westcott',
        project: 'New Anaerobic Digestion Facility, Westcott Venture Park',
        sector: 'renewable',
        sectors: ['Civil', 'Renewable Energy'],
        services: 'Pre and Post Contract Cost Management, Project Management',
        title: 'Anaerobic Digestion Facility',
        value: '£20m',
    },
    {
        client: 'Mansell (Balfour Beatty Group)',
        contract: 'JCT 2005',
        description: '<p>The Contract involved the conversion of a former Old Orchard House in Poole into residential units over 11 floors with commercial space on the ground floor.  There were 115 apartments in total including very high specification penthouse units. The project was extremely challenging due to working with an existing concrete frame and extending the floor area by 100%.</p>',
        duration: '12 Months',
        images: ['orchard1.jpg', 'orchard2.jpg', 'orchard3.jpg'],
        name: 'orchard',
        project: 'Orchard Plaza, Poole, Dorset',
        sector: 'residential',
        sectors: ['Residential', 'Retail'],
        services: 'Post Contract Administration / Claims',
        title: 'Orchard Plaza',
        value: '£14m',
    },
    {
        client: 'Leadbitter (part of Bouygues Group)',
        contract: 'JCT 2005 D&amp;B',
        description: '<p>Procom were engaged to undertake a review of the final account, including extension of time entitlement together with related loss and expense.</p>',
        duration: '12 months',
        images: ['culverdale1.jpg', 'culverdale2.jpg'],
        name: 'culverdale',
        project: 'Culverdale',
        sector: 'residential',
        sectors: ['Residential'],
        services: 'EOT and Loss Expenses Claim',
        title: 'Culverdale',
        value: '£12,000,000',
    },
    {
        client: 'Warings Contractors (Bouygues Group)',
        contract: 'Building Schools for the Future',
        description: '<p>The contract involves the replacement of an existing residential school for children who have experienced severe difficulties in their past educational placements. The new build scheme involves 5,000m2 of new teaching space and residential accommodation with associated external works.</p>',
        duration: '12 Months',
        images: ['bowden1.jpg', 'bowden2.jpg', 'bowden3.jpg', 'bowden4.jpg'],
        name: 'bowden',
        project: 'Bowden House, Residential School',
        sector: 'education',
        sectors: ['Education'],
        services: 'Cost Management',
        title: 'Bowden House',
        value: '£10m',
    },
    {
        client: 'Salberg (Developer)',
        contract: 'JCT 2005 D&amp;B',
        description: `<p>Conversion of existing offices into 11 residential dwellings for private sale.</p>
  <p>Service Provided:</p>
  <ul>
      <li>Preparation of feasibility report and costings</li>
      <li>Develop cost plan from initial design</li>
      <li>Develop pre-tender estimate</li>
      <li>Compile tender documents and run tender</li>
      <li>Selection of Contractor</li>
      <li>Post contract administration</li>
  </ul>`,
        duration: '9 months',
        images: ['dwellings1.jpg'],
        name: 'salberg',
        project: 'Barratt House, Woking',
        sector: 'residential',
        sectors: ['Residential'],
        services: 'Cost Management',
        title: 'Barratt House',
        value: '£1.2m',
    },
    {
        client: 'Shanks Waste Management',
        contract: 'NEC Options A',
        description: '<p>Capacity: 90,000 tonnes per annum.</p>',
        duration: '14 Months',
        images: ['pontypool.jpg'],
        name: 'pontypool',
        project: 'New Anaerobic Digestion Facility, Pontypool / Usk Vale',
        sector: 'renewable',
        sectors: ['Civil', 'Renewable Energy', 'Industrial'],
        services: 'Pre and Post Contract Cost Management, Project Management',
        title: 'Anaerobic Digestion Facility',
        value: '£20m',
    },
    {
        client: 'Shanks Waste Management',
        contract: 'NEC Options A &amp; C',
        description: `<p>Project Description<br />
  Construction of new facilities, including:</p>
  <ul>
      <li>Household Waste Recycling Centres</li>
      <li>Transfer Loading Stations</li>
      <li>Autoclaves</li>
      <li>Materials Recycling Facility</li>
      <li>Composting Facility</li>
      <li>Education Centre</li>
      <li>Refuse Collection Vehicle Facility</li>
      <li>A.D. Facility</li>
  </ul>
  <p>Shanks uses the latest technologies:</p>
  <ul>
      <li>Mechanical biological treatment plants that treat waste and use composting or anaerobic digestion to turn it into useful products.</li>
      <li>Materials recovery facilities that prepare recycled materials for re-use.</li>
  </ul>`,
        duration: '18 Months',
        images: ['wakefield.jpg'],
        name: 'wakefield',
        project: 'New Waste Management Facility, Wakefield',
        sector: 'renewable',
        sectors: ['Civil', 'Renewable Energy', 'Industrial'],
        services: 'Pre and Post Contract Cost Management',
        title: 'Waste Management Facility',
        value: '£140m',
    },
    {
        client: 'Shanks Waste Management',
        contract: 'Bespoke (similar to NEC Option C)',
        description: `<p>Project Description</p>
  <ul>
      <li>Shanks provide management of 14 HWRCs across Cumbria</li>
      <li>Provision of two new Resource Parks centred upon treatment of residual waste using proven Ecodeco Mechanical Biological Treatment (<span class="caps">MBT</span>) technology, the Intelligent Transfer Station</li>
      <li>Each Resource Park will feature at 75,000 tonnes per annum <span class="caps">ITS</span>, a recyclate storage facility, dual weighbridges, a housed windrow stabilisation facility and an administration block</li>
      <li>The North Cumbria Resource Park is to be built at Hespin Wood near Carlisle and will serve the Northern half of the county</li>
      <li>The South Cumbria Resource Park is to be built at Barrow and will serve the Southern half of the county</li>
      <li>The aim of the Resource Parks is to maximise the recovery of waste by: Producing a solid recovered fuel, intended for use in local industry Recovering a range of recyclable materials.</li>
  </ul>`,
        duration: '20 Months',
        images: ['cumbria1.jpg', 'cumbria2.jpg'],
        name: 'cumbria',
        project: 'New Refuse Derived Fuel Production Facilities, Cumbria',
        sector: 'renewable',
        sectors: ['Civil', 'Renewable Energy', 'Industrial'],
        services: 'Final Account Audit',
        title: 'Fuel Production Facilities',
        value: '£21m',
    },
    {
        client: 'Shanks Waste Management',
        contract: 'NEC Option A',
        description: `<p>Main Contractor: Mansell &amp; Birse<br />
  Project Description<br />
  Construction of new facilities, including:</p>
  <ul>
      <li>Household Waste Recycling Centres</li>
      <li>Transfer Loading Stations</li>
      <li>Autoclaves</li>
      <li>Materials Recycling Facility</li>
      <li>Composting Facility</li>
      <li>Education Centre</li>
      <li>Refuse Collection Vehicle Facility</li>
      <li>A.D. Facility</li>
  </ul>`,
        duration: '18 Months',
        images: ['barnsley.jpg'],
        name: 'barnsley',
        project: 'Intelligent Transfer Station, Barnsley, Doncaster & Rotherham',
        sector: 'renewable',
        sectors: ['Civil', 'Renewable Energy', 'Industrial'],
        services: 'Pre &amp; Post Contract Cost Management',
        title: 'Intelligent Transfer Station',
        value: '£120m',
    },
    {
        client: 'Buro Happold',
        contract: 'Bespoke',
        description: `<p>King Abdullah Economic City (<span class="caps">KAEC</span>) was founded by King Abdullah in 2006. It&#8217;s a unique partnership between the Kingdom of Saudi Arabia (<span class="caps">KSA</span>), its people and investors, creating a new world city that will benefit businesses, citizens and the entire Kingdom of Saudi Arabia.<br />
  <span class="caps">KAEC</span> is the perfect location to connect East and West. From its state-of-the-art deepwater port on the Red Sea shore, <span class="caps">KAEC</span> can handle the world&#8217;s largest ships. And with new road and rail networks, <span class="caps">KAEC</span> is just an hour from Jeddah, Mecca, and Medina.</p>`,
        duration: 'Ongoing',
        images: ['King-Abdullah-Economic-City.jpg'],
        name: 'kaec',
        project: 'King Abdullah Economic City',
        sector: 'commercial',
        sectors: ['Civil', 'Offices and Commercial', 'Industrial', 'Transportation'],
        services: 'Pre Contract',
        title: 'King Abdullah Economic City',
        value: 'US$100Bn',
    },
    {
        client: 'TEG Environmental',
        contract: 'NEC3 Option A',
        description: '<p>The project involves undertaking remedial works to an in-vessel composting facility which has suffered from severe corrosion to the building since completion.  The external cladding, <span class="caps">MEP</span> services and structural steelwork are all to be replaced with higher specified materials to prevent from corrosion .</p>',
        duration: 'Ongoing',
        images: ['waithlands1.jpg', 'waithlands2.jpg'],
        name: 'waithlands',
        project: 'Waithlands Remedial works',
        sector: 'renewable',
        sectors: ['Civil', 'Renewable Energy'],
        services: 'Project Management &amp; Cost Management',
        title: 'Waithlands Remedial Works',
        value: '£5m',
    },
    {
        client: 'Brent Housing Partnership',
        contract: 'NEC3 Option C',
        description: '<p>New build and refurbishment for 200 units, Planned works, OT Adaptions &amp; voids.</p>',
        duration: '12 Months',
        images: ['brent5.jpg', 'brent6.jpg'],
        name: 'brent',
        project: 'Brent Housing Dwellings',
        sector: 'residential',
        sectors: ['Residential'],
        services: 'Pre and Post Contract Quantity Surveying',
        title: 'Brent Housing',
        value: '£25m',
    },
    {
        client: 'Yorkshire Water',
        contract: 'NEC Option 3',
        description: '<p>Work was to improve the bathing water quality for Scarborough beaches.  By making these improvements secures the highest standards of bathing water quality for the town &#8211; helping make both Scarborough North Bay and South Bay eligible for Blue Flags in the future.</p>',
        duration: '24 Months',
        images: ['wheatcroft1.jpg', 'wheatcroft2.jpg'],
        name: 'wheatcroft',
        project: 'Wheatcroft Sewage Pumping Station',
        sector: 'renewable',
        sectors: ['Civil'],
        services: 'Post Contract Quantity Surveying',
        title: 'Wheatcroft Pumping Station',
        value: '£50m',
    },
    {
        client: 'Leadbitter (Part of Bouygues UK) ',
        contract: 'JCT 2005 D&amp;B',
        description: `<p>Construction of a new retail and leisure development in the centre of Trowbridge in Wiltshire, on behalf of Legal &amp; General Property.<br />
  St Stephen&#8217;s Place Leisure Park will include an Odeon multiplex cinema and an 80-bed Premier Inn hotel, as well as a range of restaurants, cafes and bars, 111 car parking spaces and new landscaped areas of riverside open space.</p>`,
        duration: '12 Months',
        images: ['trowbridge1.jpg', 'trowbridge2.jpg'],
        name: 'trowbridge',
        project: 'Mixed Use Development, Trowbridge',
        sector: 'commercial',
        sectors: ['Offices and Commercial', 'Retail', 'Hospitality', 'Sports and Recreational'],
        services: 'Post Contract QS Services',
        title: 'Mixed Use Development',
        value: '£12m',
    },
    {
        client: 'Feltham Construction',
        contract: 'JCT 2005 D&amp;B',
        description: `<p>Construction of a new Modern Languages, Economics, Politics, Divinity and Lecture Theatre, teaching block and accommodation.<br />
  Procom were engaged to compile an Extension of Time Claim for the Contractor and associated Loss and Expense.</p>`,
        duration: 'Ongoing',
        images: ['eton.jpg'],
        name: 'eton',
        project: 'Eton College',
        sector: 'education',
        sectors: ['Education'],
        services: 'EOT &amp; Loss &amp; Expenses Claim',
        title: 'Eton College',
        value: '£12m',
    },
    {
        client: 'BAM',
        contract: 'NEC3 Option C',
        description: '<p>Procom were engaged to defend claims received from the supply chain and to compile claims against the client to ensure full entitlement is recovered.</p>',
        duration: 'Ongoing',
        images: ['crossrail1.jpg', 'crossrail2.jpg'],
        name: 'crossrail',
        project: 'Crossrail Project, Essex',
        sector: 'aviation',
        sectors: ['Transportation'],
        services: 'Claims Support',
        title: 'Crossrail',
        value: '£50m',
    },
    {
        client: 'Leadbitter (Part of Bouygues UK)',
        contract: 'JCT 2005 D&amp;B',
        description: `<p>Extensive refurbishment and extension of existing building into grade A offices.<br />
  Procom were engaged to review the project on behalf of the Board of Bouygues UK and to implement new reporting procedures.</p>`,
        duration: '12 Months',
        images: ['onevictoria.jpg', 'onevictoria2.jpg', 'onevictoria3.jpg'],
        name: 'onevictoria',
        project: 'One Victoria, Bristol',
        sector: 'commercial',
        sectors: ['Offices and Commercial'],
        services: 'Commercial Management',
        title: 'One Victoria',
        value: '£6m',
    },
    {
        client: 'RNAS Yeovilton',
        contract: 'NEC3 Option C',
        description: `<p>New build storage and vehicle servicing facilities.<br />
  Procom were engaged to provide commercial management services to ensure the contractors full entitlement was recovered.</p>`,
        duration: '10 Months',
        images: ['yeovilton.jpg', 'yeovilton2.jpg'],
        name: 'yeovilton',
        project: 'Vehicle Servicing / Storage Facilities',
        sector: 'aviation',
        sectors: ['Defence'],
        services: 'Post Contract Commercial Management',
        title: 'RNAS Yeovilton',
        value: '£6m',
    },
    {
        client: 'Sutton House Partnership',
        contract: 'NEC3 Option C',
        description: '<p>Extensive refurbishment of the mechanical &amp; electrical systems including installation of new sprinkler systems into 12 storey block of flats.</p>',
        duration: 'Ongoing',
        images: ['sutton1.jpg', 'sutton2.jpg'],
        name: 'sutton',
        project: 'Sutton House',
        sector: 'residential',
        sectors: ['Residential'],
        services: 'Cost Management / Quantity Surveying and Forensic Auditing',
        title: 'Sutton House',
        value: '£4m',
    },
    {
        client: 'EC Harris',
        contract: 'NEC3 Option A &amp; C',
        description: `<p>Refurbishment and extension to existing terminal building and new build new piers.<br />
  Procom were engaged to work within the EC Harris team to provide cost planning and cost management services for the business case.</p>`,
        duration: '12 Months',
        images: ['cityairport1.jpg', 'cityairport2.jpg', 'cityairport3.jpg'],
        name: 'cityairport',
        project: 'London City Airport',
        sector: 'aviation',
        sectors: ['Aviation'],
        services: 'Cost Planning / Cost Management',
        title: 'London City Airport',
        value: '£200m',
    },
    {
        client: 'EC Harris',
        contract: 'NEC Option 3',
        description: '<p>Survey and develop scope of work for upgrade of existing IT rooms across Heathrow airport. Prepare business case, procure works and manage to completion, including final account.</p>',
        duration: '12 Months',
        images: ['heathrow1.jpg', 'heathrow2.jpg', 'heathrow3.jpg'],
        name: 'heathrow',
        project: 'Computer Room Upgrade',
        sector: 'aviation',
        sectors: ['Aviation', 'Offices and Commercial', 'Retail'],
        services: 'Cost Planning / QS',
        title: 'Heathrow Computer Room',
        value: '£80m',
    },
    {
        client: 'Tamar Energy',
        contract: 'Various',
        description: `<p>Tamar Energy plan to build 40 A.D. plants around the UK by 2018.<br />
  Procom have been selected as Tamar Energy’s framework Cost Consultant.</p>`,
        duration: 'Ongoing',
        images: ['tamar.jpg', 'tamar2.jpg', 'tamar3.jpg'],
        name: 'tamar',
        project: 'Various',
        sector: 'renewable',
        sectors: ['Infrastructure'],
        services: 'Cost Management',
        title: 'Tamar Energy',
        value: '£400m',
    },
    {
        client: 'Transport for London',
        contract: 'Various',
        description: `<p>Procom have been selected to provide Estimating, Cost Planning and QS services to TfL, <span class="caps">SWIP</span> projects.<br />
  First services expected Feb / March 2014.</p>`,
        duration: 'Ongoing',
        images: ['swip1.jpg', 'swip2.jpg', 'swip3.jpg', 'swip4.jpg'],
        name: 'swip',
        project: 'Various SWIP team projects',
        sector: 'aviation',
        sectors: ['Transportation'],
        services: 'Cost Planning / QS',
        title: 'Transport for London',
        value: '£50m per annum',
    },
    {
        client: 'Viridor Waste Management',
        contract: 'Various',
        description: '<p>Procom have been selected to support Viridor’s small projects framework, which spends £50m per annum on development.</p>',
        duration: 'Ongoing',
        images: ['viridor1.jpg', 'viridor2.jpg'],
        name: 'viridor',
        project: 'Various',
        sector: 'renewable',
        sectors: ['Civil'],
        services: 'QS / PM',
        title: 'Viridor Waste Management',
        value: '£1.5Bn',
    },
    {
        client: 'Bouygues UK',
        contract: 'JCT 2005 Build only with Contractor Design Portions',
        date: '2014-04-19',
        description: '<p>Procom were engaged to head the existing commercial team dealing with external client account and the supply chain accounts.</p>',
        duration: '3-9 months',
        images: ['graftonstreet.jpg'],
        name: 'grafton',
        project: '5 Grafton Street, London',
        sector: 'residential',
        sectors: ['Residential'],
        services: 'Post contract commercial management / Quantity surveying.',
        title: 'Grafton Street',
        value: '£9m',
    },
    {
        client: 'Bouygues UK',
        contract: 'JCT 2005 Build only with Contractor Design portions.',
        date: '2014-04-19',
        description: '<p>Refurbishment of Grade 2 listed building, forming new basement level; bespoke high end interior design and fit out.</p>',
        duration: '3-9 months',
        images: ['palacegreen.jpg'],
        name: 'palacegreen',
        project: '9 Palace Green, Kensington Palace Gardens, London',
        sector: 'residential',
        sectors: ['Residential'],
        services: 'Post contract Quantity Surveying',
        title: 'Palace Green',
        value: '£18m',
    },
    {
        client: 'Balfour Beatty',
        contract: 'NEC3 Option C',
        date: '2014-10-06',
        description: '<p>Procom were engaged to do the preparation of final accounts for highways maintenance works.</p>',
        duration: '3 months',
        images: ['highways.jpg'],
        name: 'highways',
        project: 'Highways Agency Road Maintenance Works',
        sector: 'aviation',
        sectors: ['Civil'],
        services: 'Preparation of main contract Final Account and supply chain C accounts',
        title: 'Highways Agency',
        value: '£20m',
    },
    {
        client: 'City of London Corporation',
        contract: '',
        date: '2014-10-06',
        description: `<p>Refurbishment of Smithfields Poultry Market in London which is a Listed building originally built in 1868.  However the market was destroyed by fire in 1958 which then had to be re-built.</p>
  <p>The refurbishment works include replacement of the existing copper roof covering to the parabolic roof and surrounding flat roofing and rooflights and extensive upgrade and replacement of the mechanical and electrical systems.</p>`,
        duration: '2 months',
        images: ['smithfield1.jpg', 'smithfield2.jpg', 'smithfield3.jpg'],
        name: 'smithfield',
        project: 'Smithfield Poultry Market, London',
        sector: 'commercial',
        sectors: ['Civil', 'Retail', 'Offices and Commercial'],
        services: 'Cost Planning/Cost Management',
        title: 'Smithfield Poultry Market',
        value: '£9m',
    },
    {
        client: 'City of London Corporation',
        contract: '',
        date: '2014-10-06',
        description: `<p>Procom has been engaged to provide cost planning and cost management services in relation to the re-development of the City of London owned site located at 140 / 150 London Wall.</p>
  <p>The existing site houses the Museum of London and circa. 119,000 sq.ft. of commercial office space. The City is seeking to re-develop the site to create a new Museum of London / cultural hub as well as increase the density of commercial office space. Current master planning proposals indicate a new museum of up to 270,000 sq.ft. and commercial office space of up to 651,000 sq.ft.</p>
  <p>Procom has completed the stage 1 budget, and will be working with the appointed architect in the coming months to work up more detailed proposals.</p>`,
        duration: '1 month',
        images: ['bastion1.jpg', 'bastion2.jpg'],
        name: 'bastion',
        project: 'Bastion House / Museum of London',
        sector: 'commercial',
        sectors: ['Civil', 'Offices and Commercial', 'Sports and Recreational'],
        services: 'Cost Management',
        title: 'Bastion House',
        value: 'c. £300m',
    },
    {
        client: 'Keepmoat',
        contract: 'NEC3 Option A',
        date: '2014-10-09',
        description: '<p>Procom were appointed to measure of existing social housing blocks and preparation of bills of quantities under an NEC3 Option A contract.</p>',
        duration: '',
        images: ['thamlets1.jpg', 'thamlets2.jpg'],
        name: 'towerhamlets',
        project: 'Tower Hamlets',
        sector: 'residential',
        sectors: ['Residential'],
        services: 'Measure of existing buildings and BoQ Preparation',
        title: 'Tower Hamlets',
        value: 'c. £20m',
    },
    {
        client: 'Downing LLP',
        contract: 'NEC3 Option A',
        date: '2014-10-09',
        description: `<p>Grange Farm is a new Anaerobic Digestion facility near Spridlington, Lincolnshire.</p>
  <p>This is part of a funded project, before the funds will be released Procom has been asked to act in the capacity as a Monitoring Surveyor.</p>`,
        duration: '2014',
        images: ['grange1.jpg', 'grange2.jpg'],
        name: 'grange',
        project: 'Grange Farm Anaerobic Digestion Facility',
        sector: 'renewable',
        sectors: ['Civil', 'Renewable Energy'],
        services: 'Monitoring Surveying Role',
        title: 'Grange Farm',
        value: 'c. £8m',
    },
    {
        client: 'Honeybun Construction Limited',
        contract: 'JCT Contract',
        date: '2014-10-09',
        description: '<p>Honeybun have been appointed to undertake and complete the design and construction of 5 new houses, apartments and all associated works at a new development on Newington Green Road, Islington, London, Procom have been appointed to review and evaluate HCL&#8217;s position in relation to variations, direct loss and expense and EoT.</p>',
        duration: '',
        images: ['newington1.jpg', 'newington2.jpg'],
        name: 'newington',
        project: 'Newington Green Road',
        sector: 'residential',
        sectors: ['Residential'],
        services: 'Commercial Services/ Quantity Surveying',
        title: 'Newington Green Road',
        value: 'c. £2m',
    },
    {
        client: 'Sutton Housing Partnership',
        contract: 'NEC3 Option: C contract',
        date: '2014-10-09',
        description: `<p>Sutton is engaged in an annual programme of improvement works to existing housing stock.</p>
  <p>Procom have been appointed to carry out Pre & Post Contract Quantity Surveying and Cost Management.</p>`,
        duration: '',
        images: ['box1.jpg', 'box2.jpg', 'box3.jpg'],
        name: 'boxbathrooms',
        project: 'Box Bathrooms / OT Adaptations',
        sector: 'residential',
        sectors: ['Residential'],
        services: 'Pre and post-contract quantity surveying / cost management',
        title: 'Box Bathrooms',
        value: '',
    },
    {
        client: 'Downing – (Avocet Renewables)',
        contract: '',
        date: '2014-10-09',
        description: `<p>Downing are part funding the construction of a new Anaerobic Digestion facility at Coston Hall Farm, Norfolk.</p>
  <p>This is part of a funded project, before the funds will be released Procom has been asked to act in the capacity as a Monitoring Surveyor and to report on matters relating to the project.</p>`,
        duration: '',
        images: ['coston1.jpg', 'coston2.jpg'],
        name: 'coston',
        project: 'Coston Hall Farm, Norfolk',
        sector: 'renewable',
        sectors: ['Civil', 'Renewable Energy'],
        services: 'Monitoring Surveying',
        title: 'Coston Hall Farm',
        value: '',
    },
    {
        client: 'M&amp;W Group',
        contract: '',
        date: '2014-10-09',
        description: `<p>Development of a new Cancer research centre in Manchester.</p>
  <p>The project is being funded by the University of Manchester, Cancer Research UK and the Christie <span class="caps">NHS</span> Foundation Trust.</p>
  <p>Procom have been appointed to support and oversee the commercial administration of the project.</p>`,
        duration: '',
        images: ['cancer-research-centre.jpg'],
        name: 'manchester',
        project: 'Manchester Cancer Research Centre',
        sector: 'education',
        sectors: ['Civil', 'Science and Pharmaceuticals', 'Healthcare'],
        services: 'Post-Contract commercial Management',
        title: 'Cancer Research Centre',
        value: 'c. £18m',
    },
    {
        client: 'Barnet Homes',
        contract: '',
        date: '2015-12-07',
        description: '<p>Procom are to undertake a re-procurement of the OT Adaptations contract.  Procom will also be providing the post contract cost management service on behalf of Barnet Homes.</p>',
        duration: '6 Months',
        images: ['barnethomes.jpg'],
        name: 'barnet-ot',
        project: 'OT Adaptation Re-procurement',
        sector: 'residential',
        sectors: ['Residential'],
        services: 'Post contract',
        title: 'OT Adaptation Re-procurement',
        value: 'c. £1m',
    },
    {
        client: 'City West Homes',
        contract: 'NEC3: Option A contract',
        date: '2015-12-07',
        description: '<p>City West Homes is currently undertaking refurbishment works to 3 nr. Existing residential properties. The works comprise roof renewals / repairs, brickwork repairs, underpinning, external decoration, window replacement and electrical works.</p>',
        duration: '',
        images: ['street.jpg'],
        name: 'citywest-street',
        project: 'Street Properties',
        sector: 'residential',
        sectors: ['Residential'],
        services: 'Act as Project Manager',
        title: 'Street Properties',
        value: '',
    },
    {
        client: 'City West Homes',
        contract: 'NEC3: Option A contract',
        date: '2015-12-07',
        description: `<p>City west homes is currently undertaking refurbishment works to 7 nr. Existing residential blocks.<br />
  The works comprise of roof renewals / repairs, brickwork repairs and window replacements.</p>
  <p>During to increasing demand and strain on internal human resources, City West Homes is seeking to procure an external consultant to manage and administer Wessex Gardens contract on their behalf.</p>`,
        duration: '4 months',
        images: ['wessex.jpg'],
        name: 'citywest-wessex',
        project: 'Wessex Estate Gardens',
        sector: 'residential',
        sectors: ['Residential'],
        services: 'Act as Project Manager',
        title: 'Wessex Estate Gardens',
        value: '',
    },
    {
        client: 'Cranbrook Renewables Ltd',
        contract: '',
        date: '2015-12-07',
        description: `<p>Edgeworthy is a new Anaerobic Digestion facility.</p>
  <p>This is part of a funded project, before the funds will be released Procom has been asked to act in the capacity as a Monitoring Surveyor.</p>`,
        duration: '9 Months',
        images: ['downing-edgeworthy.jpg'],
        name: 'edgeworthy',
        project: 'Edgeworthy AD Facility',
        sector: 'renewable',
        sectors: ['Utilities', 'Renewable Energy'],
        services: 'Monitoring Surveyor’s Role',
        title: 'Edgeworthy AD Facility',
        value: 'c. £2m',
    },
    {
        client: 'Downing LLP',
        contract: '',
        date: '2015-12-07',
        description: `<p>Enfield  is a new Anaerobic Digestion facility.</p>
  <p>This is part of a funded project, before the funds will be released Procom has been asked to act in the capacity as a Monitoring Surveyor.</p>`,
        duration: '9 Months',
        images: ['downing-enfield.jpg'],
        name: 'enfield',
        project: 'Enfield AD Facility',
        sector: 'renewable',
        sectors: ['Utilities', 'Renewable Energy'],
        services: 'Monitoring Surveyor’s Role',
        title: 'Enfield AD Facility',
        value: 'c. £12m',
    },
    {
        client: 'Sustainable Energy Generation Ltd',
        contract: '',
        date: '2015-12-07',
        description: `<p>Frogmary is a new Anaerobic Digestion facility in Somerset.</p>
  <p>This is part of a funded project, before the funds will be released Procom has been asked to act in the capacity as a Monitoring Surveyor.</p>`,
        duration: '12 Months',
        images: ['downing-frogmary.jpg'],
        name: 'frogmary',
        project: 'Frogmary AD Facility',
        sector: 'renewable',
        sectors: ['Utilities', 'Renewable Energy'],
        services: 'Monitoring Surveyor’s Role',
        title: 'Frogmary AD Facility',
        value: 'c. £10.3m',
    },
    {
        client: 'Condate Biogas Ltd',
        contract: '',
        date: '2015-12-07',
        description: `<p>Great Hele is a new Anaerobic Digestion facility.</p>
  <p>This is part of a funded project, before the funds will be released Procom has been asked to act in the capacity as a Monitoring Surveyor.</p>`,
        duration: '9 Months',
        images: ['downing-greathele.jpg'],
        name: 'greathele',
        project: 'Great Hele AD Facility',
        sector: 'renewable',
        sectors: ['Utilities', 'Renewable Energy'],
        services: 'Monitoring Surveyor’s Role',
        title: 'Great Hele AD Facility',
        value: 'c. £8m',
    },
    {
        client: 'Southwestern Sustainability Ltd',
        contract: '',
        date: '2015-12-07',
        description: `<p>Hartnoll is a new Anaerobic Digestion facility.</p>
  <p>This is part of a funded project, before the funds will be released Procom has been asked to act in the capacity as a Monitoring Surveyor.</p>`,
        duration: '9 Months',
        images: ['Downing-Hartnoll.jpg', 'downing-hartnoll2.jpg'],
        name: 'hartnoll',
        project: 'Hartnoll AD Facility',
        sector: 'renewable',
        sectors: ['Utilities', 'Renewable Energy'],
        services: 'Monitoring Surveyor’s Role',
        title: 'Hartnoll AD Facility',
        value: 'c. £4m',
    },
    {
        client: 'Redstow Renewables Ltd  ',
        contract: '',
        date: '2015-12-07',
        description: `<p>Swaffham is a new Anaerobic Digestion facility in Norfolk.</p>
  <p>This is part of a funded project, before the funds will be released Procom has been asked to act in the capacity as a Monitoring Surveyor.</p>`,
        duration: '9 Months',
        images: ['downing-swaffham.jpg'],
        name: 'swaffham',
        project: 'Swaffham AD Facility',
        sector: 'renewable',
        sectors: ['Utilities', 'Renewable Energy'],
        services: 'Monitoring Surveyor’s Role',
        title: 'Swaffham AD Facility',
        value: 'c. £14m',
    },
    {
        client: 'Green Puffin Ltd',
        contract: '',
        date: '2015-12-07',
        description: `<p>Rusheywood is a new Anaerobic Digestion facility.</p>
  <p>This is part of a funded project, before the funds will be released Procom has been asked to act in the capacity as a Monitoring Surveyor.</p>`,
        duration: '9 Months',
        images: ['Downing-Rushywood.jpg', 'downing-rusheywood.jpg'],
        name: 'rusheywood',
        project: 'Rusheywood AD Facility',
        sector: 'renewable',
        sectors: ['Utilities', 'Renewable Energy'],
        services: 'Monitoring Surveyor’s Role',
        title: 'Rusheywood AD Facility',
        value: 'c. £3m',
    },
    {
        client: 'Ductclean (UK) Ltd',
        contract: '',
        date: '2015-12-07',
        description: '<p>Procom were commissioned to assist with the tender for the Refurbishment of Guildford Cathedral, involving Asbestos Removal works, Upgrade of Electrical / lighting systems, Removal and upgrade of Acoustic Plastering and Decorations.  Also installation of new external hard landscaping and Illuminated Balustrading.</p>',
        duration: '',
        images: ['guildford-cathedral.jpg'],
        name: 'guildford',
        project: 'Guildford Cathedral',
        sector: 'leisure',
        sectors: ['Religious'],
        services: 'Procurement / Bid Preparation',
        title: 'Guildford Cathedral',
        value: 'c. £6m',
    },
    {
        client: 'Shanks',
        contract: '',
        date: '2015-12-07',
        description: `<p>Shanks part own and until a recent fire, operate an existing mechanical, biological treatment facility known as Frog Island. This facility is one of two <span class="caps">MBT</span> facilities forming part of the East London Waste Authority (<span class="caps">ELWA</span>) <span class="caps">PFI</span> project.</p>
  <p>Shanks wishes to procure an external consultant with expertise in these types of facilities and insurance claims, to assist with the procurement, cost management and post contract administration duties in connection with the reinstatement / re-build works.</p>`,
        duration: '2014 - 2016',
        images: ['shanks-elwa1.jpg', 'shanks-elwa2.jpg'],
        name: 'frog-island',
        project: 'Frog Island, ELWA',
        sector: 'renewable',
        sectors: ['Civil', 'Renewable Energy', 'Industrial'],
        services: 'Procurement & Commercial Services',
        title: 'Frog Island, ELWA',
        value: 'c. £17m',
    },
    {
        client: 'Vinci Construction UK',
        contract: 'NEC3 Option A',
        date: '2016-03-01',
        description: '<p>Bloc Hotel is a Part conversion / part new build a 245 room hotel with <span class="caps">POS</span> bathrooms, including monsoon-drench shower wet rooms: a business floor and presidential suite.  Procom have been instructed to carry out Quantity Surveying and Cost Management for this project.</p>',
        duration: '60 Weeks',
        images: ['Bloc-Hotel.jpg', 'bloc1.jpg', 'bloc2.jpg'],
        name: 'bloc',
        project: 'Bloc Hotel, Gatwick Airport',
        sector: 'aviation',
        sectors: ['Aviation', 'Hospitality'],
        services: 'Quantity Surveying / Cost Management',
        title: 'Bloc Hotel, Gatwick Airport',
        value: '£10m',
    },
    {
        client: 'City Of London',
        contract: 'NEC Contract',
        date: '2016-03-01',
        description: '<p>City of London are carrying out refurbishment and an extension on a grade II listed building , Bridgemaster’s House, tower bridge road. The building will consist of a restaurant, Office, Plant space. Procom have been appointed to carry out post contract Quantity Surveying.</p>',
        duration: '11 Months',
        images: ['Bridgemasters-House1.jpg', 'Bridgemasters-House2.jpg', 'Bridgemasters-House3.jpg'],
        name: 'bridgemasters',
        project: 'Bridgemasters House',
        sector: 'commercial',
        sectors: ['Retail', 'Offices and Commercial'],
        services: 'Post Contract Quantity Surveying',
        title: 'Bridgemasters House',
        value: '£5.3m',
    },
    {
        client: 'Waltham Forest & Westminster BSF',
        contract: 'NEC3 Option A &amp; C',
        date: '2016-03-01',
        description: '<p>Waltham Forest & Westminster <span class="caps">BSF</span> are carrying out various new builds, refurbishments and renovation projects under the <span class="caps">BFS</span> programme, including Kelmscott School Walthamstow Girls, Pimlico School, St. Marylebone School and Westminster School. Procom have been instructed to provide the quantity surveying and cost management for this project.</p>',
        duration: '12 Months',
        images: ['schools.jpg', 'schools2.jpg'],
        name: 'bsf',
        project: 'Building Schools for the Future',
        sector: 'education',
        sectors: ['Education'],
        services: 'Quantity Surveying / Cost Management',
        title: 'Building Schools for the Future',
        value: '£2.5m - £40m',
    },
    {
        client: 'CSJV',
        contract: 'NEC3 Option C',
        date: '2016-03-01',
        description: '<p>A new venture with Skanska and Costain have been awarded the C405 Crossrail Paddington Station contract by Crossrail. This is the first station to take part in 14.8bn Crossrail programme and a key interchange with the existing Network Rail services at Paddington Mainline station and with London Underground. Procom have been appointed to provide commercial resource, seconded into the <span class="caps">CSJV</span> team.</p>',
        duration: '6 Months',
        images: ['CrossRail-Paddington.jpg', 'paddington1.jpg'],
        name: 'crossrailpaddington',
        project: 'Crossrail Paddington',
        sector: 'aviation',
        sectors: ['Civil', 'Transportation'],
        services: 'Commercial Resource',
        title: 'Crossrail Paddington',
        value: '£400m',
    },
    {
        client: 'Vinci Construction UK',
        contract: 'NEC3 Option A',
        date: '2016-03-01',
        description: '<p>Vinci Construction UK were awarded a contract to refurbish the Heathrow Terminal 3 baggage and immigration hall. Vinci appointed Procom to support on Tender and pre-contract phases.</p>',
        duration: '60 Weeks',
        images: ['heathrowt31.jpg', 'heathrowt32.jpg'],
        name: 'heathrowt3',
        project: 'Heathrow T3 Immigration Hall Refurbishment',
        sector: 'aviation',
        sectors: ['Aviation', 'Infrastructure'],
        services: 'Support on Tender and pre-contract phases',
        title: 'Heathrow T3 Immigration Hall',
        value: '£8.3m',
    },
    {
        client: 'Bouygues UK',
        contract: 'JCT Design and build',
        date: '2016-03-01',
        description: '<p>Procom have been engaged to provide post contract quantity surveying for Bouygues UK for a new development of 517 study bedrooms, 59 flats and 73 Studio rooms built up of 3 buildings up to 9 stories high which will also hold cafes and shops for Portsmouth Student Accommodation which was previously <span class="caps">NAAFI</span> (Navy Army and Air forces Institution)</p>',
        duration: '60 Weeks',
        images: ['plymouth.jpg'],
        name: 'plymouthhoe',
        project: 'Plymouth Hoe, Student Accommodation',
        sector: 'education',
        sectors: ['Education'],
        services: 'Post Contract Quantity Surveying',
        title: 'Plymouth Hoe, Student Accommodation',
        value: '£20m',
    },
    {
        client: 'Quinn',
        contract: 'PPC 2000',
        date: '2016-03-01',
        description: '<p>Quinn are carrying out a new build consisting of 25no apartments with 1no guest suite and communal kitchen / dining facilities. This also includes a full landscaping scheme, parking areas and construction of a detached summer house. The scheme is being funded by Hanover Housing Association who are acting on behalf of a combination of private and social occupants. Procom have been appointed to provide quantity surveying &amp; commercial management for both pre and post contract services.</p>',
        duration: '93 Weeks',
        images: ['quinn1.jpg', 'quinn2.jpg'],
        name: 'unionst',
        project: 'Union Street',
        sector: 'residential',
        sectors: ['Residential'],
        services: 'Quantity Surveying &amp; Commercial management, Pre and Post Contract services',
        title: 'Union Street',
        value: '£4.4m',
    },
    {
        client: 'Warnings Contractors Ltd',
        contract: 'JCT Design and Build',
        date: '2016-03-01',
        description: '<p>This project consisted of a refurbishment of two separate units. The Student Union Building and Student Services Building. Procom have been instructed for the service of Pre and Post Contract Cost Management.</p>',
        duration: '26 Weeks',
        images: ['readinguni1.jpg', 'readinguni2.jpg'],
        name: 'readinguni',
        project: 'Reading University',
        sector: 'education',
        sectors: ['Education'],
        services: 'Pre and Post Contract Cost Management',
        title: 'Reading University',
        value: '£649k',
    },
    {
        client: 'Wates',
        contract: 'TBC',
        date: '2016-03-01',
        description: '<p>Nescot College’s land on Reigate Road is set to have  91 homes on the rest of the 14 acre site along with a care home with 150 rooms. The plans are to sell the land for the housing development to fund renovations to its campus. Procom have been appointed to take off and bill the quantities production service.</p>',
        duration: 'TBC',
        images: ['tgg1.jpg', 'tgg2.jpg'],
        name: 'tggepsom',
        project: 'TGG  Epsom',
        sector: 'residential',
        sectors: ['Residential', 'Healthcare'],
        services: 'Take off and Bill of Quantities production service',
        title: 'TGG  Epsom',
        value: 'c. £40m',
        hide: true
    },
    {
        client: 'Warings Ltd',
        contract: 'JCT Design and Build',
        date: '2016-03-01',
        description: '<p>Procom were commissioned to provide pre and post contract quantity surveying for Warings Ltd through out their project of the  Construction of a new built school in Portsmouth.</p>',
        duration: '52 Weeks',
        images: ['victory.jpg'],
        name: 'victoryschool',
        project: 'Victory School, Portsmouth',
        sector: 'education',
        sectors: ['Education'],
        services: 'Pre and Post Contract Quantity Surveying',
        title: 'Victory School, Portsmouth',
        value: '£5m',
    },
    {
        client: 'Kier Construction',
        contract: '',
        date: '2016-03-14',
        description: '<p>The project comprises the refurbishment and recladding of Albion House. Albion House is located in Woking town centre adjacent to the mainline railway station. The existing building is an 8 storey office building with a retail podium and basement parking. Kier Construction are the preferred bidder for the second stage tendering. Procom have been appointed by Kier to provide full bills of quantities for their tendering purposes. Bills are measured in accordance with SMM7 comprising alterations to the structural works, roof, stairs, recladding envelope, internal reconfiguration and redecoration, and some external works.</p>',
        duration: '',
        images: ['Albion-House1.jpg', 'Albion-House2.jpg'],
        name: 'albion',
        project: 'Albion House, Woking',
        sector: 'commercial',
        sectors: ['Offices and Commercial'],
        services: 'Bills of Quantities Preparation',
        title: 'Albion House',
        value: 'approx. £10m',
    },
    {
        client: 'Kier Construction',
        contract: '',
        date: '2016-03-14',
        description: `<p>Kier Construction are tendering this project for the Defence Infrastructure Organisation. In summary the project involves:</p>
  <ul>
      <li>The refurbishment and reconfiguration of Buildings 19, 33, 33a, 33c and 47 to accommodate the increase of personnel to these buildings.</li>
      <li>Refurbishment, reconfiguration and extension of Building 13 to accommodate the increase of personnel using the Building.</li>
      <li>Extension of the existing Armoury to accommodate three new arms cotes (referred to as Building 49).</li>
      <li>Construction of a new Training Centre (referred to as Building 48) to accommodate the uplift in personnel stationed at the Barracks.</li>
  </ul>
  <p>Procom have been appointed by Kier to provide full bills of quantities for their tendering purposes. Bills are measured in accordance with SMM7 comprising alterations and demolitions, substructure, superstructure, internal finishes, fittings, external works, drainage and underground services diversion.</p>`,
        duration: '',
        images: ['baker.jpg'],
        name: 'baker',
        project: 'Baker Barracks, Thorney Island',
        sector: 'education',
        sectors: ['Education', 'Defence'],
        services: 'Bills of Quantities Preparation',
        title: 'Baker Barracks',
        value: 'approx. £10m',
    },
    {
        client: 'Balfour Beatty',
        contract: '',
        date: '2016-03-14',
        description: '<p>As part of the Wokingham town centre regeneration scheme, Wokingham Borough Council are looking to improve residents’ health and wellbeing by providing high quality leisure facilities and public spaces in the town centre. Work will now begin to appoint a build contractor to help deliver the first phase of the work which is expected to start on site in summer 2016. Balfour Beatty are the preferred bidder for the construction of the phase one Multi-Storey Car Park and Bowling Alley. Procom have been appointed by Balfour Beatty to provide full bills of quantities for their tendering purposes including demolitions, substructure, superstructure, internal finishes, external works, drainage and external services. Bills are measured in accordance with SMM7.</p>',
        duration: '',
        images: ['Carnival-Pool1.jpg', 'Carnival-Pool2.jpg'],
        name: 'carnival',
        project: 'Carnival Pool MSCP and Bowling Alley, Wokingham',
        sector: 'leisure',
        sectors: ['Civil', 'Sports and Recreational'],
        services: 'Bills of Quantities Preparation',
        title: 'Carnival Pool',
        value: '£11.5m',
    },
    {
        client: 'Bouygues UK',
        contract: '',
        date: '2016-08-22',
        description: '<p>Procom have been appointed to provide commercial management services for the development of a 12,000 capacity indoor arena in Bristol, which is set to host over 100 events a year.  Previously a diesel storage depot, the development will also include a new widened cycle path and walkway, park and ride services for events over 9,000 capacity, shuttle run trains for capacity of 12,000.</p>',
        duration: '',
        images: ['Bristol1.jpg', 'Bristol2.jpg'],
        name: 'bristolarena',
        project: 'Bristol Arena',
        sector: 'commercial',
        sectors: ['Offices and Commercial', 'Sports and Recreational'],
        services: 'Commercial Management',
        title: 'Bristol Arena ',
        value: '£91m',
    },
    {
        client: 'Wates',
        contract: '',
        date: '2016-08-22',
        description: '<p>Procom have been engaged to produce full Bills of Quantities for the construction of a new double height exhibition hall alongside function and meeting rooms. The development will consist of a 12,500 sq m exhibition hall as well as multi-purpose function rooms and restaurants, taking the entire facility to 20,000 sq m to support Farnborough International’s Expansion</p>',
        duration: '',
        images: ['Farnborough.jpg'],
        name: 'farnborough',
        project: 'Farnborough International Exhibition and Conference Centre ',
        sector: 'commercial',
        sectors: ['Offices and Commercial'],
        services: 'Production of Bills of Quantities',
        title: 'Conference Centre',
        value: 'tbc',
    },
    {
        client: 'M &amp; W',
        contract: '',
        date: '2016-08-22',
        description: '<p>Procom have been appointed provide Commercial Project Management for the development of a Gasification facility with pre-treatment and an Anaerobic Digestion (AD) facility to process up to 95,000 tons of household waste per year which will be turned into fuel to produce power for the national grid and fertiliser.  Also the development of a recyclables Bulking Facility and a visitors and education centre.</p>',
        duration: '',
        images: ['Gasification.jpg'],
        name: 'surreygasification',
        project: 'ECO Park comprising Gasification and Anaerobic Digestion Facilities, Surrey',
        sector: 'renewable',
        sectors: ['Utilities', 'Renewable Energy'],
        services: 'Commercial Project Management',
        title: 'Gasification Plant, Surrey',
        value: 'tbc',
    },
    {
        client: 'Shanks',
        contract: '',
        date: '2016-08-22',
        description: '<p>Procom are providing estimating and commercial support to Shanks by producing a detailed cost estimate for the production of a new build proposed Household Waste Recycling Centre, Waste Recycling Centre and Waste Transfer Centre in Derbyshire.</p>',
        duration: '',
        images: ['Glossop.jpg'],
        name: 'glossop',
        project: 'Glossop HWRC',
        sector: 'renewable',
        sectors: ['Utilities', 'Renewable Energy'],
        services: 'Estimating and Commercial Support',
        title: 'Glossop HWRC',
        value: '£2.4m',
    },
    {
        client: 'Black Finch Investments',
        contract: '',
        date: '2021-02-25',
        description: '<p>The development was for the conversion of an existing building into residential units.</p><p>Procom were brought in at the request of the lender, to replace the previous monitoring surveyor.</p><p>Procom had dual functions for this commission, initially, to review the previous monitoring report and state their finding.  Secondly, Procom took over the role of monitoring surveying services for the remainder of the project.</p>',
        duration: '13 months',
        images: ['kingswayhouse.jpg','kingswayhouse1.jpg','kingswayhouse2.jpg','kingswayhouse3.jpg'],
        name: 'kingswayhouse',
        project: 'Kingsway House',
        sector: 'residential',
        sectors: ['Residential'],
        services: 'Monitoring Surveying Role',
        title: 'Kingsway House',
        value: 'circa £5m',
    },
    {
        client: 'Black Finch Investments',
        contract: '',
        date: '2021-02-25',
        description: '<p>The development was for the conversion of an existing building into a luxury hotel and spa.</p><p>Procom were engaged to provide the role of monitoring surveyor on this project.</p>',
        duration: '13 months',
        images: ['dixiedean.jpg', 'dixiedean1.jpg', 'dixiedean2.jpg', 'dixiedean3.jpg'],
        name: 'dixiedean',
        project: 'Dixie Dean',
        sector: 'commercial',
        sectors: ['Commercial'],
        services: 'Monitoring Surveying Role',
        title: 'Dixie Dean Hotel',
        value: 'circa £3.5m',
        hide: true
    },
    {
        client: 'Ravensgate Developments',
        contract: '',
        date: '2021-03-04',
        description: '<p>Procom provided contract and commercial support to the client and dealt with claims and adjudications from the contractor, Chase Construction.</p>',
        duration: '',
        images: ['southey1.jpg', 'southey2.jpg', 'southey3.jpg', 'southey5.jpg'],
        name: 'southey',
        project: 'Southey Bowling Club',
        sector: 'residential',
        sectors: ['Residential'],
        services: 'Contract & Commercial Support',
        title: 'Southey Bowling Club',
        value: '',
    },
    {
        client: '',
        contract: '',
        date: '2021-03-04',
        description: '<p>Procom are providing Quantity Surveying Services for the project to build 117 new council homes on the old Thomas Tallis School site on Kidbrooke Park Road, Greenwich</p>',
        duration: '',
        images: ['kidbrooke.jpg'],
        name: 'kidbrooke',
        project: 'Kidbrooke Park Road, Greenwich',
        sector: 'residential',
        sectors: ['Residential'],
        services: '',
        title: 'Kidbrooke Park Road, Greenwich',
        value: '',
    },
    {
        client: '',
        contract: '',
        date: '2021-03-04',
        description: '<p>Procom are proud to be providing Quantity Surveying Services to Wates for the Grahame Park Estate Regeneration.</p><p>The masterplan for Grahame Park, developed by Notting Hill Genesis (NHG) in partnership with Barnet Council, will see 2,088 new homes developed.</p><p>More than 1,000 of those properties will be affordable housing with 346 for social rent.</p><p>Community facilities, including a community centre and a day nursery, will also be provided as well as improvements to Heybourne Park.</p>',
        duration: '',
        images: ['grahamepark.jpg'],
        name: 'grahamepark',
        project: 'Grahame Park Estate Regeneration',
        sector: 'residential',
        sectors: ['Residential'],
        services: 'Quantity Surveying Services',
        title: 'Grahame Park Estate Regeneration',
        value: '',
    },
    {
        client: '',
        contract: '',
        date: '2021-06-28',
        description: '<p>Procom was appointed to act in an independent capacity to undertake an impartial review and assessment of the final account for the works on behalf of the Employer and Contractor.</p>',
        duration: '',
        images: ['charmhotel.jpg'],
        name: 'charmhotel',
        project: 'Charm Hotel Brighton',
        sector: 'commercial',
        sectors: ['Commercial'],
        services: '',
        title: 'Charm Hotel Brighton',
        value: '',
        hide: true
    },
    {
        client: '',
        contract: '',
        date: '2021-10-29',
        description: '<p>Procom Construction Consultants have been appointed by Surefire Management Services Limited to provide the services and deliverables of Employer\'s Agent and Clerk of Works in relation to the cladding remediation works for seven apartment blocks at Gallions Approach, Albert Basin, Royal Docks, Newham, London, E16 2QJ.</p>',
        duration: '',
        images: ['galleonsreach1', 'galleonsreach2', 'galleonsreach3', 'galleonsreach4', 'galleonsreach5', 'galleonsreach6', 'galleonsreach7'],
        name: 'galleonsreach',
        project: 'Galleons Reach',
        sector: 'residential',
        sectors: ['Residential'],
        services: '',
        title: 'Galleons Reach',
        value: '',
    },
    // {
    //     client: '',
    //     contract: '',
    //     date: '2022-06-27',
    //     description: `<p>Procom are working with CHC to assist them in assessing Compensation Events, which includes cost and time implications.</p>
    //     <p>We have produced an impacted programme to justify extensions of time due and loss and expense due.</p>
    //     <p>The project is a key site for HS2 and will see a 30-hectare brownfield site transformed into the nerve centre of the high speed railway line.</p>`,
    //     duration: '',
    //     images: ['hs2-midlands1', 'hs2-midlands3', 'hs2-midlands4', 'hs2-midlands5', 'hs2-midlands2'],
    //     name: 'washwood-heath',
    //     project: 'Washwood Heath',
    //     sector: 'aviation',
    //     sectors: ['Transportation'],
    //     services: '',
    //     title: 'Washwood Heath HS2 Nerve Centre',
    //     value: '',
    // },
    {
        client: '',
        contract: '',
        date: '2022-09-28',
        description: `<p>Procom's involvement was BQ Provider working as part of the Wates integrated project team. Our scope was split into 3 district areas:</p>

        <ul>
            <li>Works to existing features (Internal) – Heritage Areas – Production of detailed CostX BQs using SMM7 for Internal Grade 1 and 2 listed internal works</li>
            <li>Additional Scope to New Areas – Production of detailed BQs using SMM7 for all internal works to Ground, 1st and Mezzanine Floors</li>
            <li>Add and Omit on General Drawing Updates – Audit of the latest drawings and a general add an omit exercise on the drawings that have changed.</li>
        </ul>
        
        <p>International real estate developer
        Pembroke has signed up Wates to
        undertake a £53m refurbishment and
        extension of a grade II listed former
        bank HQ in London’s square mile.</p>
        
        <p>Wates Construction is returning to the 41 Lothbury
        site 18 years after completing a previous overhaul
        of the building.
        The 130,000 sq ft office building was originally built
        to serve as the headquarters for Natwest Bank.</p>
        
        <p>The Edwardian building features heritage assets
        including iconic stone columns, an original
        checkerboard marble floor and a grand central
        atrium. The programme of work will transform existing
        rooftop space on floor seven and introduce an
        eighth floor with new outdoor terraces offering
        spectacular City skyline views.</p>
        
        <p>The iconic Banking Hall will also receive a fresh
        injection of high-quality finishes including a new
        hospitality-style reception, agile breakout spaces
        and an impressive entrance with statement lighting
        and artwork by internationally renowned designer,
        Jason Bruges.</p>`,
        duration: '',
        images: ['41lothbury'],
        name: 'lothbury',
        project: '41 Lothbury',
        sector: 'commercial',
        sectors: ['Commercial'],
        services: '',
        title: '41 Lothbury',
        value: '',
    },
    {
        client: 'Rizzani',
        contract: '',
        date: '2023-03-09',
        description: `<p>Construction of Yvie (also known as Y-Towers), two buildings, respectively a 110 m-high hotel and a 101 m-high residential building, located in the very heart of Amsterdam, linked by a 4-storey podium that will accommodate public spaces, conference halls and lobby. Procom provided Dispute Resolution, Contract and Quantum Support services.</p>`,
        duration: '',
        images: 'YVIE',
        name: 'yvie',
        project: 'YVIE',
        sector: 'residential',
        sectors: ['Residential'],
        services: 'EOT and Loss & Expense Analysis',
        title: 'Amsterdam Y Towers',
        value: '',
        hide: true,
    },
    {
        client: 'Cheetham',
        contract: '',
        date: '2023-03-09',
        description: `<p>Procom are proud to provide Quantity Surveying Services for Washwood Heath Depot, the operational heart of HS2, is set to create over 550 jobs in the local area.</p>
        <p>Birmingham City Council granted Schedule 17 approval under the HS2 Phase One Act 2017, for HS2's Washwood Heath Depot, north-east of Birmingham city centre. This is where HS2 trains will be maintained, serviced and stored when not in use, and includes the Maintenance Building, Network Integrated Control Centre (NICC) and Cleaners and Drivers Building.</p>
        <p>All the buildings have been designed to celebrate engineering and provide sustainable working, as well as outdoor amenity spaces for staff. The designs have been produced by HS2's Engineering Delivery Partner (EDP).</p>`,
        duration: '',
        images: 'Washwood',
        name: 'washwood',
        project: 'Washwood',
        sector: 'aviation',
        sectors: ['Transportation'],
        services: '',
        title: 'Washwood Heath HS2 Nerve Centre',
        value: '',
    },
    {
        client: 'Rizzani',
        contract: '',
        date: '2023-03-09',
        description: `<p>The project for the new Bispebjerg hospital represents a key intervention within the vision proposed by the Capital Region of Denmark for the development of health care services in the area. The architectural complex develops in an area of approximately 77.500 sqm, within which six pavilions host a dense and widely articulated functional program.</p>
        <p>There are three key departments: emergency, operation and radiology. These are adjeced by departments with the most contact with patients, such as pediatrics and woman & child. Operating rooms, laboratories, connection tunnels and services complete the programmatic layout, making the new facility a state-of-the-art hub for the entire region.</p>
        <p>Procom are proud to have provided Planning and Programme Support for this project.</p>`,
        duration: '',
        images: 'Bisperbjerg',
        name: 'bisperbjerg',
        project: '',
        sector: 'commercial',
        sectors: ['Offices and Commercial'],
        services: 'EOT and Loss & Expense Analaysis',
        title: 'Copenhagen Hospital',
        value: '',
    },
    {
        client: 'Rizzani',
        contract: '',
        date: '2023-03-09',
        description: `<p>Rizzani de Eccher is proud to take part to the construction of a crucial energy infrastructure in the field of bio-diesel.
        Bio-diesel is a fuel produced from renewable materials such as food scraps, cereals, and other oleo-chemical by-products, and is is rightly considered to be the product of the future in the pursuit of energy independence from hydrocarbons in the long term.</p>
        <p>Technip Energies has indeed selected Rizzani de Eccher for the civil works package and the turnkey design & build packages for the delivery of complex buildings in connection with the Neste’s new bio-diesel refinery in Rotterdam port, where the renewable diesel production capacity will be increased with a new production line rated for 1,25 million mt/year.</p>
        <p>Procom are proud to be a part of this programme, contributing Procurement and Construction Programme Development.</p>`,
        duration: '',
        images: 'Neste',
        name: 'neste',
        project: '',
        sector: 'commercial',
        sectors: ['Offices and Commercial'],
        services: 'Procurement and Construction Programme Development',
        title: 'Rotterdam Bio Diesel Refinery',
        value: '',
    },
    {
        client: 'Equans',
        contract: '',
        date: '2023-03-09',
        description: `<p>VIVO, a 50:50 joint venture between Serco and EQUANS, has been awarded places on the Defence FM and Housing Maintenance framework contracts.</p>
        <p>Our purpose is to modernise the lived experience for our Armed Forces to achieve, grow and thrive. There is one other element that will be critical to the success of our joint venture - our culture. Our 'Commit to Enable' culture is centred around the commitments we make every day to enable Defence Capability.</p>
        <p>Procom are proud to have participated to this project through Void Surveys, Inspection of Void MOD properties, Asset inspection and quantity surveying.</p>`,
        duration: '',
        images: ['vivodefence'],
        name: 'vivodefence',
        project: '',
        sector: 'residential',
        sectors: ['Residential'],
        services: 'Asset Inspections and Quantity Surveying',
        title: 'VIVO Defence',
        value: '',
    },
    {
        client: 'Wates',
        contract: '',
        date: '2023-03-09',
        description: `<p>Oasis Academy Brislington is a secondary school situated in South Bristol. Serving the communities of Brislington, Stockwood, Knowle, Totterdown, St Anne's and Broomhill, we are an all-inclusive academy serving children from Years 7 to 11.</p>
        <p>At full capacity, the new school will cater for 900 pupils. The school will grow incrementally in numbers year on year. The proposed school building will have a floor area of 6,720 m2 and will be part-two and part-three storeys high.</p>
        <p>Procom are proud to have completed Bills of Quantities for this programme.</p>`,
        duration: '',
        images: ['oasisacademy'],
        name: 'oasisacademy',
        project: '',
        sector: 'education',
        sectors: ['Education'],
        services: 'Bills of Quantities',
        title: 'Oasis Academy',
        value: '',
    },
    {
        client: 'Guildmore',
        contract: '',
        date: '2023-03-09',
        description: `<p>In light of the Grenfell disaster, many high-rise buildings have been re-evaluating fire safety and St Francis Tower, a 1960s residential development in the centre of Ipswich, in one such example.</p>
        <p>The existing over-cladding and insulation was found to be a fire risk and has been partially removed. Internally, sprinklers and improved fire alarms have been fitted.</p>
        <p>The design proposals are intended to reinforce the vertical features of the façade and create some contrast by using white for structural elements and grey shades for infill areas. These colours will connect the tower visually with the flint and stonework of the nearby St Nicholas Church.</p>
        <p>Procom are proud to have provided Commercial Support and Quantity Surveying services.</p>`,
        duration: '',
        images: ['cowan'],
        name: 'stfrancis',
        project: '',
        sector: 'residential',
        sectors: ['Residential'],
        services: 'Quantity Surveying',
        title: 'St Francis Tower, Ipswich',
        value: '',
    },
    {
        client: 'Overbury',
        contract: '',
        date: '2023-03-09',
        description: `<p>Following a £40m investment, the magnificent 300-year-old Grade II listed Heythrop Park manor, set in 440-acre grounds on the edge of the Cotswolds, opened its doors in September as an opulent luxury hotel.</p>
        <p>Procom are proud to have provided Quantity Surveying Services for this project.</p>`,
        duration: '',
        images: 'heythrop',
        name: 'heythrop',
        project: '',
        sector: 'leisure',
        sectors: ['Hospitality'],
        services: 'Quantity Surveying',
        title: 'Heythrop Park',
        value: '',
        hide: true
    },
    {
        client: 'IML',
        contract: '',
        date: '2023-03-09',
        description: `<p>HMP Lowdham Grange is a Category B training prison accommodating adult male offenders aged 21 and over with sentences over four years and with at least 12 months to serve. It has a maximum capacity of 920.</p>
        <p>Procom are proud to have acted as Employers Agent and provided Quantity Surveying and CDM Services for this project.</p>`,
        duration: '',
        images: ['lowdham1'],
        name: 'lowdham',
        project: '',
        sector: 'commercial',
        sectors: ['Offices and Commercial'],
        services: 'Employers Agent, QS and CDM services',
        title: 'HMP Lowdham Grange',
        value: '',
    },
    {
        client: 'Pellings',
        contract: '',
        date: '2023-03-09',
        description: `<p>Following the obtaining of planning consent for a comprehensive redevelopment of the site, Mizen ( in partnership with Paragon Asra Housing Association ) commenced work on the development of 40 new build apartments over ground floor offices and an underground car park in early 2020. The site sits in the middle of a huge regeneration effort in Wembley with great nearby attractions such as the London Designer Outlet or Boxpark.</p>
        <p>Located on Wembley Hill Road, Heron House involves the demolition of the existing 1960s office building to provide a mix of 1, 2 & 3 bedrooms homes ( with all 40 apartments featuring a private balcony or terrace ) over ground floor office space together with a basement for car parking and secured bicycle storage.</p>
        <p>Procom are proud to have provided Quantity Surveying Services and Forensic Cost Auditing for this project.</p>`,
        duration: '',
        images: ['heronhouse1', 'heronhouse2'],
        name: 'heronhouse',
        project: '',
        sector: 'residential',
        sectors: ['Residential'],
        services: 'Quantity Surveying & Forensic Cost Auditing',
        title: 'Heron House',
        value: '',
    },
    {
        client: 'United Living',
        contract: '',
        date: '2023-03-09',
        description: `<p>United Living Property Services (ULPS) has strengthened its position as a leading UK place maker by securing a place on Fusion 21's 'Reactive Repairs and Empty Buildings Framework'.</p>
        <p>Fusion21 is a procurement organisation and social enterprise that provides access to property, construction and built environment services to organisations operating within the social housing, local authority, education, blue light and health sectors. ULPS recently secured a place on Fusion 21's £750 million decarbonisation framework - which covers 'whole-house decarbonisation'.</p>
        <p>Procom are proud to have provided Tendering and Estimating services for this project.</p>`,
        duration: '',
        images: ['fusion21'],
        name: 'fusion21',
        project: '',
        sector: 'residential',
        sectors: ['Residential'],
        services: 'Tendering & Estimating',
        title: 'Fusion 21',
        value: '',
    },
    {
        client: 'West Midlands Combined Authority',
        contract: '',
        date: '2023-03-09',
        description: `<p>The West Midlands Rail Programme are investing £61 million in 3 new stations on the Camp Hill line in South Birmingham. This is part of the West Midlands Rail Programme (WMRP).</p>
        <p>This project will provide faster, more reliable transport for people who live or work in the area. It will also mean that train journeys could replace up to 25% of car journeys. This will improve the local area, reducing congestion and pollution.</p>
        <p>Procom are proud to have provided Commercial Management services for this project.</p>`,
        duration: '',
        images: ['camphillline'],
        name: 'camphilline',
        project: '',
        sector: 'aviation',
        sectors: ['Transportation'],
        services: 'Commercial Management',
        title: 'Camp Hill Line',
        value: '',
    },
    {
        client: 'CSM',
        contract: '',
        date: '2023-03-09',
        description: `<p>Heartlands Hospital is an acute general hospital in Bordesley Green, Birmingham, England. It is managed by University Hospitals Birmingham NHS Foundation Trust.</p>
        <p>Procom are proud to have provided Adjudication and claims support services for this project.</p>`,
        duration: '',
        images: ['heartlands1'],
        name: 'heartlands',
        project: '',
        sector: 'commercial',
        sectors: ['Healthcare'],
        services: 'Adjudication, Claims Support',
        title: 'Heartlands Hospital',
        value: '',
    },
    {
        client: 'Barnet',
        contract: '',
        date: '2023-03-09',
        description: `<p>To improve the energy efficiency of homes Barnet are tendering block by block for Retrofit works, such as insulation, solar panel, heated pumps and other energy efficiency items, that will enable Barnet to meet EPC B/C by 2030 and net zero ASAP thereafter.</p>
        <p>Procom are proud to have contributed Precontract Services for this project.</p>`,
        duration: '',
        images: ['barnet'],
        name: 'barnetretrofit',
        project: '',
        sector: 'residential',
        sectors: ['Residential'],
        services: 'Precontract Services',
        title: 'Retrofit Works Tendering, Barnet',
        value: '',
    },
    {
        client: 'JLL',
        contract: '',
        date: '2023-03-09',
        description: `<p>Procom are pleased to announce that we have completed a cost plan for Ransomes Wharf.</p>
        <p>Situated in Battersea’s creative quarter on the River Thames, adjacent to 200 acres of London’s best kept riverside open space, Battersea Park, this is an unrivalled residential-led development opportunity to create iconic warehouse style accommodation in a vibrant dockside location.</p>`,
        duration: '',
        images: 'ransomeswharf',
        name: 'ransomeswharf',
        project: '',
        sector: 'residential',
        sectors: ['Residential'],
        services: 'Cost Planning',
        title: 'Ransomes Wharf',
        value: '',
    }
 

];


export {
    projectData,
    ProjectData 
};

