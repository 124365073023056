import { PageContent } from '@appzuka/rxnano';

import { patch, JSXFactory, CustomElementHandler, render } from '@appzuka/rxnano';

import { linkClick } from '@appzuka/rxnano';

import { SectorBanner, bannerTitle } from 'site/components/sectorBanner';
import { imagePanel } from 'site/components/imagePanel';

import { projectData } from './projectdata';
import { sitePix } from '@appzuka/rxnano';
import { SiteData } from 'site/siteContent';
import { pixLoadedObservable } from '@appzuka/rxnano';
import { first } from 'rxjs/operators';
import { exportedSiteData } from '@appzuka/rxnano';
import { ProjectData } from 'site/pages/sectors/projectdata';

import './sectors.scss';

let npd = projectData;

pixLoadedObservable
.pipe(
    first(a => a.length > 0)
)
.subscribe(ap => {
   let replacedpd = projectData.map(pd => {
    if (typeof pd.images === 'string') {
      let ap2 = ap.filter(p => p.file.match(new RegExp("projects\/"+(pd.images as string).toLowerCase())));
      let b  = Array.from(Array(ap2.length).keys()).map((_,i) => {
        return(pd.images as string+(i+1));
      })
      return({...pd, images: b});
    } else {
      return(pd);
    }
   });
   npd = replacedpd;
})

const toggleActive = (event) => {
  const isActive = event.currentTarget.className.split(' ').includes('active');
  const otherClasses = event.currentTarget.className.split(' ').filter(a => a !== 'active'?a:'').join(' ');

  if (isActive) {
    // Remove active from the current node
    event.currentTarget.className = otherClasses;
  } else {
    // Remove active from all wrappers and add it to the clicked node
    const els = document.getElementsByClassName('project-wrapper');
    Array.from(els).map(el => el.className = el.className.split(' ').filter(a => a !== 'active'?a:'').join(' '));
    event.currentTarget.className = otherClasses + ' active';
  }
}



const projectList2 = (projectList: ProjectData[], pathPrefix: string) => {

    let elementList:HTMLElement[] = [];
    const addELement = (el) => {
      el.addEventListener("transitionend", () => {
        var rect = el.firstChild.getBoundingClientRect();
        var rect2 = el.parentNode.getBoundingClientRect();
        if (rect.bottom > rect2.bottom) {
          el.parentNode.classList.add("overflow");
        }
      });
      elementList.push(el);
      return el;
    }

    // TODO: replace special case for midfield
    <render>
        <div class='project-outer-wrapper'>
        { () => {
            projectList.map((p, i) => {
              imagePanel(p.images,
               p.title, `/${pathPrefix}/${p.name}`, p.description.replace(/<\/[^>]*>/g, ' ').replace(/<[^>]*>/g, ''), p.hide || false);
            });
            for(let i=0; i<5; i++) {
              <render>
                <div class='project-wrapper filler' />
              </render>
            }
        }}
        </div>
    </render>

}

const projectList = ({sector, exclude} : {sector: string, exclude: string}) => {
  const sectorList = npd.filter(p => p.sector === sector).filter(p => p.name !== exclude);
  projectList2(sectorList, 'case');
}

const projectListByDate = ({date, pathPrefix, exclude}: { date: string, pathPrefix: string, exclude: string}) => {
  //TODO: Better to have date range, of after
  const sectorList = npd.filter(p => p.date === date).filter(p => p.name !== exclude);
  projectList2(sectorList, pathPrefix || 'case');
}

const sectorContent = (sector, label, content) => {

    <render>
        <h2>{sector}</h2>
        <p>{content}</p>
        { () => projectList({sector: label, exclude: ''}) }
    </render>
}

const sectorList = [
    {sector: 'Renewable Energy', name: 'renewable-energy', label: 'renewable',
        tagline: 'Procom have extensive experience of a wide range projects covering waste management, reprocessing, nuclear decommissioning and biomass sectors',
        content: 'Select a project below or get in touch to view further details of projects we are proud to have made a difference on.' },
    {sector: 'Aviation & Transport', name: 'renewable-energy', label: 'aviation',
        tagline: 'Procom have extensive experience of a wide range projects covering road, rail, air and naval sectors.',
        content: 'Select a project below or get in touch to view further details of projects we are proud to have made a difference on.' },
    {sector: 'Education', name: 'renewable-energy', label: 'education',
        tagline: 'Procom have extensive experience of a wide range of education and research related projects. of a wide range projects covering road, rail, air and naval sectors.',
        content: 'Select a project below or get in touch to view further details of projects we are proud to have made a difference on.' },
    {sector: 'Office & Commercial', name: 'renewable-energy', label: 'commercial',
        tagline: 'Procom have extensive experience in the Office and Commercial field from refurbishment to new build.',
        content: 'Select a project below or get in touch to view further details of projects we are proud to have made a difference on.' },
    {sector: 'Leisure & Sport', name: 'renewable-energy', label: 'leisure',
        tagline: 'Procom\'s leisure and sport work includes cinemas, leisure centres, swimming pools and restaurants.',
        content: 'Select a project below or get in touch to view further details of projects we are proud to have made a difference on.' },
    {sector: 'Residential', name: 'renewable-energy', label: 'residential',
        tagline: 'We advise on residential schemes which vary in size from single properties to developments comprising several hundred units.',
        content: 'Our clients include local authorities, developers and housing associations. Select a project below or get in touch to view further details of projects we are proud to have made a difference on.' }
];

const nextSector = (current) => {

    const index = sectorList.findIndex(({label}) => label === current);
    if (index === sectorList.length-1) {
        return({link: `/sectors/${sectorList[0].sector.replace(/ & /g,'-').replace(/ /g,'-').toLowerCase()}`, label: `Next Sector: ${sectorList[0].sector}`});
    }
    return({link: `/sectors/${sectorList[index+1].sector.replace(/ & /g,'-').replace(/ /g,'-').toLowerCase()}`, label: `Next Sector: ${sectorList[index+1].sector}`});

}

const renderSectorImage = (imageName, index) => {

    const multiPix = (ap4) => {

        let variantArray = [
            {variant: 'mobile', media: '(max-width: 450px)'},
            {variant: 'pano', media: '(min-width: 4510px)'}
        ];

        if (ap4.length > 0) {
            // FIXME: Log errors to console
            const defaultPix = ap4.filter(p => ((p.file === imageName) && (p.variant === 'wide')))[0];
            const defaultSize = defaultPix.sizes.sort((a, b) => { return ((a - 1024) ** 2 < (b - 1024) ** 2) ? -1 : +1 })[0]; // The size closest to 1024

            variantArray.map(({variant, media}, i) => {
                const mobilePixArray = ap4.filter(p => ((p.file === imageName) && (p.variant === variant)));
                if (mobilePixArray.length > 0) {
                    const mobilePix = mobilePixArray[0];
                    const imageBase = `${exportedSiteData.assetBase}${mobilePix.pathprefix}/${[mobilePix.file, mobilePix.variant, mobilePix.hash].filter(p=>p).join('-')}`
                    const webpSrc = mobilePix.sizes.map(s => `${imageBase}-${s}.webp ${s}w`).join();
                    const jpgSrc = mobilePix.sizes.map(s => `${imageBase}-${s}.jpg ${s}w`).join();

                    <render>
                        <source type='image/webp' srcset={webpSrc} media={media} />
                        <source type='image/jpeg' srcset={jpgSrc} media={media} />
                    </render>
                } else {
                    console.log(`*** Image ${imageName} variant ${variant} not found in pixlist`)
                }
            }); 

            <render>
                <img src={`${exportedSiteData.assetBase}${defaultPix.pathprefix}/${[defaultPix.file, defaultPix.variant, defaultPix.hash, defaultSize].join('-')}.jpg`}/>
            </render>
        }
    }

    const w = window.innerWidth;
    const h = w<550 ? 0.5 : w<1024 ? 0.333 : 0.25;
    const svginline = `data:image/svg+xml;utf8,<svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 ${100*h}"><rect width="680" height="${100*h}"  style="fill:%23eee" />
    </svg>`;

    <render>
        <picture class='sector-pix' id={`multi-aspect-pix-${index}`}>
            <img src={svginline}/>
        </picture>
    </render>

    // Replace the image once the pixlist is available
    pixLoadedObservable
    .pipe(
        first(a => a.length > 0)
    )
    .subscribe(ap => {
        const el2 = document.getElementById(`multi-aspect-pix-${index}`) as HTMLDivElement;
        patch(el2, () => multiPix(ap));
    })
}

const sectorHomeBanner = () => {
    <render>
        <div id='sector-home-banner'>
            <a id='sector-button-container'>
                {() => sectorList.map((s,i) => {
                    const link = `/sectors/${s.sector.replace(/ & /g,'-').replace(/ /g,'-').toLowerCase()}`;
                    <render>
                        <a class='sector-button-wrapper' href={ link } onclick={e => linkClick(e, link)}>
                            { () => renderSectorImage(`banners/sectors/${s.label}`, i)}
                            <div class='sector-button-pix-filter'></div>
                            <h2>{s.sector}</h2>

                        </a>
                    </render>
                })}
            </a>
        </div>
    </render>
}


const serviceContent = () => {

    <render>
        <p>We have worked across many industry sectors on projects ranging from £250k to £4 Billion in the UK. We are also currently involved in providing pre-contract services on a US$100 Billion project in the Middle East.</p>
        <p>Click on one of the sectors below to learn more about our services and projects we have completed in each area.</p>
        { sectorHomeBanner }
    </render>

}

declare global { // opening up the namespace
  var ResizeObserver: { // mergin ResizeObserver to it
    prototype: any;
    new(callback: any): any;
  }
}
const newCase = (pathPrefix, date) => {

  return (_, pv) => {
    singleCase(null, {...pv, date: date, pathPrefix: pathPrefix});
  }
}

const singleCase = (_, pathVariables) => {
  let project = pathVariables.project;
  let pathPrefix = pathVariables.pathPrefix || 'case';

  let wrapperEl: HTMLElement | undefined;
  const foundProject = npd.find(p => p.name === project);
  const foundProjectIndex = npd.findIndex(p => p.name === project);
  const nextProjectIndex = foundProjectIndex === (npd.length - 1)
    ? 0 : foundProjectIndex + 1;
  const nextProject = npd[nextProjectIndex];

  if (!foundProject) {
    render(<div>
      <h2>Project Not Found</h2>
      <p>Project {project} could not be found.</p>
    </div>)
    return;
  }
  render(<div class='single-case'>
    <div ref={el => wrapperEl = el} class='single-case__image-wrapper'>
      { () => {
        if (Array.isArray(foundProject.images)) {
          foundProject.images.map(image =>
            sitePix(SiteData.assetBase,
            `projects/${image.replace('.jpg', '').replace('.png', '').toLowerCase()}`,
            {class: 'single-case__image'})
          )
        } else if (typeof foundProject.images === "string") {

    // Replace the images once the pixlist is available
    pixLoadedObservable
    .pipe(
        first(a => a.length > 0)
    )
    .subscribe(ap => {
        const foundProject = npd.find(p => p.name === project);
        if (foundProject) {
          patch(wrapperEl as Element, () => {(foundProject.images as string[]).map(image =>
            sitePix(SiteData.assetBase,
            `projects/${image.replace('.jpg', '').replace('.png', '').toLowerCase()}`,
            {class: 'single-case__image'})
          )});
        }

    })

        }}
      }
    </div>
    <div class='single-case__project-desc' dangerouslySetInnerHTML={(foundProject.hide || false) ? "" : foundProject!.description}></div>
    <p class='single-case__link-text'>Next Project: <a href={'/'+pathPrefix+'/'+nextProject.name}
          onclick={e => linkClick(e, '/'+pathPrefix+'/'+nextProject.name)}>{nextProject.title}</a>
    </p>
    { () => {
        if (pathVariables.date) {
          <render>
            <h2>Other New Cases</h2>
          </render>
          projectListByDate({date: pathVariables.date, pathPrefix: 'newcase', exclude: project});
        } else if (pathVariables.sector) {
          const thisSector = sectorList.find(s => s.label === pathVariables.sector)?.sector || '';
          <render>
            <h2>Other Cases in {thisSector}</h2>
          </render>
          projectList({sector: pathVariables.sector, exclude: project});
        }
    }}
  </div>)

    // Observe one or multiple elements
    // NICE: This could be throttled
  if (window.ResizeObserver && (wrapperEl !== undefined)) {
    var ro = new ResizeObserver(entries => {
      for (let entry of entries) {
        const cr = entry.contentRect;
        if (cr.height > 210) {
          wrapperEl!.style.justifyContent = 'center';
        } else {
          wrapperEl!.style.justifyContent = 'flex-start';
        }
      }
    });

    // FIXME: Do we need to unobserve on page change?
    ro.observe(wrapperEl);
  }






}

let pageList = [
    {
        pageLocation: 'sectors',
        image: () => <render><div class="page-name-banner"><h2>Sectors</h2></div></render>,
        pageTitle: 'Services',
        content: serviceContent,
        name: 'services'
    },
    {
      pageLocation: 'case/:project',
      image: (_, pv) => {
        const thisProject = npd.find(p => p.name === pv.project);
        if (thisProject) {
          <render><div class="page-name-banner"><h2>{thisProject!.title}</h2></div></render>
        }
      },
      pageTitle: 'Case Study',
      content: (_, pv) => {
        const thisProject = npd.find(p => p.name === pv.project);
        if (thisProject) {
          singleCase(null, {...pv, sector: thisProject.sector, pathPrefix: 'case', exclude: thisProject.name});
        } else {
          // TODO: on screen message
          render(<div id='page-case-study'>
            <h2>Project Not Found</h2>
            <p>Please select a case study from the menu.</p>
          </div>)
        }

      },
      name: 'case-study'
    },
    {
      pageLocation: 'newcase/:project',
      image: (_, pv) => {
        const thisProject = npd.find(p => p.name === pv.project);
        if (thisProject) {
          <render><div class="page-name-banner"><h2>{thisProject!.title}</h2></div></render>
        }
      },
      pageTitle: 'Case Study',
      content: newCase('newcase', '2023-03-09'),
      index: false,
      name: 'case-study'
    },
    ...sectorList.map(({sector, name, label, tagline, content}) => {return(
        {
            pageLocation: `sectors/${sector.replace(/ & /g,'-').replace(/ /g,'-').toLowerCase()}`,
            image: (pco) => SectorBanner(`banners/sectors/${label}`, () => bannerTitle(sector, tagline), {link: '/sectors', label: 'All Sectors'}, nextSector(label)),
            pageTitle: 'Services',
            content: () => sectorContent(sector, label, content),
            name: name
        }
    )})
];



const sectorSubmenu = () => {
    <render>
        <div class='service-sub-menu'>
            {() => sectorList.map(({sector}) => {
                const link = `/sectors/${sector.replace(/ & /g,'-').replace(/ /g,'-').toLowerCase()}`;
                return(<render>
                    <a href={link}
                        onclick={e => linkClick(e, link)}
                        class={['submenu-item', window.location.pathname===link?'active':'non-active'].join(' ')}>
                        <p>{sector}</p>
                    </a>
                </render>)
            })}
        </div>
    </render>
}


const sectorPages : PageContent = {
    content: pageList,
    subMenu: sectorSubmenu
}

export {
    sectorPages,
    projectList,
    projectListByDate,
    sectorHomeBanner
};

